.ais-Menu-container
  margin-top: 1.5rem

  @include lbg_tablet
    margin-top: 3rem

.ais-Menu-list
  display: flex
  justify-content: center

  @include lbg_tablet
    justify-content: flex-start

  a
    display: block
    cursor: pointer
    padding: .75rem .75rem
    background: #fff
    margin-right: 2px
    color: $black
    opacity: .4
    transition: opacity .25s ease, background .25s ease

    @include lbg_tablet
      font-size: 1rem
      padding: 1rem 1.5rem

    &:hover
      opacity: .65

    span
      text-transform: capitalize

    span.ais-Menu-count
      &::before
        content: ' ('

      &::after
        content: ')'
  
  .ais-Menu-item--selected a
    opacity: 1 !important

.ais-Hits
  .search_hit
    display: grid
    grid-template-columns: 4rem 1fr 
    gap: 1rem

    @include lbg_tablet
      align-items: center
      grid-template-columns: 1fr 8rem
      gap: $gutter
      min-height: 4rem
  
  li
    a
      display: block
      transition: color .2s ease

      &:hover
        color: $green

    dl
      display: grid
      grid-template-columns: auto 1fr
      gap: .375rem 1rem
      margin-top: .375rem
      color: $black

      a
        color: $blue

        &:hover
          color: $green

    .hit__content__title
      span
        display: block
        font-size: 1rem
        line-height: 1.2
        margin-bottom: .5rem

    .hit__content__tags
      display: flex
      margin-top: .75rem

      &:empty
        display: none

      li
        color: $black
        font-size: .75rem
        background: $blueLight
        border-radius: .25rem
        padding: .25rem .375rem
        margin-right: .25rem

    .hit__content__meta
      color: $blue
      font-size: .875rem
      margin-top: .25rem

    .hit__content__type
      text-transform: capitalize

    .hit__meta
      order: -1

      @include lbg_tablet
        order: 2

    .hit__meta__day
      display: flex
      color: $black

      @include lbg_tablet
        padding: 0 0 0 1rem

      .day__number
        font-weight: 300
        font-family: Antonia H2
        font-size: 40px

        @include lbg_tablet
          margin: .5rem 0 .125rem 0
          font-size: 64px

      .day__month
        font-family: Whitney
        font-weight: 400
        font-size: .75rem
        margin: 1px 0 0 .25rem
    
        @include lbg_tablet
          font-size: .875rem
          margin: .75rem 0 0 .25rem

    .hit__content__data
      display: flex

      *
        margin-top: .125rem
        color: $black75

        &:empty
          display: none

      a
        transition: opacity .25s ease, color .25s ease

      * + *::before
        content: ' — '
        color: $black75 !important
        
    p
      margin-top: .75rem
      color: $black
      line-height: 1.2

      @include lbg_tablet
        font-size: 1rem
        line-height: 1.3

      &:empty
        display: none

    mark
      background: lighten($blue, 50%)
      box-shadow: 0 -4px 0 lighten($blue, 50%)

  li + li.ais-Hits-item
    margin-top: .75rem
    padding-top: 1rem
    border-top: 1px solid #efefef
    
.ais-RefinementList
  padding: 0 .5rem
  user-select: none

  li + li
    margin-top: .4375rem

  .ais-RefinementList-label
    width: 100%
    display: grid
    grid-template-columns: auto 1fr 2rem
    align-items: start

  .ais-RefinementList-checkbox
    margin: 0 -.875rem 0 0
    width: .875rem
    height: .875rem
    opacity: 0

    &:checked + .ais-RefinementList-labelText::after
      opacity: 1

    &:checked + .ais-RefinementList-labelText::before
      background: $black

  .ais-RefinementList-labelText
    position: relative
    padding-left: 1.25rem

    &::after,
    &::before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0

    &::before
      width: .875rem
      height: .875rem
      border: 1px solid $black
      border-radius: 1px

    &::after
      width: .25rem
      height: .5rem
      border-right: 1px solid #fff
      border-bottom: 1px solid #fff
      transform: translate(.3125rem, .125rem) rotate(40deg)
      opacity: 0

  .ais-RefinementList-count
    justify-self: end
    font-size: 12px
    color: $pink

.ais-ClearRefinements-button
  color: #fff
  background: $black
  font-family: Whitney,sans-serif
  height: 1.875rem
  border: none
  border-radius: 0
  padding: 0 0.75rem
  font-size: .875rem
  cursor: pointer
  opacity: 1

  @include lbg_tablet
    height: 1.5rem

  &.ais-ClearRefinements-button--disabled
    pointer-events: none
    cursor: default
    background: $blueLighter
    color: $black20

.ais-HierarchicalMenu
  & > ul > li
    padding: 1rem 1.5rem
    border-bottom: 1px solid #fff

  .ais-HierarchicalMenu-label
    margin-right: 1.5rem

  & > ul > li > div > a > .ais-HierarchicalMenu-label
    font-size: 1.5rem

  ul ul > .ais-HierarchicalMenu-label
    font-size: 1.25rem

  .ais-HierarchicalMenu-count
    opacity: .5

  ul ul
    margin-top: 1rem

    .ais-HierarchicalMenu-item--selected  .ais-HierarchicalMenu-label
      font-weight: bold

    li
      padding: .25rem 0

  .ais-HierarchicalMenu-link
    color: $black
    display: flex
    align-items: center
    justify-content: space-between

.ais-SearchBox-reset,
.ais-SearchBox-submit,
.ais-SearchBox-loadingIndicator
  display: none

.ais-HitsPerPage-select
  color: $black
  background: $blueLighter
  height: 1.5rem
  font-size: .875rem
  border: none
  border-radius: 0
  padding: 0 .5rem

.ais-Pagination-list
  display: flex

  li
    a,
    span
      color: $black
      background: $blueLighter
      width: 1.625rem
      height: 1.625rem
      line-height: 1
      display: grid
      place-items: center

      @include lbg_tablet
        width: 1.5rem
        height: 1.5rem

    a:hover
      background: $blueLight

    span
      opacity: .25

  li.ais-Pagination-item--page
    display: none

    @include lbg_tablet
      display: block

  li.ais-Pagination-item--selected
    display: block

    a
      background: $black
      color: #fff

  li + li
    margin-left: 2px

.ais-RangeSlider
  margin: 1rem 1.5rem 2rem 1.5rem
  
  @include lbg_tablet
    margin: 2rem 1.5rem

  .rheostat
    height: 1.125rem
    position: relative

  .rheostat-progress,
  .rheostat-background
    position: absolute
    top: 50%
    left: 0
    width: 100%
    height: 1px
    transform: translateY(-50%)
    background: rgba(0,0,0,.1)

  .rheostat-progress
    z-index: 1
    background: $black

  .rheostat-tooltip
    position: absolute
    bottom: 100%
    margin-bottom: .5rem
    left: 50%
    transform: translateX(-50%)
    font-size: 13px
    border-radius: 4px

  .rheostat-handle
    z-index: 2
    top: 50%
    height: 1.5rem
    width: 1.5rem
    background: #fff
    border: 1px solid $black
    border-radius: 50%
    transform: translate(-50%, -50%)
    cursor: ew-resize

    @include lbg_tablet
      height: 1.125rem
      width: 1.125rem