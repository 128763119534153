nav ul.sub-menu
  display: none
  overflow: hidden
  margin-bottom: 1.25rem
  flex-direction: column

  @include lbg_desktop
    overflow: visible
    position: absolute
    display: block
    top: 100%
    left: -1.5rem
    width: 250px
    margin: 0
    opacity: 0
    pointer-events: none
    transition: opacity .25s ease .1s, transform .1s ease .35s
    padding: 1.25rem 0
    border: 1px solid hsla(var(--primary-hsl), .2)
    background: hsla(var(--white-hsl), .75)
    backdrop-filter: blur(8px)

    &:after, 
    &:before
      bottom: 100%
      left: 3rem
      border: solid transparent
      content: ""
      height: 0
      width: 0
      position: absolute
      pointer-events: none

    &:after
      border-color: rgba(255, 255, 255, 0)
      border-bottom-color: #fff
      border-width: 8px
      margin-left: -8px
      
    &:before
      border-color: hsla(var(--primary-hsl), 0)
      border-bottom-color: hsla(var(--primary-hsl), .2)
      border-width: 9px
      margin-left: -9px

  a
    color: var(--color)
    display: block
    line-height: 1.25
    white-space: initial
    text-wrap: balance
    padding: .375rem 1.5rem

    @include lbg_desktop
      padding: .5rem 1.5rem

    @media (hover: hover)
      &:hover
        color: var(--primary)

  .current-menu-item a
    color: var(--primary)