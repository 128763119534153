.m31 {
  width: 100vw;
  margin-left: calc(-50vw + 635px);

  background-image: url('../images/triangle-background.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    position: relative;
    left: -12px;
    margin-left: 0;
  }
  @include tablet-big {
    position: relative;
    left: -12px;
    margin-left: 0;
  }
  @include tablet {
    margin-left: 0;
    position: relative;
    left: -40px;
  }
  @include mobile {
    margin-left: -24px;
  }

  .profile-hero {
    padding: 24px 0px 80px;
    width: 100%;

    @include mobile {
      padding: 32px 0;
    }
    @include tablet {
      padding: 24px 0;
    }

    &-breadcrumb {
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
      span {
        font-size: 14px;
        color: $pinkDark;
      }
      a {
        margin-right: 8px;
        color: $pinkDark;
        &:first-child {
          svg {
            margin-right: 0;
          }
        }
        svg {
          margin-right: 6px;
        }
      }
    }
    &-cart {
      display: flex;
      margin-top: 80px;
      @include mobile {
        margin-top: 32px;
      }
      &-thumb {
        margin-right: 48px;
        @include desktop {
          width: 170px;
          height: 225px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        @include tablet-big {
          width: 170px;
          height: 225px;
          margin-right: 70px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        @include tablet {
          width: 170px;
          height: 225px;
          margin-right: 70px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        @include mobile {
          width: 106px;
          height: 136px;
          margin-right: 18px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      &-body {
        flex: 1;
        @include mobile {
          display: flex;
          flex-direction: column;
          width: calc(100% - 186px);
          word-break: break-word;
        }
        &-title {
          display: flex;
          align-items: baseline;
          margin-bottom: 3px;
          @include mobile {
            margin-top: 3px;
            flex-direction: column;
          }
          h2 {
            color: $black;
            font-size: 48px;
            line-height: 56px;
            font-family: 'Antonia H2';
            margin-right: 9px;
            @include tablet {
              font-size: 46px;
              line-height: 50px;
            }
            @include mobile {
              font-size: 36px;
              line-height: 38px;
              margin-right: 0;
            }
          }
        }
        span {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          color: $pinkDark;
          @include mobile {
            font-size: 14px;
            line-height: 20px;
          }
        }
        label {
          font-size: 16px;
          line-height: 22px;
          color: $black;
          font-weight: 500;
          @include mobile {
            font-size: 14px;
            line-height: 20px;
          }
        }
        &-personal-infos {
          margin-top: 56px;
          @include mobile {
            margin-top: 32px;
          }
          &-item {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            @include mobile {
              flex-wrap: wrap;
            }

            &-download {
              a {
                font-size: 18px;
                line-height: 20px;
                color: $black;
                font-weight: 500;
                &:hover {
                  color: $green;
                }
              }
            }
            &-span {
              max-width: 80px;
              width: 100%;
              @include mobile {
                white-space: nowrap;
              }
              span {
                font-variant: small-caps;
                font-size: 14px;
                line-height: 20px;
                color: $black;
                font-weight: 500;
              }
            }
            transition: $transition1;
            &:hover {
              a {
                color: $green;
                transition: $transition1;
              }
            }
            span {
              a {
                color: $black;
                font-size: 18px;
                font-weight: 500;

                span {
                  color: inherit;
                  font-size: inherit;
                  font-weight: inherit;
                }

              }
            }
          }
          &-links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            transition: $transition1;
            @include tablet {
              margin-top: 48px;
            }
            @include mobile {
              margin-top: 36px;
            }
            h3 {
              font-family: 'Antonia H3';
              font-size: 22px;
              line-height: 26px;
              color: $black;
              margin-bottom: 12px;
            }
            a {
              margin-bottom: 10px;
            }
            span {
              color: $blue;
              &:hover {
                color: $green;
                transition: $transition1;
              }
            }
          }
        }
      }
    }
  }
}
