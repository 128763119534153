.m06s {
  width: 100%;
  min-height: 530px;
  margin: 0 auto;
  padding-bottom: 80px;
  position: relative;
  background: $blueLight;
  display: flex;
  align-items: flex-end;
  @include tablet {
    min-height: 455px;
  }
  &.is--white {
    .banner-inner {
      h5 {
        color: #fff;
      }
      h1 {
        color: #fff;
      }
    }
    .btn-primary--outline {
      color: #fff;
      border-color: #fff;
      transition: $transition1;
    }
  }

  .container {
    position: relative;
    z-index: 3;
  }

  .banner-inner {
    max-width: 850px;
    width: 100%;
    &--white{
      color: #fff; 
      border-color: #fff;
    }
    &--black{
      color: $black;
      border-color: $black;
    }
    h5 {
      font-size: 28px;
      line-height: em(32, 28);
      color: inherit;
      font-weight: 500;
      margin-bottom: 20px;
      @include mobile {
        font-size: 22px;
        line-height: em(28, 22);
      }
      @include tablet {
        font-size: 24px;
        line-height: em(28, 24);
      }
    }
    h1 {
      font-size: 60px;
      font-weight: 400;
      color: inherit;
      line-height: 1.07143em;
      font-family: 'Antonia H1';
      @include mobile {
        font-size: 40px;
        line-height: em(42, 40);
      }
      @include tablet {
        font-size: 50px;
        line-height: em(56, 50);
      }
    }
  }

  .btn-primary--outline {
    padding: 16px 24px;
    background: none;
    margin-right: 16px;
    color: inherit;
    border-color: inherit;
    min-width: auto;
    transition: $transition1;

    &:hover {
      color: inherit;
      border-color: inherit;
    }
  }
  .btn-area {
    display: flex;
    margin-top: 30px;
    float: left;
  }

  .m06s_keyvisual {
    &, 
    img,
    video,
    .m06s_keyvisual__overlay {
      position: absolute;
      top: 0;
      left: 0; 
      width: 100%;
      height: 100%;
    }

    img, 
    video {
      object-fit: cover;
      z-index: 1;
    }

    .m06s_keyvisual__overlay {
      z-index: 2;
    }
  }

  &.contain  .m06s_keyvisual {
    background: #000;

    img, 
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.m06s.bluedark .m06s_keyvisual__overlay {
  background: linear-gradient(45deg, rgba(0, 40, 155, 0), rgba(0, 50, 195, 1));
}
.m06s.lightblue .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(54, 80, 153, 0), rgba(110, 133, 198, 1));
}
.m06s.flieder .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(71, 42, 81, 0), rgba(149, 110, 161, 1));
}
.m06s.darkblue_alpha .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(0, 50, 195,.8), rgba(0, 50, 195,.8));
}
.m06s.darkblue_gradient .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(0, 50, 195, 0), rgba(0, 50, 195, 1));
}
.m06s.darkblue_solid .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(0, 50, 195, 1), rgba(0, 50, 195, 1));
}
.m06s.lightblue_alpha .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(110, 133, 198,.8), rgba(110, 133, 198,.8));
}
.m06s.lightblue_gradient .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(110, 133, 198, 0), rgba(110, 133, 198, 1));
}
.m06s.lightblue_solid .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(110, 133, 198, 1), rgba(110, 133, 198, 1));
}
.m06s.turquoise_alpha .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(0, 186, 150,.8), rgba(0, 186, 150,.8));
}
.m06s.turquoise_gradient .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(0, 186, 150, 0), rgba(0, 186, 150, 1));
}
.m06s.turquoise_solid .m06s_keyvisual__overlay {
  background: linear-gradient(225deg, rgba(0, 186, 150, 1), rgba(0, 186, 150, 1));
}