.m25 {
  width: 100%;
  .more-tags {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    float: left;
    color: #fff;
    background: url('../images/dots.svg') no-repeat right;
    background-size: 14%;
  }
  .popup-tags {
    display: none;
    width: 157px;
    min-height: 163px;
    float: left;
    padding: 20px;
    padding-left: 36px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(32, 48, 65, 0.21);
    z-index: 1;
    position: absolute;
    right: -157px;
    bottom: -140px;
    span {
      font-size: 16px;
      line-height: em(18, 16);
      color: #939aae;
      font-weight: 500;
      margin-bottom: 20px;
      bottom: -140px;

      &:before {
        content: '';
        float: left;
        width: 14px;
        height: 18px;
        background: url(../images/label-icon.svg) no-repeat 0 top;
        background-size: 88%;
        position: absolute;
        left: 14px;
      }
    }
    ul.tags-area {
      display: flex;
      flex-direction: column;
      position: relative !important;
      left: auto !important;
      bottom: auto !important;
      margin-top: 20px;
      li {
        float: left;
        margin-right: 0;
        margin-bottom: 10px !important;
        a {
          background: #f6f3f7;
          &:hover {
            background: #dee7ff;
          }
        }
      }
    }
  }
  .popup-tags.is--active {
    display: block;
  }
  .btn-secondary--outline {
    background-color: blue;
    margin: 0 auto;
    margin-top: 40px;
  }

  &__breadcrumb {
    transition: $transition1;
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }
    span {
      font-size: 14px;
      color: $pinkDark;
      transition: $transition1;
    }
    a {
      margin-right: 8px;
      transition: $transition1;
      color: $pinkDark;
      &:hover {
        span {
          color: $green;
          transition: $transition1;
        }
      }
    }
  }
  &__checkbox-margin {
    li {
      margin-bottom: 0;
    }
  }
  .checkbox_btns {
    z-index: 6;
  }
  .custom_date_filter {
    display: flex;
    flex-direction: column;
    &-von {
      display: flex;
      align-items: center;
    }
    &-bis {
      display: flex;
      padding-left: 22px;
      margin-top: 8px;
    }
  }
  .dropdown-is--active {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: #f1f5ff;
    align-items: center;
    padding: 0 24px;
    position: relative;
    &.is--active{
      .m25__actions__dropdown{
        transform: rotate(45deg);
      }
    }
    &_items {
      display: flex;
      justify-content: space-between;
      margin-top: -65px;
    }
    .search_filter {
      padding-right: 20px;
      &.search_filter--years{
        width: 50%;
      }
    }
    span {
      cursor: pointer;
    }
    span.text99 {
      width: calc(100% - 20px);
    }
    &_dropdown {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      display: block;
      width: 100%;
      padding: 74px 107px;
      background-color: #f1f5ff;
      border-radius: 3px;
      box-shadow: 0 4px 20px rgba(32, 48, 65, 0.21);
      position: absolute;
      z-index: 4;
      transition: $transition1;
      left: 0;
      top: 0;
    }
  }
  &__footer_pagination {
    margin-top: 17px;
    padding-top: 16px;
    border-top: 1px solid $pink;
  }
  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(184, 169, 188, 0.5);
    padding-bottom: 16px;
    
    @include lbg_tablet {
      margin-top: 32px;
    }

    &__dropdown {
      z-index: 6;
      transition: $transition1;
    }
    &-left {
      display: flex;
      align-items: center;
      @include mobile {
        justify-content: space-between;
        width: 100%;
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.42857em;
          color: #0032c3;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &__results {
      display: flex;
      align-items: center;
      margin-right: 40px;
      position: relative;
      &.is--active {
        .m25__actions__result_select {
          opacity: 1;
          visibility: visible;
          overflow: visible;
        }
      }
    }

    &__result {
      &_value {
        display: flex;
        align-items: center;
        h6 {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $blue;
          border: 1px solid $blue;
          border-radius: 4px;
          margin: 0 8px 0 0;
          padding: 0 4px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          letter-spacing: 0.01px;
          text-transform: uppercase;
          
          span {
            font-size: inherit;
            color: inherit;
          }
        }
      }

      &_select {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        width: calc(100% + 5px);
        padding: 24px;
        background-color: rgba($color: #fff, $alpha: 0.95);
        border-radius: 3px;
        box-shadow: 0 4px 20px rgba(32, 48, 65, 0.21);
        position: absolute;
        top: -24px;
        left: -24px;
        z-index: 4;
        transition: $transition1;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          letter-spacing: 0.01px;
          text-transform: uppercase;
          span {
            font-weight: normal;
          }
        }

        ul {
          margin-left: 14px;
          margin-top: 1px;
        }
        li {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          position: relative;
          &.is--active {
            color: $blue;
            &:after {
              content: '';
              width: 12px;
              height: 10px;
              background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='10px' viewBox='0 0 12 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Sprint-2-/-Abgabe' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='XLg_Suchergebnisse-with-results' transform='translate(-531.000000, -517.000000)' fill='%230032C3' fill-rule='nonzero'%3E%3Cg id='Group-32' transform='translate(391.000000, 497.000000)'%3E%3Cg id='checkmark' transform='translate(140.000000, 20.000000)'%3E%3Cpath d='M3.29079567,9.82407895 C3.08079567,9.64407895 3.05079567,9.34407895 3.22079567,9.13407895 C3.22079567,9.13407895 3.22079567,9.13407895 3.22079567,9.13407895 L10.7407957,0.174078947 C10.9107957,-0.0359210526 11.2207957,-0.0559210526 11.4307957,0.114078947 L11.4307957,0.114078947 C11.6407957,0.284078947 11.6607957,0.594078947 11.4907957,0.804078947 L3.97079567,9.76407895 C3.80079567,9.96407895 3.49079567,9.99407895 3.29079567,9.82407895 C3.29079567,9.82407895 3.29079567,9.82407895 3.29079567,9.82407895 Z' id='Rectangle_2_'%3E%3C/path%3E%3Cpath d='M0.170795668,5.34407895 C0.380795668,5.17407895 0.690795668,5.19407895 0.860795668,5.40407895 C0.860795668,5.40407895 0.860795668,5.40407895 0.860795668,5.40407895 L3.99079567,9.13407895 C4.16079567,9.34407895 4.14079567,9.64407895 3.93079567,9.82407895 C3.93079567,9.82407895 3.93079567,9.82407895 3.93079567,9.82407895 L3.93079567,9.82407895 C3.73079567,9.99407895 3.42079567,9.96407895 3.25079567,9.76407895 L3.25079567,9.76407895 L0.110795668,6.02407895 C-0.059204332,5.82407895 -0.029204332,5.51407895 0.170795668,5.34407895 C0.170795668,5.34407895 0.170795668,5.34407895 0.170795668,5.34407895 Z' id='Rectangle-2_2_'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
              background-size: 12px;
              background-repeat: no-repeat;
              position: absolute;
              right: -22px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
  }
  &__item {
    width: 100%;
    // border-bottom: 1px solid rgba($pink, $alpha: 0.5);
    padding: 40px 0;
  }
  .radio_btns li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .news_box-disc_right {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    @include tablet {
      margin-top: 16px;
      padding-left: 0;
    }
  }

  .tags-area {
    position: absolute;
    bottom: 9px;
    left: 8px;
    > li a {
      background-color: white;
    }
  }

  .news_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &:hover {
      .box-thumb img {
        opacity: 0.9;
      }
    }
    &-thumb {
      position: relative;
      min-width: 190px;
      max-width: 190px;
      height: 141px;
      @include tablet-big {
        min-width: 220px;
        max-width: 220px;
        height: 164px;
      }
      @include tablet {
        min-width: 208px;
        max-width: 208px;
        height: 158px;
      }
      @include mobile {
        min-width: 100px;
        max-width: 100px;
        height: 76px;
      }
      img {
        @extend %imgCropped;
        transition: $transition1;
      }
    }
    &-dsc {
      width: 100%;
      max-width: 455px;
      padding: 0;
      position: relative;
      line-height: 1.25;
      color: #001141;
      
      h3 {
        font-size: 20px;
        line-height: em(24, 20);
        color: #001141;
        font-family: 'Antonia H3';
        font-weight: 500;
        word-break: break-word;
        transition: color .25s ease;
        margin-bottom: .25rem;

        @include tablet-big {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
        }
        @media (hover: hover) {
          &:hover {
            color: $green
          }
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      @include tablet {
        position: relative;
        bottom: auto;
        margin-top: 8px;
      }
      @include mobile {
        margin-top: 8px;
        bottom: -42px;
      }

      h5 {
        font-weight: normal;
        color: #001141;
        font-weight: normal;
        font-size: 14px;
        line-height: em(20, 14);
      }
      span {
        font-size: 14px;
        color: #001141;
        line-height: em(20, 14);
        font-weight: normal;
      }
    }
  }
  .event_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &-date {
      min-width: 84px;
      max-width: 84px;
      display: flex;
      flex-direction: column;
      h5 {
        font-family: 'Antonia H2';
        font-size: 64px;
        font-weight: 300;
        color: #001141;
        display: flex;
      }
      h6 {
        font-size: 14px;
        line-height: em(20, 14);
        font-weight: normal;
        color: $black;
        margin-top: -5px;
      }
    }
    &-dsc {
      width: 100%;
      max-width: 563px;
      padding: 0 24px;
      h3 {
        font-family: 'Antonia H3';
        font-size: 22px;
        line-height: em(26, 22);
        color: #001141;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;

      span {
        font-size: 14px;
        color: #001141;
        line-height: em(20, 14);
        font-weight: normal;
      }
    }
  }

  .team_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &:hover {
      .box-thumb img {
        opacity: 0.9;
      }
    }
    &-thumb {
      position: relative;
      min-width: 190px;
      max-width: 190px;
      height: 141px;
      @include tablet-big {
        min-width: 220px;
        max-width: 220px;
        height: 164px;
      }
      @include tablet {
        min-width: 208px;
        max-width: 208px;
        height: 158px;
      }
      @include mobile {
        min-width: 100px;
        max-width: 100px;
        height: 76px;
      }
      img {
        width: 85px;
        height: 110px;
        object-fit: cover;
        float: right;
        transition: $transition1;
      }
    }
    &-dsc {
      width: 100%;
      max-width: 455px;
      padding: 0 24px;
      font-size: 20px;
      line-height: em(24, 20);
      color: #001141;
      position: relative;
      @include tablet {
        padding-left: 0;
        margin-top: 15px;
      }
      @include mobile {
        padding: 0 0 0 10px;
      }
      h3 {
        font-size: 18px;
        line-height: em(24, 18);
        color: #001141;
        font-weight: 600;
        word-break: break-word;
        margin-bottom: 4px;
        @include mobile {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
        line-height: em(20, 16);
        font-weight: 500;
        color: $pinkDark;
        &:first-of-type {
          margin-bottom: 16px;
        }
        a {
          color: inherit;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
    }
  }
  .publication_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &-dsc {
      width: 100%;
      max-width: 648px;
      padding-right: 24px;
      color: #001141;
      @include tablet {
        padding-right: 0;
      }
      @include mobile {
        padding-right: 0;
      }
      h3 {
        font-family: 'Antonia H3';
        font-size: 20px;
        font-weight: 500;
        line-height: em(24, 20);
        color: #001141;
        margin-bottom: 8px;
        @include tablet-big {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
        }
      }
      span {
        font-size: 14px;
        font-weight: normal;
        line-height: em(20, 14);
        color: $black;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      span {
        font-size: 14px;
        color: #001141;
        line-height: em(20, 14);
        font-weight: normal;
      }
    }
  }
  .seite_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &-dsc {
      width: 100%;
      max-width: 648px;
      padding-right: 24px;
      @include tablet {
        padding-right: 0;
      }
      @include mobile {
        padding-right: 0;
      }
      h3 {
        font-family: 'Antonia H3';
        font-size: 20px;
        font-weight: 500;
        line-height: em(24, 20);
        color: #001141;
        margin-bottom: 8px;
        @include tablet-big {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
        }
      }
      p {
        font-size: 18px;
        font-weight: normal;
        line-height: em(24, 18);
        color: $black;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      h5 {
        font-size: 16px;
        font-weight: normal;
        line-height: em(22, 16);
        text-transform: uppercase;
        color: $black;
      }
    }
  }
}
