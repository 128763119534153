// basic spacing
.lbg__breadcrumbs,
.lbg__linked-posts,
.m02,
.m03,
.m04,
.m06,
.m07,
.m07o,
.m08,
.m09,
.m10,
.m11,
.m12,
.m12b,
.m13,
.m14,
.m15,
.m16,
.m17,
.m17s,
.m18,
.m19,
.m20,
.m21,
.m22,
.m23,
.m24,
.m25,
.m26,
.m27,
.m28,
.m29,
.m30,
.m32,
.m33,
.m34,
.m37,
.m38,
.m41,
.m42,
.m43,
.m46,
.m48,
.m49,
.m50,
.m51,
.m52,
.m52__single,
.m53,
.m53__single,
.m54,
.m57,
.m59[data-variation="module"],
.m61,
.m68
  margin: 60px 0

  @include lbg_tablet
    margin: 80px 0

  @include lbg_desktop
    margin: 100px 0

  &:first-child
    margin-top: calc($m-vs/2)

    @include lbg_tablet
      margin-top: $t-vs

  .lbg__breadcrumbs + &
    margin-top: 3rem

// utilizes more margin
.m62,
.m63,
.m64,
.m65,
.m66
  margin: 6rem 0

  @include lbg_tablet
    margin: clamp(4rem, 20vh, 15rem) 0

// remove top margin for specific containers
.m06,
.m15
  margin-top: 0 !important

// has background color and needs additional padding
.m04,
.m05,
.m06__section,
.m09,
.m24,
.m28,
.m30,
.m60
  padding: 3rem 0

  @include lbg_tablet
    padding: 4rem 0

  @include lbg_desktop
    padding: 5rem 0

  & + &,
  & + .m06,
  & + .m06s,
  .m06 + &,
  .m06s + &
    margin-top: -56px

    @include lbg_tablet
      margin-top: -74px

    @include lbg_desktop
      margin-top: -90px

  &.has-breadcrumbs
    padding-top: 1.5rem

    .lbg__breadcrumbs--inline
      margin-bottom: 1.5rem

      @include lbg_tablet
        margin-bottom: 2rem

      @include lbg_desktop
        margin-bottom: 3rem

  &:last-child
    margin-bottom: $gutter !important