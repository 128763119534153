.m68
  &[data-variation="100"]
    .container
      max-width: 100%
      padding: 0

    &:last-child
      margin-bottom: $gutter !important

  video
    width: 100%
    height: auto
    aspect-ratio: var(--ratio)
    background: var(--blue-light)

  iframe
    display: block
    width: 100%
    height: auto
    aspect-ratio: 16/9