.footer-cookies-right
  display: flex
  flex-direction: column
  align-items: flex-end

.footer-cookies-checkbox
  display: flex
  align-items: center
  justify-content: space-between
  background: #f6f3f7
  border-radius: 3px
  @include mobile
    width: 100%
    margin-top: 20px
    flex-wrap: wrap
  
  @include tablet
    margin-top: 40px
  
  > div
    padding: 11px 16px
    
    @include mobile
      padding: 8px 5px
  
  //checkbox
  input
    padding: 0
    height: initial
    width: initial
    margin-bottom: 0
    display: none
    cursor: pointer
  
  label
    position: relative
    user-select: none
    cursor: pointer
    &:hover
      color: $darkGreen
      &:before
        border: 1px solid $darkGreen

  label:before
    width: 14px
    height: 14px
    margin-top: 0

  label:after
    margin-top: 0

  input[disabled]:checked + label
    cursor: default
    &:after,
    &:before
      opacity: 0.4

    &:before
      background-color: #dcdcdc
      border: 1px solid #dcdcdc

  input:checked + label:before
    background-color: $blue
    border: 1px solid $blue

  input:checked + label:after
    width: 14px
    height: 14px
    background-position-y: 3px
    opacity: 1