.lbg__sidebar-links
  background: #f1f5ff
  padding: $gutter*1.5

  .fz-h4
    margin-bottom: .5rem

  a
    display: grid
    gap: 1rem
    color: $blue
    transition: color .25s ease
    padding: .375rem 0
    grid-template-columns: 1fr 1.5rem 3.5rem

    &:hover
      color: $darkGreen
    
    span
      grid-column: span 1
      
      &:last-child
        text-align: right

    &.isLink
      grid-template-columns: 1fr 1rem

      span:nth-child(2)
        padding-top: .2rem

    &.isFile
      align-items: center

      span:nth-child(1)
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

      span:nth-child(2)
        text-transform: uppercase

  a + a
    border-top: 1px solid rgba($blueDark,0.1)

  div + div
    margin-top: $gutter