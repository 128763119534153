.m12b {
  width: 100%;
  background-color: #f1f5ff;

  &__btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    &:not(:hover) {
      background-color: transparent;
    }
    .btn-primary--outline__icon {
      background-image: none !important;
      svg {
        color: inherit;
      }
    }
  }
  &__list {
    max-height: 380px;
    overflow: auto;
    margin-right: -30px;
    padding-right: 30px;
    @include mobile {
      max-height: 75%;
    }
    .releases-list__item {
      @include tablet {
        flex-direction: column;
      }
    }
    .releases-list__title {
      @include tablet {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  &__search {
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
    &__left {
      width: 75%;
      @include mobile {
        width: 100%;
      }
    }
    &__right {
      width: 25%;
      padding-left: 60px;
      @include mobile {
        width: 100%;
        padding-left: 0;
        margin-top: 23px;
      }
      @include tablet {
        padding-left: 50px;
      }
    }
    &__title {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      line-height: em(20, 14);
      font-weight: 500;
      color: $black;
      letter-spacing: 0.01px;
      margin-bottom: 8px;
    }
    &__icon {
      margin-right: 10px;
      color: $black;
    }
    &__input {
      width: 100%;
      padding: 10px 12px;
      box-shadow: 0 1px 10px $pink;
      background-color: #fff;
      border-radius: 3px;
      display: flex;
      align-items: center;
      input[type='text'] {
        width: calc(100% - 30px);
        padding: 0;
        outline: 0;
        border: 0;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
}

.releases-list {
  &__item {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid $pink;
    display: flex;
    justify-content: space-between;
    &:hover {
      .releases-list__title {
        color: $darkGreen;
      }
      .releases-list__icon svg {
        color: $darkGreen;
      }
    }
    @include mobile {
      padding: 24px 0;
      flex-direction: column;
    }
  }
  &__title {
    width: calc(100% - 260px);
    font-size: 16px;
    line-height: em(22, 16);
    font-weight: 500;
    color: $black;
    transition: $transition1;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: calc(100% - 350px);
    }
  }
  &__information {
    width: 260px;
    display: flex;
    align-items: center;
    @include mobile {
      width: 100%;
    }
    span {
      display: flex;
      justify-content: center;
      font-size: 16px;
      line-height: em(22, 16);
      color: $black;
      margin-right: 24px;
      white-space: nowrap;
      font-variant: small-caps;
      text-transform: uppercase;
      transition: $transition1;
    }
  }
  &__size {
    color: $pinkDark !important;
  }
  &__icon {
    display: flex;
    svg {
      width: 8px;
      height: 12px;
      transition: $transition1;
    }
  }
}
