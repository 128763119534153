.m17 {
  width: 100%;
  .section-title {
    font-size: 36px;
    margin-bottom: 16px;
  }
  .small_accordions__content{
    a{
      font-size: 16px;
      font-weight: 500;
      color: $black;
      text-decoration: none;
      border-bottom: 1px solid $green;
      transition: $transition1;
      &:hover {
        color: $green;
      }
      &:focus {
        color: $blueDark;
        border-bottom-color: $blueDark;
      }
    }

    .wysiwyg ul:not(.extra-links):not(.stats-list-ul) {
      margin: 0 0 30px;
      padding-left: 10px;
      max-width: initial;
    }
  }
}

.single-team{
  .m17{
    .container{
      padding: 0;
    }
  }
}
.m17s{
  &.show-search{
    .sidebar_search{
      display: flex!important;
    }
  }
  .sidebar_search{
    display: none!important;
  }
}