.swiper-meta
  display: flex
  gap: 1rem
  line-height: 1.25
  flex-direction: column
  align-items: stretch

  @include lbg_mobile
    margin: 2rem var(--offsetWide)
    justify-content: space-between
    flex-direction: row

.swiper-archive
  font-size: 16px
  color: var(--primary)
  align-self: center
  display: flex
  gap: .625rem
  justify-content: space-between
  align-items: center
  color: var(--primary)
  font-size: 1.125rem

  @include to_mobile
    text-decoration: underline

  .lbg-arrow
    display: none
    margin-bottom: -.125rem
  
    @include lbg_mobile
      display: grid

.swiper-description
  color: var(--primary)

  @include lbg_mobile
    margin-top: .5rem
    max-width: 600px
    text-wrap: balance

.swiper-controls
  display: flex
  position: relative
  z-index: 21
  user-select: none
  justify-content: space-between
  order: -1
  gap: .25rem
  margin: 1rem 0 0
  height: 3rem
  border-radius: 3rem
  border: 1px solid var(--primary)

  @include lbg_mobile
    order: 2
    gap: .25rem
    margin: 0
    height: 2.5rem
    border-radius: 2.5rem
    border: 1px solid var(--primary)

  .swiper-index
    justify-content: center
    padding: 0

    span
      min-width: .5rem
      text-align: center

  .swiper-prev,
  .swiper-next
    cursor: pointer

    @media (hover: hover)
      &:hover
        .lbg-arrow::before
          transform: scaleX(.75)

    &.swiper-button-disabled
      pointer-events: none
      opacity: .3

  .swiper-prev .lbg-arrow
    transform: rotate(180deg)

  > *
    display: flex
    align-items: center
    position: relative
    line-height: 1
    text-transform: uppercase
    color: var(--primary)
    font-weight: 500
    letter-spacing: 0.1em
    font-size: .875rem
    padding: 0 1.5rem

    @include lbg_mobile
      padding: 0 1rem
