.m29 {
  .team-profiles {
    margin-top: 0 !important
  }
  
  .label-checkbox-color {
    color: #0032c3;
  }

  .checkbox_btns li:last-child {
    margin-bottom: 16px;
  }
  .m20__box__link {
    margin-left: 20px;
  }

  .jobs-listing-content-header {
    border-bottom: 1px solid $pink;
    padding-bottom: 3px;
    &--padding {
      margin-left: -30px;
    }
    span {
      font-size: 14px;
      line-height: 20px;
      color: $pinkDark;
      text-transform: uppercase;
    }
  }
  .jl-dropdown {
    span {
      display: flex;
      margin-right: 8px;
    }
  }
  .jl-rotate {
    &:after {
      transform: rotate(211deg);
    }
  }
  .team-profiles-content-header {
    @include tablet {
      margin-bottom: 24px;
    }
    @include mobile {
      margin-bottom: 24px;
    }
  }
  .team-profiles-sidebar-search {
    @include tablet {
      margin-bottom: 40px;
    }
    @include mobile {
      margin-bottom: 40px;
    }
  }
}
.jobs-listing-content-item {
  display: flex;
  margin-top: 4px;
  span {
    font-size: 12px;
    line-height: 22px;
    color: $pinkDark;
  }
  &-title {
    font-size: 16px;
    line-height: 22px;
    color: $black;
    font-weight: 500;
  }
  &--time {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:before {
      content: '';
      width: 14px;
      height: 12px;
      display: inline-block;
      margin-right: 4px;
      background: url('../images/history-icon.svg') no-repeat center;
      background-position: center;
    }
  }
  &--majority {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-right: 4px;
      background: url('../images/majority-icon.svg') no-repeat center;
      background-position: center;
    }
  }
}

.jobs-listing-content-items {
  &--pagination {
    display: flex;
    margin-top: 40px;
    span {
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      color: $blue;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &--numbers {
    display: flex;
    align-items: center;
    span.currentPage {
      color: $green;
    }
    span {
      margin-right: 8px;
    }
  }
  &--btn {
    display: flex;
    align-items: center;
    margin-left: 16px;
    &:hover {
      cursor: pointer;
    }
    .btn-primary--outline__icon {
      margin-left: 8px;
    }
    .btn-primary--outline__icon__left {
      margin-right: 8px;
    }
    span.backPage {
      margin-right: 8px;
    }
  }
}

.jlci-padding {
  padding: 24px 0px;
  border-bottom: 1px solid $pink;
}

.jlcic-style {
  @include mobile {
    margin-top: 16px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
  &-mobile {
    display: flex;
    flex-direction: column;
    span {
      margin-bottom: 8px;
    }
    &-item {
      display: flex;
    }
  }
}
