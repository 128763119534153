.m09 {
  background-color: $pinkLight;

  #lineCont {
    width: 100%;
    height: 20%;
    margin-top: 56px;
    background: #b8a9bc;
  }
  #line {
    height: 5px;
    width: 70%;
    border-radius: 5px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }
  #span {
    display: none;
    width: 70%;
    margin: auto;
    margin-top: 25%;
    text-align: center;
    color: white;
  }
  .item-wrapper {
    max-width: 725px;
    margin: 0 auto;
    display: flex;
    @include mobile {
      flex-direction: column;
      max-width: 100%;
    }
  }
  .item-content {
    float: left;
    width: 50%;
    text-align: left;
    margin-right: 24px;
    margin-top: 85px;
    @include mobile {
      width: 100%;
      margin-right: 0;
    }
    h3 {
      color: #001141;
      font-size: 20px;
      line-height: em(24, 20);
      font-family: 'Antonia H3';
      font-weight: 500;
      margin-bottom: 7px;
      cursor: pointer;

      &:hover {
        color: #0032c3;
      }
    }
    p {
      color: #001141;
      font-size: 14px;
      line-height: em(16, 14);
      font-weight: normal;
    }
  }
  .item-thumb {
    float: left;
    width: 400px;
    height: 540px;
    position: relative;

    &:hover {
      .item-thumb-expand {
        opacity: 1;
      }
    }

    img {
      @extend %imgCropped;
    }

    @include mobile {
      width: 210px;
      height: 297px;
      margin-left: 70px;
      margin-top: 40px;
    }

    &-expand {
      opacity: 0;
      position: absolute;
      top: 55px;
      right: 55px;
      cursor: pointer;
      background: transparent;
      padding: 0;
      margin: 0;
      border: none;
      outline: 0;
    }
  }

  .circle {
    width: 9px;
    height: 9px;
    background: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    border: 2px solid #7e6e7e;
    cursor: pointer;
    transition: $transition1;
    &:after {
      content: '';
      @extend %centered;
      background: #fff;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      left: 3px;
      transition: $transition1;
      top: 2px;
    }
    &:hover,
    &.active {
      width: 26px;
      height: 26px;
      border: 2px solid #0032c3;
      top: -8px;
      background: #fff;
      margin-left: -8px;
      &:after {
        background: #0032c3;
        width: 10px;
        height: 10px;
        left: 11px;
        z-index: 1;
        top: 11px;
      }
    }
    &:hover {
      border: 2px solid #008576;
      &:after {
        background: #008576;
      }
    }

    .popupSpan {
      display: none !important;
    }

    &.hover:before,
    &.active:before {
      display: block;
    }
    &.hover .popupSpan,
    &.active .popupSpan {
      display: block;
    }
    &.active .popupSpan {
      top: -40px;
    }
  }

  #mainCont {
    height: 80%;
    width: 100%;
    transition: $transition1;
    position: relative;
    height: 650px;
    background: #f6f3f7;

    &-track {
      position: relative;
      width: 5000px;
      transition: $transition2;
      padding: 0 340px;
      @include tablet-big {
        padding: 0;
      }
      @include tablet {
        padding: 0;
      }
      @include mobile {
        padding: 0;
      }
    }
    span {
      display: inline-block;
      width: 750px;
      // height: 100%;
      top: 124px;
      transition: $transition1;

      &.right {
        color: red;
        // margin-left: 10%;
      }
      &.center {
        color: yellow;
      }
      &.left {
        color: rebeccapurple;
      }
    }

    $triangleTranstion: all 2s;

    img {
      // width: 50px;
      // height: 50px;
      object-fit: cover;
    }

    [data-triangle='1'] {
      position: absolute;
      // top: 100px;
      // left: 15%;
      // transition: $triangleTranstion;
    }

    [data-triangle='2'] {
      position: absolute;
      // bottom: 40px;
      // left: 15%;
      // transition: $triangleTranstion;
    }

    [data-triangle='3'] {
      position: absolute;
      // top: 150px;
      // right: 15%;
      // transition: $triangleTranstion;
    }
  }

  .modal-09 {
    padding: 0 100px;

    @include mobile {
      padding: 0 60px 0 20px;
    }

    .modal-content {
      max-width: 1186px;
      padding: 0;

      @include tablet {
        height: calc(100% - 150px);
      }

      @include mobile {
        height: calc(100% - 150px);
      }

      &-wrapper {
        display: flex;
        max-height: 600px;
        overflow: hidden;

        @include tablet {
          flex-direction: column;
          max-height: 100%;
          overflow: auto;
        }

        @include mobile {
          flex-direction: column;
          max-height: 100%;
          overflow: auto;
        }

        .info,
        .img {
          flex: 1;
        }

        .info {
          padding: 126px 54px 0 65px;

          @include tablet-big {
            padding: 40px 20px 0 20px;
          }

          @include tablet {
            padding: 40px 20px 0 20px;
          }

          @include mobile {
            padding: 20px;
          }

          &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 50px;

            @include tablet-big {
              padding-right: 0;
            }

            @include tablet {
              padding-right: 0;
            }

            @include mobile {
              padding-right: 0;
            }

            h1 {
              color: #001141;
              font-size: 20px;
              line-height: 1.2em;
              font-family: 'Antonia H3';
              font-weight: 500;
              cursor: pointer;
              max-width: 220px;
            }

            span {
              font-size: 36px;
              line-height: 1.11111em;
              color: #0032c3;
              font-weight: 500;
              font-family: 'Antonia H2';
            }
          }

          &-content {
            height: calc(100% - 39px);
            overflow: auto;
            padding-right: 50px;
            padding-bottom: 100px;

            @include tablet-big {
              padding-right: 0;
            }

            @include tablet {
              padding-right: 0;
            }

            @include mobile {
              padding-right: 0;
            }

            p {
              color: #001141;
              font-size: 18px;
              margin-bottom: 32px;
              line-height: 24px;
              font-weight: normal;
            }
          }
        }

        .img {
          background: #eee;
          padding: 40px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 381px;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .modal-close {
      background: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      position: absolute;
      right: -44px;
      top: 0;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}
