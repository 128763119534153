.m27 {
  .team-profiles {
    margin-top: 0 !important
  }
  
  .lwis_item {
    display: flex;
    justify-content: space-between;
    margin-left: 40px;
    @include mobile {
      margin-left: 0px;
      margin-top: 8px;
      justify-content: unset;
      span {
        margin-right: 24px;
      }
    }
    &-row {
      display: flex;
      align-items: center;
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: $black;
    }
    span {
      font-size: 16px;
      line-height: 22px;
      color: $black;
      margin-right: 17px;
      svg {
        transform: translateY(3px);
        color: $black;
      }

      svg path,
      svg polygon {
        transition: $transition1;
      }
    }

    a:hover {
      svg path,
      svg polygon {
        fill: $darkGreen;
      }
    }

    span:nth-child(3) {
      color: $pinkDark;
    }
  }
  .tpcht-right {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $black;
      margin-right: 17px;
      text-transform: uppercase;
    }
  }
}
