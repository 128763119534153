.m63
  // reset outdated styling behaviour
  clear: both
  font-size: 16px

  .m63__content
    grid-column: span 12
    color: var(--color)

    @include lbg_tablet
      grid-column: span 7
  
    &[data-fontsize="large"],
    &[data-fontsize="medium"]
      .m63__wysiwyg
        font-family: 'Antonia H1'
        line-height: 1.2
        hyphens: auto

        @include lbg_tablet
          hyphens: initial
          line-height: 1.1

    &[data-fontsize="default"]
      .m63__wysiwyg
        font-size: 18px
        line-height: 24px
        font-weight: normal

        @include mobile
          font-size: 16px
          
    &[data-fontsize="medium"]
      font-size: 1.875rem

      @include lbg_tablet
        font-size: 2rem
        grid-column: span 8

      @include lbg_desktop
        font-size: 2.125rem

    &[data-fontsize="large"]
      font-size: 1.5rem

      @include lbg_tablet
        font-size: 2.375rem
        grid-column: span 11

      @include lbg_desktop
        font-size: 2.625rem

    &[data-color="primary"]
      color: var(--primary)

  .m63__content:nth-child(even)
    @include lbg_tablet
      grid-column: 6 / span 7
    
      &[data-fontsize="medium"]
        grid-column: 5 / span 7

      &[data-fontsize="large"]
        grid-column: 3 / span 11

  .m63__content + .m63__content
    margin-top: clamp(2rem, 5vh, 5rem)

  .m63__ctas
    margin-top: 2rem
    display: flex
    flex-direction: column
    justify-content: stretch

    @include lbg_mobile
      gap: .75rem
      margin-top: 2.5rem
      gap: 2.5rem
      flex-direction: row
      flex-wrap: wrap
    
    li + li
      @include to_mobile
        padding-top: .875rem
        margin-top: .875rem
        border-top: 1px solid hsla(var(--primary-hsl), .1)

    a
      display: flex
      gap: .625rem
      justify-content: space-between
      align-items: center
      color: var(--primary)
      font-size: 1.125rem

      .lbg-arrow
        @include lbg_mobile
          margin-bottom: -.125rem