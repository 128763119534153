.m28 {
  width: 100%;
  padding: 150px 0 226px;
  background-color: #f1f5ff;
  
  &__wrapper {
    width: 100%;
    padding: 0 105px;
    margin-bottom: 80px;
    
    @include tablet-big {
      padding: 0;
      margin-bottom: 70px;
    }
    @include tablet {
      padding: 0;
      margin-bottom: 60px;
    }
    @include mobile {
      padding: 0;
      margin-bottom: 60px;
    }
  }
  &__item {
    width: 100%;
    display: flex;
    @include tablet-big {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      flex-direction: column;
    }
    &__left {
      width: 100%;
      max-width: 350px;
      @include tablet-big {
        max-width: 100%;
        display: flex;
        margin-bottom: 40px;
      }
      @include tablet {
        max-width: 100%;
        display: flex;
        margin-bottom: 40px;
      }
    }
    &__thumb {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;
      background: url('../images/triangle-bg.svg') no-repeat;
      margin-bottom: 16px;
      @include tablet-big {
        width: 200px;
        padding: 10px 0;
        background-size: contain;
        margin-bottom: 0;
      }
      @include tablet {
        width: 200px;
        padding: 10px 0;
        background-size: contain;
        margin-bottom: 0;
      }
      img {
        width: 192px;
        height: 192px;
        object-fit: cover;
        border-radius: 100%;
        overflow: hidden;
        @include tablet-big {
          width: 126px;
          height: 126px;
        }
        @include tablet {
          width: 126px;
          height: 126px;
        }
      }
    }
    &__title {
      width: 100%;
      @include tablet-big {
        width: calc(100% - 200px);
        padding-left: 30px;
      }
      @include tablet {
        width: calc(100% - 200px);
        padding-left: 30px;
      }
      h6 {
        font-size: 16px;
        line-height: em(22, 16);
        font-weight: 500;
        color: $pinkDark;
        margin-bottom: 2px;
        @include mobile {
          font-size: 14px;
          line-height: em(20, 14);
          color: $black;
          margin-bottom: 11px;
        }
      }
      h2 {
        font-family: 'Antonia H2';
        font-size: 48px;
        line-height: em(50, 48);
        font-weight: 500;
        color: $blue;
        margin-bottom: 4px;
        text-decoration: none;
        transition: $transition1;
        &:hover {
          color: $darkGreen;
        }
        @include mobile {
          font-size: 36px;
          line-height: em(38, 36);
          margin-bottom: 9px;
        }
      }
      h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: em(22, 16);
        color: $black;
        @include mobile {
          font-size: 14px;
          line-height: em(20, 14);
        }
      }
    }
    &__right {
      width: calc(100% - 350px);
      padding-left: 24px;
      margin-top: 30px;
      @include tablet-big {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      @include mobile {
        width: 100%;
        margin-top: 40px;
        padding-left: 0;
      }
      blockquote {
        width: 100%;
        position: relative;
        padding-left: 58px;
        @include mobile {
          padding-left: 20px;
        }
        span {
          width: 34px;
          height: 34px;
          position: absolute;
          left: 0;
          top: 0;
          @include mobile {
            width: 10px;
            height: 10px;
          }
          svg {
            width: 100%;
          }
        }
        p {
          font-size: 30px;
          line-height: em(40, 30);
          font-weight: 300;
          font-style: italic;
          color: $black;
          @include mobile {
            font-size: 24px;
            line-height: em(32, 24);
          }
        }
      }
    }
  }
  .slick-counter-quotes {
    position: absolute;
    bottom: -77px;
    left: 50%;
    transform: translateX(-50%);
    color: #00289b;
    opacity: 0.7;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: em(20, 14);
      letter-spacing: 0.02px;
    }
  }
  .slick-arrow {
    bottom: -85px;
    width: 40px;
    height: 36px;
    top: unset;
    &:before {
      display: none;
    }
  }
  .slick-prev {
    left: 50%;
    background: url(../images/arrow-outline-right-blue.svg) no-repeat 0 0;
    background-size: 40px;
    transform: translateX(-50%) rotate(-180deg);
    margin-left: -50px;
  }
  .slick-next {
    background: url(../images/arrow-outline-right-blue.svg) no-repeat 0 0;
    background-size: 40px;
    right: 50%;
    transform: translateX(-50%);
    margin-right: -88px;
  }
  @include mobile {
    .slick-next,
    .slick-prev {
      display: block !important;
    }
  }
  .slick-disabled {
    opacity: 0.6;
  }
}

.m28__item_2 .m28__item__thumb{
  background-image: url('../images/triangle-bg-1.svg');
}
.m28__item_3 .m28__item__thumb{
  background-image: url('../images/triangle-bg-2.svg');
}
.m28__item_4 .m28__item__thumb{
  background-image: url('../images/triangle-bg-3.svg');
}