// m48 & m49 share a lot of styling 
// m52 adds / overrides styles

.m52__single,
.m53__single
  .image-tags
    margin: 2rem 0 3rem 0

  .lbg-grid
    max-width: 880px
    margin: 3rem 0 0

.m52__single
  .pracices__links,
  .pracices__attachments
    grid-column: span 12

    @include lbg_tablet
      grid-column: span 6