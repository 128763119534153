:root
  --m54__logo_width: 6rem

  @include lbg_mobile
    --m54__logo_width: 7rem

  @include lbg_desktop
    --m54__logo_width: 8rem

.m54
  .m54__groups + .m54__groups
    margin-top: $gutter

  .m54__headline
    margin-bottom: $gutter
    display: block

  strong.m54__headline
    color: $black
    font-size: 18px

    @include mobile
      font-size: 16px

.m54__logos
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-top: 2rem

  a
    margin: 0 $gutter*1.5 $gutter 0
    
    @include lbg_mobile
      margin: 0 $gutter*2 $gutter*2 0

    &:last-child
      margin: 0 0 $gutter 0
    
      @include lbg_mobile
        margin: 0 0 $gutter*2 0

  img
    width: 100%
    margin-bottom: 0