.m35 {
  .error-page {
    padding: 150px 0px;
    @include tablet {
      padding: 120px 0px;
    }
    @include mobile {
      padding: 80px 0px;
    }
    &-left {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      transition: $transition1;
      @include mobile {
        margin: 0px 0px 80px 0px;
      }
      h1 {
        font-size: 20px;
        line-height: 24px;
        font-family: 'Antonia H3';
        color: $black;
        margin-bottom: 9px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
        color: $black;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;

      @include mobile {
        margin: 0;
      }
      h1 {
        font-size: 20px;
        line-height: 24px;
        font-family: 'Antonia H3';
        color: $black;
        margin-bottom: 9px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
        color: $black;
      }
      &-search {
        display: flex;
        align-items: center;
        padding: 16px 14px 16px 22px;
        border: 1px solid $blue;
        border-radius: 4px;
        max-width: 222px;
        width: 100%;
        justify-content: space-between;
        transition: $transition1;
        &:hover {
          color: white;
          border-color: $green;
          background-color: $green;
          transition: $transition1;
          label,
          span {
            color: white;
            transition: $transition1;
          }
        }
        span {
          display: block;
          width: 12px;
          height: 12px;
          background: url(../images/search-small-error.svg) no-repeat center;
        }
        label {
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          color: $blue;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
