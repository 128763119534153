.m34 {
  .teach-box {
    &-link {
      margin-top: -54px;
      @include mobile {
        display: none;
      }
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $pinkDark;
      padding-bottom: 8.5px;
      margin-top: 52px;
      h1 {
        color: $black;
        font-size: 20px;
        line-height: 24px;
        font-family: 'Antonia H3';
        font-weight: 500;
      }
    }
  }
}
