.m32 {
  .two-c-content {
    &-body {
      max-width: 516px;
      margin: 140px auto;
      @include mobile {
        margin: 120px 0px;
        max-width: unset;
      }
      @include tablet {
        margin: 120px 0px;
        max-width: unset;
      }
      &-main-p {
        margin-top: 50px;
      }
      &-p1 {
        margin-bottom: 16px;
      }
      span {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: $black;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        color: $black;
      }
    }
    &-mt {
      margin-top: 80px;
    }
    h1 {
      font-size: 20px;
      line-height: 24px;
      color: $black;
      font-family: 'Antonia H3';
      margin-bottom: 24px;
    }
    span {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: $black;
    }
    p:first-child {
      margin-bottom: 24px;
    }
    p:nth-child(2) {
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      color: $black;
      a{
        font-size: 16px;
        font-weight: 500;
        color: $black;
        text-decoration: none;
        border-bottom: 1px solid $green;
        transition: $transition1;
        &:hover {
          color: $green;
        }
        &:focus {
          color: $blueDark;
          border-bottom-color: $blueDark;
        }
      }
    }
  }
}
