
.lbg_pagination-container
  display: flex

  & > div + div
    margin-left: 1.25rem
  
.lbg_pagination
  select
    font-size: 14px
    font-weight: 500
    color: #0032c3
    border: 1px solid #0032c3
    border-radius: 4px
    padding: 0 0 0 4px
    height: 1.375rem
    outline: none
    background: #fff

  &::-ms-expand
    display: none

  .pagination__button
    &.next
      &::after
        margin-left: 0
        float: none

    &.prev
      margin-right: .5rem

      &::before
        margin-right: 0
        float: none