.m58
  .m58__post
    grid-template-rows: auto 1fr auto
    color: $black
    gap: 1rem 2rem

    @include lbg_tablet
      gap: .75rem 3rem

    & + .m58__post
      border-top: 1px solid #E5E7EC
      margin-top: 2rem
      padding-top: 2rem

    a
      color: $black
      transition: color .25s ease

      &:hover
        color: $green

  .m58__post__image
    padding-top: 65%
    position: relative
    grid-column: span 12

    @include lbg_mobile
      grid-column: 1 / span 4
      grid-row: 1 / span 3

    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover

  .m58__post__meta,
  .m58__post__title,
  .m58__post__description
    grid-column: span 12

    @include lbg_mobile
      grid-column: 5 / span 8

    @include lbg_desktop
      grid-column: 5 / span 7

  .m58__post__title
    font-family: 'Antonia H3'
    font-weight: 500
    line-height: 1.15em
    font-size: 1.375rem

    @include lbg_mobile
      grid-row: 1 / span 1

    @include lbg_tablet
      font-size: 1.75rem
      margin-top: .25rem

  .m58__post__description
    line-height: 1.35
    font-size: 1rem

    @include lbg_mobile
      grid-row: 2 / span 1
      
    @include lbg_tablet
      font-size: 1.0625rem
  
  .m58__post__meta
    line-height: 1.35
    display: flex
    flex-direction: column
    margin-top: 1.5rem

    @include lbg_mobile
      grid-row: 3 / span 1

    a
      text-decoration: underline

  .m58__link
    margin-top: 5rem
    display: flex
    justify-content: center