// m49 & m57 share same styling

.m57
  h2 
    margin-bottom: .25rem

    .title
      color: $blue

  p.description
    font-size: 1rem
    line-height: 1.3
    max-width: 550px


  .projects,
  .practices
    ul li:nth-child(n+4)
      display: block

    ul li:nth-child(n+6)
      display: none

  .image-group
    .group
      display: flex
      flex-direction: column
      justify-content: space-between

  .link-details
    margin: 1.5rem 0 .5rem 0
    display: inline-block
    text-decoration: underline
    transition: color .3s ease
    color: $black

    &:hover
      color: $darkGreen