footer
  // reset outdated styling behaviour
  clear: both
  font-size: 16px

  // start styling
  margin: 0 var(--offset)

  &, a,
  .fz-h3
    color: var(--primary)

  .fz-h3
    line-height: 1.25
    font-family: 'Antonia H1'

  .footer__contact
    padding: 1.75rem var(--offset)
    background: var(--blue-light)
    display: grid
    gap: 1rem

    @include lbg_tablet
      padding: var(--offset)
      gap: 2rem
      min-height: 22rem
      grid-template-columns: 2.75rem 1fr

    @include lbg_desktop
      grid-template-columns: 2.75rem 1fr auto

    img
      width: 2rem
      
      @include lbg_tablet
        width: 100%

    .contact__address
      display: flex
      flex-direction: column
      justify-content: space-between
      gap: 1.5rem

      @include lbg_desktop
        gap: 2rem

      ul
        @include lbg_tablet
          display: flex
          gap: 1.75rem
        
        a
          display: inline-block
          text-decoration: underline
          font-size: 1.25rem
          padding: .25rem 0

          @include lbg_tablet
            font-size: 1.125rem
            padding: 0

    .contact__secondary
      @include to_tablet
        border-top: 1px solid hsla(var(--primary-hsl), .1)
        margin-top: 1rem
        padding-top: 1rem
      
      @include lbg_tablet
        grid-column: 3 / span 1
        grid-row: span 1
        place-self: end
        margin-top: 0

      ul
        column-count: 2
        column-gap: 2rem
        margin-bottom: -.5rem

      li 
        padding-right: 0

        @include lbg_tablet
          padding-right: clamp(2rem, 5vw, 5rem)
        
        a
          display: inline-block
          padding: .375rem 0

          &:hover
            text-decoration: underline

  .footer__meta
    margin-bottom: 1rem
    padding: 1.5rem 0 1rem 0

    @include lbg_tablet
      display: flex
      justify-content: space-between
      align-items: center
      height: 3em

    ul,
    .meta__navigation
      display: flex
      flex-wrap: wrap
      gap: 1.5rem
      align-items: center

    .meta__navigation
      @include to_tablet
        padding: 0 var(--offset)

      a:hover
        text-decoration: underline

      .copyright
        @include mobile
          display: none

    .meta__socialmedia

      @include to_tablet
        padding: 0 var(--offset)
        border-top: 1px solid hsla(var(--primary-hsl), .2)
        padding-top: 1.5rem
        margin-top: 1.5rem

    svg 
      height: auto
      width: 1.125rem

      @include lbg_tablet
        width: 1rem
      
      path
        fill: var(--primary)
