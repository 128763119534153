.m30 {
  background-color: #f6f3f7;
  .career-box {
    position: relative;
    &:before {
      content: '';
      background-color: #000;
      opacity: 0.2;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    &:hover {
      cursor: pointer;
    }
    &-wrapper {
      @include mobile {
        display: flex;
        justify-content: center;
      }
    }
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-image: url('../images/carrer-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 35px 46px 32px 32px;
    min-height: 250px;
    margin-bottom: 24px;
    > * {
      position: relative;
      z-index: 2;
    }
    h2 {
      font-size: 28px;
      line-height: 32px;
      font-family: 'Antonia H3';
      color: white;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      color: white;
    }
  }
  &-footer {
    .m20__box__links {
      margin-bottom: 0;
    }
    &--btn {
      margin-top: 22px;
      a {
        background-color: #f6f3f7;
      }
      margin-top: -15px;
    }
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      &--btn {
        margin-top: 40px;
      }
    }

    &-center {
      justify-content: center;
      .m20__box__link {
        margin-right: 0px;
      }
    }
  }
}
.not-clicable{
  cursor: default;
  .career-box{
    &:before{
      cursor: default;
    }
    p,
    h2{
      cursor: text;
    }
  }
}