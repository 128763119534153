.m12 {
  width: 100%;
  &__box {
    width: 100%;
    
    &.is--dark {
      .m12__box__image {
        background-color: #373737;
      }
    }
    &__image {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      background-color: #F2F2F2;
      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
    &__content {
      width: 100%;
      ul {
        width: 100%;
      }
      li {
        width: 100%;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        width: 100%;
        display: inline-block;
        &:hover {
          .m12__box__name,
          .m12__box__icon {
            color: $darkGreen;
          }
        }
      }
      span {
        float: left;
        font-size: 16px;
        line-height: em(22, 16);
        color: $black;
        margin-right: 18px;
        transition: $transition1;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__name {
      font-weight: 500;
    }
    &__format {
      font-weight: normal;
      text-transform: uppercase;
    }
    &__size {
      font-weight: normal;
      text-transform: uppercase;
      color: $pinkDark !important;
    }
    &__icon {
      display: flex;
      padding-top: 4px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    margin-right: 24px;
    color: $blueDark;

    svg {
      transition: $transition1;
    }
    a {
      margin-right: 8px;
      &:hover ~ span {
        color: $green;
      }
    }
  }
}
