.m51
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0)

  .lbg-grid
    gap: 2px .75rem
    margin-top: 1.5rem
    align-items: stretch

    @include lbg_tablet
      margin-top: 2.5rem

  #panzoom
    position: relative

  #m51_image-hotspots
    & > p,
    & > h2
      max-width: 800px
    
    & > h2
      color: $blue
    
    & > p
      margin-top: .5rem
      color: $black

  .hotspots
    grid-column: span 12
    position: relative
    background: $blueLighter
    overflow: hidden
    // max-height: 24rem

    @include lbg_tablet
      grid-column: span 8
      // max-height: 90vh

    @include lbg_desktop
      grid-column: span 9

    @media screen and (min-height: 1200px)
      // max-height: 60vh

  .sidebar
    grid-column: span 12
    order: -1
    background: $blue
    padding: 0 1rem
    display: flex
    flex-direction: column
    justify-content: space-between
    max-height: 12rem
    overflow: auto

    @include lbg_tablet
      background: $blueLighter
      grid-column: span 4
      padding: 2.25rem 1.5rem 1.75rem 1.5rem
      order: 1  
      max-height: inherit

    @include lbg_desktop
      grid-column: span 3
    
    &.open 
      padding-bottom: 1rem

      .select-hotspot
        display: block
  
      .toggle-focus
        &::before
          transform: translateY(-50%) rotate(-225deg)

    .toggle-focus
      padding: 1rem 0 .75rem 0
      color: #fff
      position: sticky
      background: $blue
      top: 0

      &::before
        content: ''
        position: absolute
        top: 50%
        right: .25rem
        width: .625rem
        height: .625rem
        border-left: 2px solid #fff 
        border-bottom: 2px solid #fff 
        transform: translateY(-60%) rotate(-45deg)
  
      @include lbg_tablet
        padding: 0
        color: $black
        background: none
        position: static

        &::before
          display: none

    .btn-primary
      display: none
    
      @include lbg_tablet
        display: flex
        margin-top: 2rem
        width: 100%
    
    .select-hotspot
      padding: .75rem .5rem
      cursor: pointer
      transition: background .2s ease
      display: none
      color: #fff

      @include lbg_tablet
        margin: 1rem -.75rem 0 -.75rem
        display: block
        color: $blue

        @media (hover: hover)
          &:hover
            background: darken($blueLighter, 5%)

      &.active,
      &.active:hover
        background: #fff
        color: $blue

        @include lbg_tablet
          background: $blue
          color: #fff  

    .select-hotspot + .select-hotspot
      margin-top: 0
      border-top: 1px solid #fff

      @include lbg_tablet
        border-top: 1px solid darken($blueLighter, 10%)

  .hotspot
    position: absolute
    transform: translate(-50%, -50%)
    width: 40px
    height: 40px
    cursor: pointer

    &::after,
    &::before
      content: ''
      display: block
      position: absolute
      left: 50%
      top: 50%
      z-index: 1
      width: 12px
      height: 12px
      transform: translate(-50%, -50%)
      border-radius: 50%
      background: $blue
      transition: background .3s ease, transform .3s ease

    @media (hover: hover)
      &:hover::after
        transform: translate(-50%, -50%) scale(1.25)

      &:hover::after,
      &:hover::before
        background: $green

    &::before
      animation: m51-highlight 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite

  .hotspot_controls
    position: absolute
    bottom: .5rem
    right: .5rem
    background: #fff
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15)

    @include lbg_tablet
      bottom: 1.5rem
      right: 1.5rem
      padding: .25rem

    div
      width: 2.25rem
      height: 2.25rem
      color: #fff
      cursor: pointer
      position: relative
      transition: background .2s ease

      @include lbg_tablet
        width: 1.5rem
        height: 1.75rem

      &::after,
      &::before
        content: ''
        width: 10px
        height: 2px
        background: $blue
        position: absolute
        top: 50%
        left: 50%
        transition: background .2s ease
        transform: translate(-50%, -50%)
      
      @media (hover: hover)
        &:hover
          background: $blue

          &::after,
          &::before
            background: #fff

    .hotspots_zoom-in::after
      transform: translate(-50%, -50%) rotate(-90deg)

  #hotspot_details
    opacity: 0
    pointer-events: none
    user-select: none

    &.intro,
    &.visible
      opacity: 1
      pointer-events: all
      user-select: auto

    &.intro
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: rgba(255, 255, 255, .75)

      @supports (backdrop-filter: blur(10px))  or (-webkit-backdrop-filter: blur(10px))
        background: rgba(255, 255, 255, 0)
        backdrop-filter: blur(10px)
        -webkit-backdrop-filter: blur(10px)

      .hotspot_details_container
        max-width: 600px

    .close
      cursor: pointer
      position: absolute
      width: 3.5rem
      height: 3.5rem
      z-index: 2
      top: 0
      right: 0

      @include lbg_tablet
        width: 5rem
        height: 5rem
        top: .75rem
        right: .75rem

      &::after,
      &::before
        content: ''
        width: 1rem
        height: 2px
        background: $blue
        position: absolute
        top: 50%
        left: 50%
        transition: background .2s ease
        transform: translate(-50%, -50%) rotate(45deg)

        @include lbg_tablet
          width: 1.5rem

      &::after
        transform: translate(-50%, -50%) rotate(-45deg)

      @media (hover: hover)
        &:hover::after,
        &:hover::before
          background: $green

    #hotspot_details_title
      color: $blue
      padding: 1rem 4rem 0 1rem
      max-width: 600px
      
      @include lbg_tablet
        padding: 2.5rem 6rem 0 3rem

    #hotspot_details_desc,
    #hotspot_details_link
      color: $black
      max-width: 600px
      margin: 1rem 1rem 0 1rem

      @include lbg_tablet
        margin: 2.5rem 3rem 0 3rem

    #hotspot_details_desc
      @include lbg_tablet
        border-top: 1px solid $blueLight
        margin: 1.5rem 3rem 0 3rem
        padding-top: 1.5rem

      .hotspot_instruction
        display: flex
        align-items: center
        margin-top: .75rem

        &:first-child
          margin-top: 1.75rem
   
          @include lbg_tablet
            margin-top: .75rem

        img
          flex: 0 0 2rem
          width: 2rem
          margin-right: .5rem

    #hotspot_details_link,
    .hotspot_details_container a
      color: inherit
      display: inline-block
      text-decoration: underline
      transition: color .2s ease

      @media (hover: hover)
        &:hover
          color: $green

    .hotspot_details_container
      position: absolute
      top: 50%
      left: 50%
      width: calc(100% - 1rem)
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2)
      max-height: 70%
      overflow: auto
      padding-bottom: 2rem
      transform: translate(-50%, -50%)
      background-color: rgba(255, 255, 255, .95)

      @supports (backdrop-filter: blur(10px))  or (-webkit-backdrop-filter: blur(10px))
        background: rgba(255, 255, 255, .75)
        backdrop-filter: blur(10px)
        -webkit-backdrop-filter: blur(10px)

      @include lbg_tablet
        max-height: 50%
        width: calc(100% - 3rem)
        padding-bottom: 3rem
        box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.15)

  @keyframes m51-highlight
    0%
      transform: translate(-50%, -50%) scale(1)
    100%
      transform: translate(-50%, -50%) scale(3)
      opacity: 0
