.m67
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="number"],
  input[type="password"],
  select,
  .select,
  textarea
    width: 100%
    display: block
    font-family: inherit
    font-size: inherit
    font-weight: inherit
    color: inherit
    border: none
    outline: none
    border-radius: 0
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none
    color: var(--primary)
    transition: border .25s ease

    &:hover,
    &:focus
      box-shadow: none
      outline: none
      border-color: hsla(var(--primary-hsl), 1)

  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="number"],
  input[type="password"]
    height: 2.5rem
    font-family: 'Antonia H1'
    border-bottom: 1px solid hsla(var(--primary-hsl), .5)
    font-size: 26px

    @include mobile
      font-size: 22px

  ::placeholder
    font: inherit
    color: hsla(var(--primary-hsl), .3)

  input#m67__slider
    opacity: 0
    height: 20px

  textarea
    resize: none

  select,
  .select
    display: flex
    align-items: center
    height: 2.25rem
    border-radius: 1.125rem
    padding: 0 1rem
    background: var(--white)
    border: 1px solid hsla(var(--primary-hsl), .5)
    cursor: pointer

  select
    background-image: url('../images/arrow-form-select.svg')
    background-repeat: no-repeat
    background-position: calc(100% - 14px) calc(50% + 1px)
    padding-right: 32px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  
  .select
    justify-content: center
    
    // background-image: linear-gradient(45deg, transparent 50%, var(--primary) 50%), linear-gradient(135deg, var(--primary) 50%, transparent 50%)
    // background-size: 4px 4px, 4px 4px, 0 1.5em

  // select:focus
  //   background-image: linear-gradient(45deg, green 50%, transparent 50%), linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, red, red)
  //   background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em
  //   background-size: 5px 5px, 5px 5px, 1px 1.5em
  //   background-repeat: no-repeat
  //   border-color: green
  //   outline: 0

  select:-moz-focusring 
    color: transparent
    text-shadow: 0 0 0 #000