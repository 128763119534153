.m07o {
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 860px;

    @include tablet {
      max-width: 620px;
    }
  }

  &__content {
    width: 100%;
  }
  
  &__link{
    color: $blueDark;
    margin-top: 15px;
    display: none;
    cursor: pointer;
    svg{
      transform: rotate(180deg);
    }
    a{
      margin-right: 8px;
    }
    &.is--visible {
      display: inline
    }

    &.is--rotated{
      svg{
        transform: none;
      }

      .label--less {
        display: inline
      }

      .label--more {
        display: none
      }
    }

    .label--less {
      display: none
    }
  }
  &__lines-limit{
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    line-height: em(24,18); // 1.33333em
    
    &.is--collapsed {
      max-height: 6.83332em; //limit: number_of_lines * line-height
    }
  }
}
