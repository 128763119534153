.m39 {
  margin-top: 150px;
  @include mobile {
    margin-top: 120px;
  }
  @include tablet {
    margin-top: 120px;
  }
  .book-series {
    &-box {
      display: flex;
      border-bottom: 1px solid $pink;
      padding-bottom: 48px;
      margin-bottom: 48px;
      @include tablet {
        flex-direction: column;
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
      @include mobile {
        flex-direction: column;
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
    }
    &-thumb {
      margin-right: 43px;
      min-width: 107px;
      max-width: 107px;
      height: 126px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @include tablet {
        margin-bottom: 16px;
      }
      @include mobile {
        margin-bottom: 16px;
      }
    }
    &-content {
      h4 {
        color: $blue;
        font-size: 28px;
        line-height: 32px;
        font-family: 'Antonia H3';
        margin-bottom: 4px;
      }
      p {
        line-height: 22px;
        font-size: 16px;
        color: $black;
        margin-bottom: 16px;
      }
      @include mobile {
        h4 {
          font-size: 24px;
          font-weight: 500;
        }
        p {
          font-size: 14px;
        }
      }
      @include tablet {
        h4 {
          font-size: 24px;
          font-weight: 500;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
