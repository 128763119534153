// m03 & m047 share same styling

.m03 {
  .more-tags {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    float: left;
    color: #fff;
    background: url('../images/dots.svg') no-repeat right;
    background-size: 14%;
  }
  .popup-tags {
    display: none;
    width: 157px;
    min-height: 163px;
    float: left;
    padding: 20px;
    padding-left: 36px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(32, 48, 65, 0.21);
    z-index: 1;
    position: absolute;
    right: -157px;
    bottom: -140px;
    span {
      font-size: 16px;
      line-height: em(18, 16);
      color: #939aae;
      font-weight: 500;
      margin-bottom: 20px;
      bottom: -140px;

      &:before {
        content: '';
        float: left;
        width: 14px;
        height: 18px;
        background: url(../images/label-icon.svg) no-repeat 0 top;
        background-size: 88%;
        position: absolute;
        left: 14px;
      }
    }
    ul.tags-area {
      display: flex;
      flex-direction: column;
      position: relative !important;
      left: auto !important;
      bottom: auto !important;
      margin-top: 20px;
      li {
        float: left;
        margin-right: 0;
        margin-bottom: 10px !important;
        a {
          background: #f6f3f7;
          &:hover {
            background: #dee7ff;
          }
        }
      }
    }
  }
  .popup-tags.is--active {
    display: block;
  }
  .news-slider-mobile,
  .news-slider {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 40px;
    @include tablet-big {
      padding-top: 34px;
      padding-bottom: 0;
    }
    &-item {
      li {
        float: left;
        width: 50%;
        margin-bottom: 40px;
        &:nth-child(even) {
          clear: right;
        }
        &:nth-child(odd) {
          clear: left;
        }
        @include tablet-big {
          margin-bottom: 80px;
        }
        @include mobile {
          width: 100%;
          &:last-child {
            margin-bottom: 0;
          }
        }
        @include tablet {
          margin-bottom: 30px;
        }
      }
      .box {
        display: flex;
        @include tablet {
          flex-direction: column;
        }
        &:hover {
          .box-thumb img {
            opacity: 0.9;
          }
        }
        &-thumb {
          position: relative;
          min-width: 190px;
          max-width: 190px;
          height: 141px;
          @include tablet-big {
            min-width: 220px;
            max-width: 220px;
            height: 164px;
          }
          @include tablet {
            min-width: 208px;
            max-width: 208px;
            height: 158px;
          }
          @include mobile {
            min-width: 100px;
            max-width: 100px;
            height: 76px;
          }
          img {
            @extend %imgCropped;
            transition: $transition1;
          }
          .tags-area {
            position: absolute;
            bottom: 12px;
            left: 8px;
            @include mobile {
              display: none !important;
            }
            li {
              margin-bottom: 0;
              clear: none;
              width: auto;
              @include mobile {
                min-height: 1px;
              }
            }
          }
        }
        &-dsc {
          padding: 0 26px;
          color: #001141;
          position: relative;
          line-height: 1.25;
          
          @include desktop{
            width: calc(100% - 190px);
          }
          @include tablet {
            padding-left: 0;
            margin-top: 15px;
          }
          @include mobile {
            padding: 0 0 0 10px;
          }
          h3 {
            font-size: 20px;
            line-height: em(24, 20);
            color: #001141;
            font-family: 'Antonia H3';
            font-weight: 500;
            word-break: break-word;
            transition: color .25s ease;
            margin-bottom: .25rem;

            @include tablet-big {
              font-size: 18px;
            }
            @include tablet {
              font-size: 18px;
            }
            @include mobile {
              font-size: 16px;
            }

            @media (hover: hover) {
              &:hover {
                color: $green
              }
            }
          }
          &-bottom {
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            @include tablet {
              position: relative;
              bottom: auto;
              margin-top: 8px;
            }
            @include mobile {
              margin-top: 8px;
              bottom: -42px;
              position: static;
            }

            h5 {
              font-weight: normal;
              color: #001141;
              font-weight: normal;
              font-size: 14px;
              line-height: em(20, 14);
            }
            span {
              font-size: 14px;
              color: #001141;
              line-height: em(20, 14);
              font-weight: normal;
              font-family: 'Whitney';
              font-size: 14px;
              line-height: 1.42857em;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
  @include mobile {
    .news-slider-mobile {
      float: left;
      width: 100%;
    }
  }
}

body.home {
  .hidden-home-page{
    display: none;
  }
}