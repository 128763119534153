.m14 {
  width: 100%;
  
  .section-title-small {
    margin-bottom: 42px;
    @include tablet {
      margin-bottom: 40px;
    }
    @include mobile {
      margin-bottom: 24px;
    }
  }
  .slick-track {
    margin-right: -12px;
    margin-left: -540px;
    @include mobile {
      margin-left: -12px;
    }
    @include tablet {
      margin-left: 28px;
      margin-right: 28px;
    }
    @include tablet-big {
      margin-left: -388px;
    }
  }
  .slick-counter-publication,
  .slick-counter-news {
    top: -78px;
    @include desktop {
      right: calc(100vw / 2 - 590px);
    }
    @include tablet-big {
      display: none;
    }
    @include tablet {
      display: none;
    }
  }
  .slick-arrows-top .slick-prev {
    top: -86px;
    @include desktop {
      right: calc(100vw / 2 - 556px);
    }
    @include tablet-big {
      right: calc(100vw / 2 - 440px);
    }
    @include tablet {
      right: calc(100vw / 2 - 300px);
    }
  }
  .slick-arrows-top .slick-next {
    top: -68px;
    @include desktop {
      right: calc(100vw / 2 - 640px);
    }
    @include tablet-big {
      right: calc(100vw / 2 - 490px);
    }
    @include tablet {
      right: calc(100vw / 2 - 346px);
    }
  }
}
.pressphoto_slider {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  z-index: 3;
  @include mobile {
    padding: 0 24px;
  }
  &__item {
    width: 216px;
    padding-right: 12px;
    padding-left: 12px;
  }
  &__thumb {
    width: 100%;
    height: 124px;
    margin-bottom: 8px;
    border: 1px solid #979797;
    img {
      @extend %imgCropped;
    }
  }
  a {
    &:hover {
      .pressphoto_slider__content {
        color: $darkGreen;
      }
    }
  }
  &__content {
    color: $black;
    h4 {
      font-size: 16px;
      line-height: em(22, 16);
      font-weight: 500;
      transition: $transition1;
    }
    svg {
      transition: $transition1;
    }
    &-top {
      display: flex;
      justify-content: space-between;
    }
    &-bottom {
      display: flex;
      align-items: center;
      span {
        display: flex;
        font-size: 16px;
        line-height: em(22, 16);
        color: $black;
        margin-right: 8px;
        text-transform: uppercase;
        transition: $transition1;
        &:last-child {
          opacity: 0.5;
        }
      }
    }
  }
  &__icon {
    margin-top: 5px;
    color: inherit;
  }
}
