.m21 {
  .news-slider1 {
    float: left;
    width: 100%;
    border-bottom: 1px solid #b8a9bc;
    padding-bottom: 40px;
  }
  .center-element {
    margin-top: 40px;
  }
  .more-tags {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    float: left;
    color: #fff;
    background: url('../images/dots.svg') no-repeat right;
    background-size: 14%;
  }
  .popup-tags {
    display: none;
    width: 157px;
    min-height: 163px;
    float: left;
    padding: 20px;
    padding-left: 36px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(32, 48, 65, 0.21);
    z-index: 1;
    position: absolute;
    right: -157px;
    bottom: -140px;
    span {
      font-size: 16px;
      line-height: em(18, 16);
      color: #939aae;
      font-weight: 500;
      margin-bottom: 20px;
      bottom: -140px;

      &:before {
        content: '';
        float: left;
        width: 14px;
        height: 18px;
        background: url(../images/label-icon.svg) no-repeat 0 top;
        background-size: 88%;
        position: absolute;
        left: 14px;
      }
    }
    ul.tags-area {
      display: flex;
      flex-direction: column;
      position: relative !important;
      left: auto !important;
      bottom: auto !important;
      margin-top: 20px;
      li {
        float: left;
        margin-right: 0;
        margin-bottom: 10px !important;
        a {
          background: #f6f3f7;
          &:hover {
            background: #dee7ff;
          }
        }
      }
    }
  }
  .popup-tags.is--active {
    display: block;
  }

  .news-slider {
    &-item {
      > ul > li{
        @include desktop {
          width: calc(50% - 20px);
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      li {
        float: left;
        width: 50%;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #b8a9bc;
        &:last-child {
          border-bottom: 0;
        }

        @include tablet {
          width: 100%;
          margin-bottom: 32px;
          padding-bottom: 32px;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
        @include mobile {
          width: 100%;
          min-height: 110px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .box {
        display: flex;
        @include tablet {
          flex-direction: row;
        }
        &:hover {
          .box-thumb img {
            opacity: 0.9;
          }
        }
        &-thumb {
          position: relative;
          min-width: 190px;
          max-width: 190px;
          height: 141px;
          @include tablet-big {
            min-width: 220px;
            max-width: 220px;
            height: 164px;
          }
          @include tablet {
            min-width: 95px;
            max-width: 95px;
            height: 70px;
          }
          @include mobile {
            min-width: 100px;
            max-width: 100px;
            height: 76px;
          }
          img {
            @extend %imgCropped;
            transition: $transition1;
          }
          .tags-area {
            position: absolute;
            bottom: 8px;
            left: 8px;
            @include mobile {
              display: none !important;
            }
            @include tablet {
              display: none;
            }
            li {
              margin-bottom: 0;
              clear: none;
              width: auto;
              border: 0;
              padding-bottom: 0;
              a {
                padding: 3px 8px;
              }
              @include mobile {
                min-height: 1px;
              }
            }
          }
        }
        &-dsc {
          padding: 0 26px;
          line-height: 1.25;
          color: #001141;
          position: relative;
          @include tablet {
            display: flex;
            align-items: flex-start;
            padding-right: 0;
            padding-left: 24px;
          }
          @include mobile {
            padding: 0 0 0 10px;
          }
          > a {
            @include tablet {
              width: calc(100% - 210px);
              padding-right: 24px;
            }
          }
          h3 {
            font-size: 20px;
            line-height: em(24, 20);
            color: #001141;
            font-family: 'Antonia H3';
            font-weight: 500;
            word-break: break-word;
            margin-bottom: .25rem;

            @include mobile {
              font-size: 16px;
            }
          }
          &-bottom {
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            @include tablet {
              position: relative;
              bottom: unset;
              width: 210px;
            }
            @include mobile {
              position: static;
              margin-top: 8px;
            }

            h5 {
              font-weight: normal;
              color: #001141;
              font-weight: normal;
              font-size: 14px;
              line-height: em(20, 14);
            }
            span {
              font-size: 14px;
              color: #001141;
              line-height: em(20, 14);
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  li:nth-child(4) {
    float: right !important;
  }
  .first-item {
    padding-right: 130px;
    border-bottom: 0 !important;
    @include tablet {
      width: 100% !important;
      padding-right: 0;
    }
    @include mobile {
      width: 100% !important;
      padding-right: 0;
    }

    .box {
      display: block;
      @include tablet {
        display: flex;
        flex-direction: row;
      }
      .box-thumb {
        height: 344px;
        max-width: 100%;
        @include tablet {
          min-width: 330px;
          height: 220px;
        }
        @include mobile {
          height: 140px;
          max-width: 210px;
        }
        .tags-area {
          @include tablet {
            display: block;
          }
        }
      }
    }
    .box-dsc {
      padding: 0;
      padding-top: 20px;
      @include tablet {
        flex-direction: column;
        padding-top: 0;
        margin-top: 0;
        padding-left: 24px;
      }
      @include mobile {
        padding-top: 16px;
      }
      > a {
        @include tablet {
          width: 100%;
          padding-right: 0;
        }
      }
      h3 {
        font-size: 28px;
        @include tablet {
          font-size: 28px;
          line-height: em(32, 28);
        }
        @include mobile {
          font-size: 24px;
          line-height: em(28, 24);
        }
      }
      &-bottom {
        position: relative;
        top: auto;
        bottom: auto;
        @include tablet {
          margin-top: 16px;
        }
      }
    }
  }
}
