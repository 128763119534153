@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-Bold.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-Black.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-BlackItalic.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-Light.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-BookItalic.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-BoldItalic.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-Book.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-Medium.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-SemiboldItalic.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-Semibold.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-MediumItalic.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  src: url('../fonts/whitney/Whitney-LightItalic.woff2') format('woff2'),
    url('../fonts/whitney/Whitney-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

// ANTONIA FONTS
@font-face {
  font-family: 'Antonia H1';
  src: url('../fonts/antonia/AntoniaH1-Regular.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH1-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antonia H2';
  src: url('../fonts/antonia/AntoniaH2-Regular.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH2-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antonia H2';
  src: url('../fonts/antonia/AntoniaH2-Light.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH2-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Antonia H2';
  src: url('../fonts/antonia/AntoniaH2-RegularItalic.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH2-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Antonia H2';
  src: url('../fonts/antonia/AntoniaH2-medium.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH2-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antonia H3';
  src: url('../fonts/antonia/AntoniaH3-Regular.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH3-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Antonia H3';
  src: url('../fonts/antonia/AntoniaH3-medium.woff2') format('woff2'),
    url('../fonts/antonia/AntoniaH3-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
