.m07 {
  &__leading {
    font-size: 24px;
    line-height: em(32,24);
    color: $black;
    font-weight: 400;
    margin-bottom: 48px;
    a{
      font-size: inherit!important;
      font-weight: 500!important;
      color: $black!important;
      text-decoration: none!important;
      border-bottom: 1px solid $green!important;
      transition: $transition1!important;
      &::after{
        display: none!important;
      }
      &:hover {
        color: $green!important;
      }
      &:focus {
        color: $blueDark!important;
        border-bottom-color: $blueDark!important;
      }
    }
  }

  .m07_images {
    margin-top: 3rem;
    grid-column: span 12;

    @include lbg_tablet {
      grid-column: span 8;
    }
    
    a {
      display: block;
      color: $black;
    }

    span {
      display: block;
      margin-top: .375rem
    }

    a + a {
      margin-top: 2rem;
    }
  }

  .lbg-grid {
    & > div {
      grid-column: span 12;

      @include lbg_tablet {
        grid-column: span 8;
      }
    }

    @include lbg_tablet {
      &.multicolumn > div:first-child {
        grid-column: span 7;
      }
      &.multicolumn > div:last-child {
        grid-column: 9 / span 4;
      }
    }
  }

  .single-header {
    margin-bottom: 2rem;
    
    @include lbg_tablet {
      margin-bottom: 3rem;
    }
    
    .date-area {
      color: #001141;
      font-size: 12px;
      line-height: 1.33333em;
      font-family: 'Whitney';
    }
    label {
      border-bottom: 1px solid #008576;
    }
    h3 {
      max-width: 840px;
    }
    .tags-area {
      margin-top: 0;
      overflow: auto;

      @include lbg_tablet {
        margin-right: 1rem;
      }

      &:empty {
        display: none
      }
    }
    .headerWrap__socail {
      margin-left: 0;

      span {
        display: flex;
        align-items: flex-end;
        padding-left: 30px;
        color: #001141;
        font-size: 16px;
        line-height: 1.33333em;
        font-weight: 500;
        font-family: 'Whitney';
        margin-right: 5px;
        height: 18px;
      }
    }
  }
  .top-content {
    .top-content--right + .top-content--right {
      margin-top: $gutter*2;

      @include tablet {
        margin-top: $gutter*3;
      }

      @include mobile {
        margin-top: $gutter*3;
      }
    }

    &-title {
      font-family: 'Antonia H3';
      font-size: 28px;
      font-weight: 500;
      line-height: em(32, 28);
      color: $blue;

      @include tablet {
        margin-top: 64px;
      }
    }
    
    a:not(.btn-primary):not(.lightbox-a-tag):not(.extra-links-a-tag):not(.extra-links-a-tag):not(.sidebar-link) {
      font-size: 18px;
      font-weight: 500;
      color: $blue;
      text-decoration: none;
      transition: $transition1;
      word-break: break-word;

      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 3px 0 4px;
        transform: translateY(-1px);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid $blue;
        transition: $transition1;
      }
      &:hover {
        color: $green;
        &:after {
          border-left-color: $green;
        }
      }
      &:focus {
        color: $blueDark;
        &:after {
          border-left-color: $blueDark;
        }
      }
    }

    .fb_iframe_widget {
      float: left;
      width: 100%;
      margin-bottom: 35px;

      @include mobile {
        width: 100% !important;
        max-width: 100% !important;
      }

      @include tablet {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    

    .btn-primary {
      float: left;
      margin-top: 8px;
      justify-content: space-between;
    }

    &--left {
      max-width: 860px;
      // float: left;
      // width: 50%;
      // margin-bottom: 75px;
      // @include desktop {
      //   padding-right: 58px;
      // }
      @include tablet-big {
        width: 100%;
      }
      @include tablet {
        width: 100%;
        margin-bottom: 64px;
      }
      @include mobile {
        width: 100%;
        margin-bottom: 0;
      }
      // p:nth-child(2):not(.m07__leading) {
      //   // max-width: 623px;
      // }
      p:nth-child(3):not(.m07__leading) {
        // padding-left: 108px;
        @include tablet {
          // padding-left: 47px;
        }
        @include mobile {
          padding-left: 0;
        }
      }

      .btn-primary {
        margin-top: 32px
      }
    }

    &--right {
      display: grid;
      grid-template-columns: 1fr;
      gap: $gutter;

      @include tablet-big {
        max-width: 451px;
      }

      @include tablet {
        max-width: 451px;
      }

      &__facts {
        display: grid;
        grid-template-columns: 1fr;
        gap: $gutter*2;
        margin-bottom: $gutter;
      }

      &__audio,
      &__video {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-bottom: $gutter;

        p {
          color: $black;
          line-height: 1.2;
        }

        audio,
        video {
          width: 100%;
        }
      }
      
      &__thumb {
        width: 100%;
        height: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 2fr);

        a {
          display: block;
          position: relative;
          padding-top: 67%;
        }

        img {
          @extend %imgCropped;
          position: absolute;
          top: 0; 
          left: 0;
          object-fit: contain;
          font-family: "object-fit: contain;";
        }
      }
      &__single {
        display: block;

        a {
          position: relative;
          display: block;
        
          span {
            display: block;
            margin-top: .25rem;
            color: $black
          }
        }

        a + a {
          margin-top: 1.5rem
        }

        img {
          display: block;
        }

      }
      .photo-credit {
        color: $black;
        font-size: 13px;
        line-height: 17px;
      }
    }
  }

  .top-content-different {
    p {
      margin-bottom: 20px;
    }
  }

  .top-content-different-2 {
    @include tablet-big {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }

    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .top-content--right {
        margin-right: 65px;
      }
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .top-content--right {
        margin-right: 65px;
      }
    }
  }

  .dsc > h3,
  .extra-links-area > h3,
  .book-detail-p-header > h3 {
    font-family: 'Antonia H3';
    font-size: 28px;
    font-weight: 500;
    line-height: em(32, 28);
    color: $blue;
  }

  .book-detail-p-header > h3 {
    margin: .5rem 0
  }
  
  .stats-list {
    h4 {
      color: $black;
      font-size: 18px;
      line-height: em(24, 18);
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &:last-child ul {
      margin-bottom: 0;
    }

    ul {
      li {
        border-bottom: 1px solid $pink;
        color: $black;
        font-size: 18px;
        padding: 8px 0 13px 0;
        line-height: em(24, 18);
        font-weight: normal;
        display: flex;
        margin-bottom: 0;
        justify-content: space-between;
        &:before {
          display: none;
        }
      }
    }
  }
  .extra-links-area {
    padding: 30px 42px;
    background: #f1f5ff;
    h3 {
      color: $black;
      font-size: 22px;
      line-height: em(26, 22);
      font-family: 'Antonia H3';
      font-weight: 500;
      margin-bottom: 18px;
    }

    .download-links {
      a {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid #b8a9bc;
        &:last-child {
          border-bottom: 0;
        }

        p:first-child, 
        span.file-type,
        span.file-size,
        svg path,
        svg polygon {
          transition: $transition1;
        }
        
        &:hover {
          p:first-child,
          span.file-type,
          span.file-size {
            color: $darkGreen
          }

          svg path,
          svg polygon {
            fill: $darkGreen
          }
        }

        p {
          color: $black;
          font-size: 16px;
          line-height: em(18, 16);
          font-weight: 600;
          margin-bottom: 0;
          &:first-child {
            width: 64%;
          }
        }
        .file-type {
          color: $black;
          font-size: 16px;
          text-transform: uppercase;
          min-width: 43px;
          text-align: left;
          display: inline-block;
          margin-right: 12px;
          font-weight: 500;
        }
        .file-size {
          color: #7e6e7e;
          font-size: 16px;
          line-height: em(22, 16);
          font-weight: 500;
          margin-right: 12px;
        }
      }
    }
  }
  ul.extra-links {
    li {
      margin-bottom: 16px;
      a {
        color: #0032c3;
        font-size: 18px;
        line-height: em(21, 18);
        font-weight: normal;
        position: relative;
        padding-left: 25px;
        display: flex;

        &:before {
          content: '';
          float: left;
          width: 10px;
          height: 10px;
          margin-top: 5px;
          background: url(../images/arrow-list.svg) no-repeat 0 0;
          background-size: 100%;
          transform: rotate(-90deg);
          margin-right: 14px;
          position: absolute;
          transition: $transition1;
          left: 0;
        }
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .author-area {
    max-width: 838px;
    &-content {
      display: flex;
      &-left {
        flex: 1;
        .thumb {
          float: left;
          min-width: 84px;
          width: 84px;
          height: 84px;
          border-radius: 50%;
          margin-right: 23px;

          img {
            border-radius: 50%;
            overflow: hidden;
            @extend %imgCropped;
          }
        }
        .dsc {
          padding-top: 16px;
          h3 {
            color: $black;
            font-size: 16px;
            line-height: em(18, 16);
            font-weight: 600;
          }
          p {
            color: #7e6e7e;
            font-size: 16px;
            line-height: em(24, 16);
            font-weight: 500;
            margin-top: 10px;
          }
        }
      }
      &-right {
        flex: 1;
        padding-left: 50px;
        h4 {
          font-family: 'Antonia H3';
          font-size: 20px;
          font-weight: 500;
          line-height: em(24, 20);
          color: $black;
          margin-bottom: 21px;
        }
      }
    }
  }
  .different-single-layout {
    margin-bottom: 2.5rem;

    .full-block {
      font-size: 16px;
      line-height: 1.3;
    }

    .specific_times_location + p {
      margin-top: .75rem
    }

    .full-block + .full-block {
      margin-top: .75rem
    }

    span {
      font-weight: 600;
    }

    p {
      margin: 0;
    }
  }
  .social__icons a {
    color: #fff;
    svg {
      width: 14px;
    }
  }
}
.tab-mb-100{
  @include tablet {
    margin-bottom: 100px;
  }
  @include tablet-big {
    margin-bottom: 100px;
  }
}

.single-php:not(.single-bik-biography, .single-team) {
  & > .container {
    padding: 0;
  }
}