.m67
  // reset outdated styling behaviour
  clear: both
  font-size: 16px
  
  // start styling
  height: calc(100vh - 74px)

  @include lbg_tablet
    display: grid
    gap: .5rem
    margin-top: 0 !important
    margin-bottom: var(--offset)
    grid-template-columns: clamp(300px, 35vw, 600px) 1fr
    height: calc( 100vh - 84px - var(--offset) )

  @include lbg_desktop
    height: calc( 100vh - 90px - var(--offset) )

.m67_map,
.m67_filter
  background: var(--blue-light)

.m67_list
  height: 100%
  overflow: auto
  background: linear-gradient(30deg, hsla(var(--primary-hsl), .05) 0%, hsla(var(--primary-hsl), 0) 100%)

.m67_filter
  position: sticky
  top: 0
  z-index: 2

.m67_toggle_container
  position: sticky
  bottom: 0
  left: 0
  right: 0
  height: 6rem
  z-index: 3
  display: grid
  place-items: center

  .m67_toggle
    background: var(--primary)
    width: 140px
    height: 52px
    border-radius: 26px
    transition: width .3s $easeInOutQuart
    overflow: hidden

    &.close
      width: 52px

      .m67_toggle_label
        opacity: 0
        transition-delay: 0s

      .m67_toggle_close
        opacity: 1
        transition-delay: .2s

    .m67_toggle_label,
    .m67_toggle_close
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      transition: opacity .15s ease
      pointer-events: none
      user-select: none
      
    .m67_toggle_label
      display: flex
      align-items: center
      gap: .5rem
      color: var(--white)
      text-transform: uppercase
      letter-spacing: .01em
      font-size: 14px
      transition-delay: .2s

    .m67_toggle_close
      display: grid
      place-items: center
      width: 52px
      aspect-ratio: 1/1
      opacity: 0

      &::after,
      &::before
        content: ''
        grid-area: 1/1
        width: 1rem
        height: 1px
        background: #fff
        transform: rotate(45deg)

      &::before
        transform: rotate(-45deg)

  @include lbg_tablet
    display: none

.m67_map
  @include to_tablet
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 2
    overflow: hidden
    opacity: 0
    pointer-events: none
    user-select: none

  &.visible
    opacity: 1
    pointer-events: all
    user-select: all

  .leaflet-popup
    margin-bottom: 14px

  .leaflet-popup-content-wrapper
    border-radius: 4px

  .leaflet-popup-content
    margin: .375rem .75rem
    width: auto !important
    white-space: nowrap

  .leaflet-popup-tip
    width: 10px
    height: 10px
    margin: -5px auto 0

.m67_empty
  padding: $gutter*2 $gutter
  text-align: center
  display: none
  
  &.visible
    display: grid

  div
    font-family: 'Antonia H1'
    color: var(--primary)

  span
    display: block
    cursor: pointer
    margin-top: .5rem
    text-decoration: underline
    color: var(--primary)

