.m65
  // reset outdated styling behaviour
  clear: both
  font-size: 16px

  // start styling
  overflow: hidden

  .swiper
    @include mobile
      margin: 0 1rem

  .swiper-slide
    background: var(--blue-light)
    padding: $gutter*1.5
    display: flex
    flex-direction: column
    justify-content: space-between
    transition: background .3s ease
    aspect-ratio: 1 / 1.15
    max-height: 500px

    @include lbg_mobile
      padding: $gutter*1.5 $gutter
      width: calc( 100% / 2 - 4px)
      aspect-ratio: 1 / 1.5
      margin: 0
   
    @include lbg_tablet
      width: calc( 100% / 3 - 4px)
      max-height: initial
  
    @include lbg_desktop
      width: calc( 100% / 4 - 4px)
      aspect-ratio: 1 / 2
      padding: clamp(1.5rem, 5vh, 5rem) $gutter

    @media (min-width: 1600px)
      width: calc( 100% / 5 - 6px)

    @media (min-width: 2000px)
      width: calc( 100% / 6 - 6px)
    
    @media (hover: hover)
      &:hover
        background: var(--primary)

        & > *,
        .slide__headline
          color: var(--white)

        .slide__arrow
          &::before
            background: var(--white)

          &::after
            border-color: var(--white)

        .slide__image
          opacity: .1

    & > *,
    .slide__headline
      color: var(--primary)
      transition: color .3s ease
      text-align: center
      user-select: none

    .slide__headline
      font-family: 'Antonia H1'

    .slide__header
      display: flex
      flex-direction: column
      align-items: center

    .slide__headline
      text-wrap: balance
    
    .slide__subline
      margin-top: 1rem
      opacity: .5

    .slide__arrow
      margin-top: 2rem
      width: 3.75rem

      @include to_tablet
        display: none

    .slide__image
      display: none

      @media (hover: hover)
        display: block
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        pointer-events: none
        user-select: none
        opacity: 0

    .slide__date
      display: flex
      flex-direction: column
      gap: .75rem

      strong
        font-family: 'Antonia H2'
        font-weight: 300
        font-size: 4rem

        @include lbg_tablet
          font-size: 5rem

        @include lbg_desktop
          font-size: 6rem

      span
        letter-spacing: .05em
        text-transform: uppercase
        font-size: .75rem

        @include lbg_tablet
          font-size: .875rem