.big_accordions {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  &__item {
    width: 100%;
    padding: 65px;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 6px;
    background: #f1f5ff;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover {
      .big_accordions {
        &__title,
        &__count {
          color: $darkGreen;
        }
        &__toggle {
          background: url('../images/icon-plus-big-light-green.svg') no-repeat
            center;
        }
      }
    }
    &--toggled {
      .big_accordions {
        &__toggle {
          background: url('../images/icon-minus-big-light-blue.svg') no-repeat
            center;
        }
        &__other_content {
          max-height: 9999px;
        }
      }
      &:hover {
        .big_accordions {
          &__toggle {
            background: url('../images/icon-minus-big-light-green.svg') no-repeat
              center;
          }
        }
      }
    }
    @include tablet {
      padding: 40px;
    }
    @include mobile {
      padding: 15px;
      flex-direction: column;
    }
  }
  &__count {
    color: $blue;
    font-size: 56px;
    font-weight: 400;
    margin-right: 96px;
    line-height: 1.071em;
    transition: $transition1;
    font-family: 'Antonia H1';
    @include tablet {
      margin-right: 40px;
    }
    @include mobile {
      font-size: 40px;
      margin-bottom: 12px;
    }
  }
  &__content {
    width: 65%;

    @include tablet {
      width: 75%;
    }
    @include tablet-big {
      width: 70%;
    }
    @include mobile {
      width: 100%;
    }
    .btn-primary {
      min-width: auto;

      &__icon {
        margin-left: 1.25rem;
      }
    }
  }
  &__other_content {
    max-height: 0;
    overflow: hidden;
    transition: $transition1;

    .wysiwyg {
      padding-top: 24px;
    }
  }
  &__title {
    color: $blue;
    font-size: 28px;
    font-weight: 500;
    padding-right: 16%;
    margin-bottom: 20px;
    line-height: 1.143em;
    transition: $transition1;
    font-family: 'Antonia H3';
    @include mobile {
      font-size: 24px;
      padding-right: 0;
    }
  }
  &__toggle {
    width: 32px;
    height: 32px;
    margin-left: auto;
    background: url('../images/icon-plus-big-light-blue.svg') no-repeat center;
    @include mobile {
      top: 20px;
      right: 15px;
      width: 24px;
      height: 24px;
      position: absolute;
    }
  }
  p {
    color: $black;
    font-size: 18px;
    line-height: 1.333em;
    @include mobile {
      font-size: 16px;
    }
  }
}
.m20 {
  width: 100%;
  margin-bottom: $d-vs;
  @include tablet {
    margin-bottom: 40px;
  }
  @include mobile {
    margin-bottom: 40px;
  }
  &__box {
    width: 100%;
    padding-right: 100px;
    .section-title-small {
      padding: 0;
      line-height: 1.7rem;
    }
    @include tablet {
      padding-right: 0;
      margin-bottom: 80px;
    }
    @include mobile {
      padding-right: 0;
      margin-bottom: 60px;
    }
    &_inner {
      padding-right: 110px;
      @include mobile {
        padding-right: 0;
      }
    }
    &__image {
      width: 410px;
      max-width: 100%;
      height: 224px;
      padding-right: 110px;
      margin-bottom: 24px;
      @include tablet {
        width: 300px;
        padding-right: 0;
      }
      @include mobile {
        width: 100%;
        padding-right: 0;
      }
      svg,
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      h4 {
        font-family: 'Antonia H3';
        font-size: 20px;
        font-weight: 500;
        line-height: em(24, 20);
        color: $black;
        margin-bottom: 16px;
      }
      p {
        font-size: 16px;
        line-height: em(22, 16);
        color: $black;
        margin-bottom: 16px;
        @include tablet {
          width: 407px;
        }
      }
    }
    &__links {
      display: flex;
      margin-bottom: 40px;
      @include mobile {
        margin-bottom: 32px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      margin-right: 24px;
      color: $blueDark;

      svg {
        transition: $transition1;
      }
      a {
        margin-right: 8px;
        &:hover ~ span {
          color: $green;
        }
      }
    }
  }
}
