.lbg-arrow
  width: 2rem
  height: .5rem
  display: grid
  align-items: center
  justify-items: end
  overflow: hidden
  transition: transform .2s $easeInOutQuart

  &::after,
  &::before
    content: ''
    grid-area: 1/1
    transition: transform .2s $easeInOutQuart

  &::before
    width: 100%
    height: 1px
    background: var(--primary)
    transform-origin: 100% 50%

  &::after
    height: 100%
    aspect-ratio: 1/1
    border-right: 1px solid var(--primary)
    border-bottom: 1px solid var(--primary)
    transform-origin: 100% 100%
    transform: translateY(-50%) rotate(-45deg) 

