.m59
  -webkit-tap-highlight-color: transparent

  .lbg-grid
    @include lbg_tablet
      gap: 2rem 

  .searchbox-container
    position: relative
    margin-top: 1rem

    #stats
      color: $black75
      pointer-events: none
      width: 100%
      text-align: center
      margin-top: .75rem

      @include lbg_tablet
        position: absolute
        top: 50%
        right: 1.5rem
        margin-top: 0
        width: auto
        z-index: 1
        transform: translateY(-50%)

  input[type="search"]
    width: 100%
    height: 4rem
    padding: 0 1.5rem
    font-size: 1.5rem
    background-color: #fff
    border-radius: 3px
    outline: none

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration
      display: none

  &[data-variation="module"]
    .search_type
      border-bottom: 1px solid $black75

    .ais-Menu-container
      margin-top: 2rem

    .ais-Menu-list 
      a
        background: $blueLighter
        opacity: 1
        color: $black

        &:hover
          background: darken( $blueLighter, 5% )

      .ais-Menu-item--selected a
        background: $black
        color: #fff

    input[type="search"]
      background-color: #fff
      border: 1px solid $black75
      color: darken($black, 30%)

    ::placeholder
      font-size: 1.5rem
      color: $black50

  &[data-sidebar="false"]
    .search__hits
      @include lbg_tablet
        padding: 2rem 0
        grid-column: span 12

    .search__sidebar
      display: none

  &[data-variation="default"]
    &[data-post-types="false"]
      .search__head
        padding-bottom: 1.5rem
  
    input[type="search"]
      box-shadow: 0 4px 20px #b8a9bc
      border: 0
      color: darken($black75, 30%)

    ::placeholder
      font-size: 1.5rem
      color: $black75

    .search__head
      padding: 2rem 0 0 0
      background: #E4DEE5

      @include lbg_tablet
        background: url('../images/search-bg.svg') no-repeat
        background-size: cover
        padding: 4rem 0 0 0
  
  .search__head
    h2
      font-family: 'Antonia H1'
      color: $black
      font-size: clamp( 32px, 7vw, 56px)
      line-height: 1.1
      text-align: center

      @include lbg_tablet
        text-align: left

    .container
      display: flex
      flex-direction: column
      justify-content: space-between

      &::after,
      &::before
        display: none

  .search__footer
    border-top: 1px solid $black75
    padding-top: 2rem
    display: flex
    justify-content: center

  .search__main
    margin-bottom: 2rem

  .search__hits
    grid-column: span 12
    padding: 0 0 2rem 0

    @include lbg_tablet
      order: -1
      padding: 2rem 0
      grid-column: 1 / span 9

  .search__meta
    display: flex
    align-items: center
    justify-content: space-between
    padding: 1.5rem 0 .5rem 0
    color: $black

    @include lbg_tablet
      padding: 1.5rem 0
      border-bottom: 1px solid $black75
      justify-content: space-between

    #hits-per-page
      display: none

      @include lbg_tablet
        display: block

  .search__sidebar
    color: $black
    grid-column: span 12
    user-select: none

    @include lbg_tablet
      padding: 2rem
      padding-right: 0
      align-self: stretch
      border-left: 1px solid $black75
      grid-column: 10 / span 3

  .search__filter__toggle
    text-align: center
    padding: 1rem
    font-size: 1rem
    background: $blueLighter

    @include lbg_tablet
      display: none

    span:last-child
      display: none

    &.open 
      span:first-child
        display: none

      span:last-child
        display: block

      & + .search__filter__group
        display: block

  .search__filter__group
    display: none
    padding: 1rem
    background: $blueLighter

    @include lbg_tablet
      padding: 0
      background: none
      display: block

  .search__filter
    border-top: 1px solid rgba($black, .075)
    display: none

    &.visible
      display: block

    @include lbg_tablet
      border-top: 1px solid #efefef

    .arrow
      height: .5rem
      width: .5rem
      border-right: 1px solid $black
      border-bottom: 1px solid $black
      transform: translateY(-.25rem) rotate(45deg)
      transition: border-color .25s ease

    .search__filter__label
      cursor: pointer
      display: flex
      align-items: center
      justify-content: space-between
      padding: 1rem .5rem

      .fz-h4
        transition: color .25s ease

      &.open 
        .arrow
          transform: translateY(0) rotate(225deg)

        & + .search__filter__list
          display: block

    .search__filter__list
      display: none
      margin-bottom: 2rem