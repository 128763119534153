.lbg__linked-posts
  max-width: 838px
  font-size: 1rem
  color: $black

  .linked-posts_groups
    display: grid
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) )
    gap: $gutter*1.5

  h3
    margin-bottom: 1.5rem

  h4
    margin-bottom: .5rem
    font-weight: 600

  ul
    li + li
      border-top: 1px solid $pinkLight
    
    a
      display: block
      color: inherit
      transition: color .2s ease
      padding: .5rem 0
      line-height: 1.25

      &:hover
        color: $green