.sidebar
  color: $blue
  order: -1
  grid-column: span 12
  position: relative

  @include lbg_tablet
    order: 1
    grid-column: 9 / span 4

  @include lbg_desktop
    grid-column: 9 / span 3

  &.loading
    pointer-events: none
    user-select: none

    .sidebar_filter > *
      opacity: 0
    
    &::before
      content:''
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 1rem
      height: 1rem
      border-radius: 50%
      background: $blue
      animation: sidebar-loading 1.125s cubic-bezier(0.165, 0.84, 0.44, 1) infinite

  .search 
    position: relative
    
    svg 
      position: absolute
      bottom: .5rem
      left: .5rem
      z-index: 1
      transform: scaleX(-1)

      path
        fill: $blue

  .toggleAll,
  .reset.btn-primary--outline
    margin-top: 3rem
    justify-content: center
    padding-left: 1rem
    padding-right: 1rem
    cursor: pointer

    &:hover
      color: $green
      border-color: $green

  // forms
  input, 
  select
    border-radius: 3px
    background: #FFFFFF
    color: $blue
    border: none
    box-shadow: none
    padding: .5rem 
    font-size: 16px
    line-height: 20px
    appearance: none

    &:focus
      outline: 1px solid $blue
  
  input[type="text"]
    padding-left: 2rem
  
  form
    display: none

    @include lbg_tablet
      display: block

  .sidebar_toggle
    display: block
    color: $black
    display: flex
    justify-content: space-between
    align-items: center

    @include lbg_tablet
      display: none

  &.showForm 
    .sidebar_toggle
      margin-bottom: 1.5rem

      svg
        transform: scaleY(-1)

    form
      display: block
  
.sidebar_filter
  background: #f1f5ff
  padding: $gutter

  & > *
    transition: opacity .5s ease

  label
    display: block
    margin-bottom: .5rem
    text-transform: uppercase
    font-weight: 500
    font-size: 12px
    letter-spacing: .05em

  input,
  select
    width: 100%

  select + select
    margin-top: .25rem

  form > div + div
    margin-top: $gutter !important
    width: 100%

.no-items-found,
.no-projects-found,
.no-practices-found
  display: none
  color: $black

  .reset
    display: inline-block
    margin-top: 1rem
    transition: color .3s ease
    text-decoration: underline
    cursor: pointer

    &:hover
      color: $darkGreen

@keyframes sidebar-loading
  0%
    transform: translate(-50%, -50%) scale(1)
  100%
    transform: translate(-50%, -50%) scale(2)
    opacity: 0