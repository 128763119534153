.m13 {
  width: 100%;
  
  &__box {
    width: 100%;
    padding: 24px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba($blue, 0.05);
    border-radius: 3px;
    @include mobile {
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-right: -24px;
      flex-direction: column;
      align-items: flex-start;
    }
    &__left {
      display: flex;
      align-items: center;
      @include mobile {
        margin-bottom: 18px;
      }
    }
    &__icon {
      color: $black;
    }
    &__title {
      max-width: 380px;
      padding-left: 30px;
      h3 {
        font-size: 16px;
        line-height: em(22, 16);
        color: $black;
      }
    }
  }
  &__links {
    display: flex;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__list {
    max-height: 380px;
    overflow: auto;
    margin-right: -30px;
    padding-right: 30px;
    @include mobile {
      max-height: 75%;
    }
  }
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;
    border-bottom: 1px solid rgba($pink, 0.5);
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }
    &:last-child {
      border-bottom: 0;
    }
    h4 {
      max-width: 50%;
      font-size: 16px;
      font-weight: 500;
      line-height: em(22, 16);
      color: $black;
      @include mobile {
        max-width: 100%;
        margin-bottom: 8px;
      }
      @include tablet {
        max-width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    margin-right: 32px;
    color: $blueDark;
    @include mobile {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-right: 0;
      @include mobile {
        margin-bottom: 0px;
      }
    }

    svg {
      transition: $transition1;
    }
    a {
      margin-right: 8px;
      &:hover ~ span {
        color: $green;
      }
    }
  }
  &__btn {
    &:not(:hover) {
      background-color: transparent;
    }
    .btn-primary--outline__icon {
      background-image: none !important;
      svg {
        color: inherit;
      }
    }
  }
  &__search {
    width: 100%;
    margin-bottom: 22px;
    &__title {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      line-height: em(20, 14);
      font-weight: 500;
      color: $black;
      letter-spacing: 0.01px;
      margin-bottom: 8px;
    }
    &__icon {
      margin-right: 10px;
      color: $black;
    }
    &__input {
      width: 100%;
      padding: 10px 12px;
      box-shadow: 0 1px 10px $pink;
      background-color: #fff;
      border-radius: 3px;
      display: flex;
      align-items: center;
      input[type='text'] {
        width: calc(100% - 30px);
        padding: 0;
        outline: 0;
        border: 0;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
}
