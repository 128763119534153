.m18__wrapper {
  .section-title {
    margin-bottom: 24px !important;
  }
}
.whiteBox {
  background-color: #fff !important;
  .blueItem a {
    background: #dee7ff !important;
  }
}

.m18 .program-buttons__description {
  width: initial; 
  max-width: 860px;
}

.sidebar_list {
  display: flex;
  flex-direction: column;
  &__item {
    margin-bottom: 8px;
  }
  &__link {
    width: 100%;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.188em;
    text-decoration: none;
    transition: $transition1;
    &:hover {
      color: $darkGreen;
    }
  }
  @include tablet {
    width: 30%;
  }
  @include tablet-big {
    width: 30%;
  }
  @include mobile {
    display: none;
  }
}

.small_accordions + .small_accordions {
  margin-top: 130px;

  @include mobile {
    margin-top: 90px;
  }
}

.small_accordions {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__item {
    width: 100%;
    border-bottom: 1px solid $pink;
    &--toggled {
      .small_accordions {
        &__title {
          color: $blue;
          background-color: transparent !important;
          &:before {
            background: url('../images/icon-minus.svg') no-repeat center;
          }
        }
        &__content {
          margin-top: -10px;
          max-height: 9999px;
          padding-bottom: 30px;
        }
      }
    }
  }
  &__title {
    color: $black;
    font-size: 20px;
    cursor: pointer;
    font-weight: 600;
    padding: 25px 30px 25px 0;
    line-height: 1.2em;
    position: relative;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    @include mobile {
      padding: 15px 20px 15px 0;
    }

    &:hover {
      color: $blue;
    }

    &::before {
      top: 50%;
      right: 0;
      content: '';
      width: 13px;
      height: 13px;
      position: absolute;
      transform: translateY(-50%);
      background: url('../images/icon-plus.svg') no-repeat center;
    }
    @include mobile {
      font-size: 16px;
    }
  }
  &__content {
    width: 86%;
    max-height: 0;
    overflow: hidden;
    transition: $transition1;
    @include mobile {
      width: 100%;
    }
    p {
      font-size: 16px;
      color: $black;
      line-height: em(22, 16);
    }
    ul {
      max-width: 733px;
      width: 100%;
      margin: 20px auto 75px;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding-left: 40px;
      @include mobile {
        padding-left: 0px;
        max-width: 100%;
        margin: 20px auto 20px;
      }

      @include tablet-big {
        max-width: 100%;
        margin: 20px auto;
      }
      @include tablet {
        max-width: 100%;
        margin: 20px auto;
      }
      li {
        color: $black;
        font-size: 18px;
        margin-bottom: 9px;
        line-height: em(24, 18);
        font-weight: normal;
        position: relative;
        list-style-type: none;
        @include mobile {
          font-size: 16px;
        }

        &:before {
          content: '\2022';
          color: #0032c3;
          font-weight: bold;
          width: 17px;
          border-radius: 50%;
          font-size: 13px;
          font-size: 13px;
          margin-top: 1px;
          display: inline-block;
        }
      }
    }
  }
  &__author {
    display: flex;
    justify-content: space-between;
    max-width: 630px;
    margin: 25px 0;

    @include mobile {
      flex-direction: column;
    }

    &-name {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      min-width: 300px;
      max-width: 300px;
      @include mobile {
        min-width: unset;
        max-width: unset;
      }
      span {
        line-height: 22px;
        font-size: 16px;
        color: $black;
        font-weight: 600;
        margin-bottom: 4px;
        @include desktop {
          white-space: pre-wrap;
        }
        
        @include mobile {
          margin-bottom: 0;
        }
      }
      label {
        font-weight: 500;
        line-height: 22px;
        font-size: 16px;
        color: $pinkDark;
      }
    }
    &-email {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      min-width: 160px;
      max-width: 160px;
      @include mobile {
        min-width: unset;
        max-width: unset;
        margin: 1rem 0 .125rem 0;
      }
      label {
        font-weight: 500;
        line-height: 22px;
        font-size: 16px;
        color: $pinkDark;
        cursor: pointer;
        transition: $transition1;
        &:hover {
          color: $green;
        }
      }
    }
    &-link {
      display: flex;
      align-items: flex-end;
      min-width: 100px;
      max-width: 100px;
      a {
        color: $blueDark;
      }
      @include mobile {
        min-width: unset;
        max-width: unset;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        color: $black;
        line-height: 22px;
      }
    }
  }
}
