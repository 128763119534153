////
/// Grid File
/// @group grid.scss
/// @author Shyqeri Gashi
////

/// Generates grid columns based on the specified variables in _variables.scss
/// This is the default grid
/// @see _variables.scss
/// @require $grid-columns
/// @example .column-12 {
///            width:100%;
///           }
@for $i from 1 through $grid-columns {
  .column-#{$i} {
    width: calc(100% / $grid-columns) * $i;
  }
  @if not($i > calc($grid-columns / 2)) {
    .offset-#{$i} {
      margin-left: calc(100% / $grid-columns) * $i;
    }
  }
}

.wow {
  visibility: hidden;
}

//WILDCARD SELECTOR FOR COLUMNS
[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter {
  [class*='column-'] {
    padding: 0 calc($gutter/2);
  }
}

//CLEARFIX FOR ROWS AND CONTAINER SEE MIXINS FILE
.container,
.row {
  @extend %clearfix;
}

.row {
  margin-left: calc($gutter / 2 * -1);
  margin-right: calc($gutter / 2 * -1);
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 var(--offset);
  
  @include mobile {
    padding: 0 var(--offset);
  }
  @include tablet {
    padding: 0 40px;
    max-width: 688px + $gutter;
  }
  @include tablet-big {
    max-width: 970px + $gutter;
  }
  @include desktop {
    max-width: 1270px + $gutter;
  }
}

.single-team .container section:not(.m31) .container {
  padding: 0
}

//VISIBILITY CLASSES
@include desktop {
  .show-mob {
    display: none !important;
  }
  .show-tab {
    display: none !important;
  }
  .show-tab-big {
    display: none !important;
  }
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

// RESPONSIVE GRID column-mob,column-tab.column-phab see mixins
@include mobile {
  @for $i from 1 through $grid-columns {
    .column-mob-#{$i} {
      width: calc(100% / $grid-columns) * $i;
    }
  }
  [class*='offset-'] {
    margin-left: 0;
  }
  .show-mob {
    display: block !important;
  }
  .hidden-mob {
    display: none !important;
  }
}

@include tablet {
  @for $i from 1 through $grid-columns {
    .column-tab-#{$i} {
      width: calc(100% / $grid-columns) * $i;
    }
  }
  [class*='offset-'] {
    margin-left: 0;
  }
  .show-tab {
    display: block !important;
  }
  .hidden-tab {
    display: none !important;
  }
}

@include tablet-big {
  @for $i from 1 through $grid-columns {
    .column-tab-big-#{$i} {
      width: calc(100% / $grid-columns) * $i;
    }
  }
  [class*='offset-'] {
    margin-left: 0;
  }
  .show-tablet-big {
    display: block !important;
  }
  .hidden-tablet-big {
    display: none !important;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}
