.m16 {
  @include tablet {
    .team-profiles-card {
      border-bottom: 0px;
    }
  }
  @include mobile {
    .team-profiles-card {
      border-bottom: 0px;
    }
  }
  .team-profiles-card-thumb{
    img{
      @extend %imgCropped;
    }
  }

  .team-preview-slider {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    z-index: 3;
    .slick-list {
      padding-left: 0 !important;
      padding-bottom: 10px !important;
      //added for shadow
      padding-top: 10px !important;
      margin-top: -10px;
    }
    .slick-track {
      margin-left: -415px !important;
      @include mobile {
        margin-left: 24px !important;
      }
      @include tablet {
        margin-left: 40px !important;
      }
      @include tablet-big {
        margin-left: -264px !important;
      }
    }
    .card-slider-item {
      width: 439px !important;
      margin-right: 100px;

      @include tablet-big {
        width: 439px !important;
      }
      @include tablet {
        width: 439px !important;
        margin-right: 30px;
      }
      @include mobile {
        width: 300px !important;
        // padding: 26px 20px 22px;
        // min-height: unset;
        margin-right: 30px;
      }
      .column-mob-12{
        &:first-child{
          @include mobile {
            padding-bottom: 36px;
          }
        }
      }
    }
  }
  .slick-arrows-top {
    .slick-prev {
      right: 125px;
      @include tablet-big {
        right: 93px;
      }
      @include tablet {
        right: 120px !important;
        top: -89px;
      }
    }
    .slick-next {
      right: 80px;
      @include tablet-big {
        right: 48px;
      }
      @include tablet {
        right: 78px;
        top: -71px;
      }
    }
  }
  .slick-counter-publication,
  .slick-counter-news {
    top: -92px;
    @include desktop {
      right: calc(100vw / 2 - 588px);
    }
    @include tablet-big {
      display: none;
    }
    @include tablet {
      display: none;
    }
  }
  .slick-arrows-top .slick-prev {
    top: -100px;
    @include desktop {
      right: calc(100vw / 2 - 556px);
    }
    @include tablet-big {
      right: calc(100vw / 2 - 440px);
    }
    @include tablet {
      right: calc(100vw / 2 - 300px);
    }
  }
  .slick-arrows-top .slick-next {
    top: -82px;
    @include desktop {
      right: calc(100vw / 2 - 640px);
    }
    @include tablet-big {
      right: calc(100vw / 2 - 490px);
    }
    @include tablet {
      right: calc(100vw / 2 - 346px);
    }
  }
  @include mobile {
    .banner-slider-item-noImg .event-box-dsc h4 {
      font-size: 20px;
    }
  }
  .btn-primary--outline {
    background-color: transparent;
  }
}
@include tablet {
  .banner-slider-item-noImg .event-box-date {
    h5 {
      font-size: 64px;
    }
  }
  .banner-slider-item-noImg .event-box .blockquote {
    margin-top: 43px;
  }
  .banner-slider-item-noImg .event-box .blockquote-dsc p {
    text-align: right;
  }
  .m16 .team-preview-slider.banner-slider-item {
    min-height: 438px;
  }
  .banner-slider-item-noImg .event-box-dsc h4 {
    min-height: 101px;
  }
}
@include mobile {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}
@include tablet {
  .m16 .banner-slider-item-noImg .event-box-date-schedule {
    display: inline-block;
    float: none;
    margin-left: 95px;
  }
  .m16 .slick-arrows-top .slick-next {
    right: 38px;
  }
  .m16 .slick-arrows-top .slick-prev {
    right: 84px !important;
  }
}
@include tablet-big {
  .m16 .banner-slider-item-noImg .event-box .blockquote {
    margin-top: 58px;
  }
}
