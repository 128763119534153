nav
  position: fixed
  top: 0 
  left: 0 
  right: 0 
  height: 0
  transition: height 0s ease .9s, transform .25s $easeInOutQuart, opacity .2s ease
  pointer-events: none
  overflow: hidden
  z-index: 20
  gap: 2rem

  @include lbg_desktop
    display: flex
    position: static
    padding: 0
    height: auto
    justify-content: flex-start
    align-self: stretch
    align-items: center
    pointer-events: all
    overflow: initial
    gap: clamp(1.75rem, 2.5vw, 3rem)

  &::before
    content: ''
    position: fixed
    top: 0
    left: 0
    right: 0
    height: 100%
    z-index: -1
    background: var(--white)
    transform: translate3d(0,-100%,0)
    overflow: hidden
    opacity: 0
    transition: transform 0s linear .5s, opacity .4s ease, background 0s linear .5s

  &::after
    @include to_tablet
      content: ''
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: 6rem
      background: linear-gradient( 0deg, hsla(var(--white-hsl), 0) 0, hsla(var(--white-hsl), 1) 33% )
      z-index: 100
      opacity: 0
      transition: opacity .15s ease .3s

  .nav__mobile
    margin: 5rem $gutter 1rem $gutter
    border-bottom: 1px solid hsla(var(--primary-hsl), .2)
    display: flex
    justify-content: space-between
    opacity: 0
    align-items: center
    height: 2.5rem
    transition: opacity .2s ease
    text-transform: uppercase
    font-weight: 500
    letter-spacing: 0.1em
    font-size: .875rem

    @include lbg_desktop
      display: none

    ul
      display: flex
      margin-right: -.375rem

    a
      display: flex
      align-items: center
      height: 2.5rem
      opacity: .5
      padding: 0 .375rem

      &.lang__active
        opacity: 1

    &, a
      color: var(--primary)

nav.is--toggled
  height: 100%
  transition: height 0s linear
  pointer-events: all
  overflow: auto

  & > .btn
    opacity: 1
    transition: opacity .2s ease .85s

  &::before
    opacity: 1
    transition: transform .4s $easeOutQuart, background .2s ease
    transform: translate3d(0,0,0)

  &::after
    opacity: 1
    transition: opacity .2s ease
  
  & + .header__meta .headerWrap__hamburger
    opacity: 1
    pointer-events: all
    transition: opacity .2s ease .5s

  .nav__mobile
    opacity: 1

  ul.menu
    opacity: 1

    & > li
      opacity: 1

      @for $i from 1 through 8

        &:nth-child(#{$i})
          transition: opacity .3s ease #{$i * .0375 + .15 + 's'}