.side_menu
  position: fixed
  left: 0
  top: 0
  right: 0
  height: 0
  overflow: hidden
  opacity: 0
  transition: opacity .25s ease, height 0s linear .25s
  display: grid
  place-items: stretch
  pointer-events: none
  z-index: 50

  @include lbg_desktop  
    top: 5.625rem
    background: #fff

  .quicklinks
    padding: $gutter*2 $gutter 7rem $gutter
    background: rgba(241, 245, 255, 1)
    border-bottom: 1px solid hsla(var(--primary-hsl), .05)
    pointer-events: all
    position: relative

    @include lbg_desktop  
      margin: var(--offset)
      margin-top: 0
      padding: var(--offsetWide)

.side_menu__close
  cursor: pointer

  @include to_desktop
    position: fixed
    left: 50%
    bottom: $gutter*1.5
    background: green
    height: 2.75rem
    border: 1px solid var(--primary)
    border-radius: 2.75rem
    display: grid
    place-items: center
    min-width: 200px
    background: var(--white)
    transform: translateX(-50%)
    line-height: 1
    text-transform: uppercase
    color: var(--primary)
    font-weight: 500
    letter-spacing: 0.1em
    font-size: .875rem
    pointer-events: none

  @include lbg_desktop
    position: absolute
    top: 0
    right: 0
    width: 5rem
    aspect-ratio: 1/1
    display: grid
    place-items: center

    &::after,
    &::before
      grid-area: 1/1
      content: ''
      width: 1.75rem
      height: 1px
      background: var(--primary)
      transform: rotate(45deg)

    &::before
      transform: rotate(-45deg)

    span
      @include lbg_desktop
        display: none

.side_menu__item
  @include lbg_desktop  
    display: grid
    grid-template-columns: 1.25fr 2fr

  .fz-h1
    color: var(--primary)
    line-height: 1.25
    font-family: 'Antonia H1'

    @include to_desktop
      font-size: 1.5rem
      line-height: 1.2
      font-family: 'Antonia H2'

  ul
    justify-self: start

    @include lbg_desktop  
      column-count: 2
      column-gap: 2rem
      margin-top: .5rem
      margin-bottom: -.5rem

  li 
    padding-right: 0

    @include lbg_tablet
      padding-right: clamp(2rem, 5vw, 5rem)
    
    a
      font-size: 1.125rem
      display: inline-block
      padding: .5rem 0
      color: $black

      &:hover
        color: var(--primary)
        text-decoration: underline

.side_menu__item + .side_menu__item
  margin-top: clamp(2rem, 5vh, 5rem)
  padding-top: clamp(2rem, 5vh, 5rem)
  border-top: 1px solid hsla(var(--primary-hsl), .2)

.side_menu.is--toggled
    overflow: auto
    opacity: 1
    transition: opacity .25s ease
    height: 100%

    @include lbg_desktop 
      height: calc( 100% - 5.625rem)