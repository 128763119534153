.wysiwyg {
  p:not(.m07__leading) {
    color: $black;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    font-weight: normal;

    @include mobile {
      font-size: 16px;
    }

    &:last-child {
      margin-bottom: 0
    }
    
    a:not(.btn-primary):not(.lightbox-a-tag):not(.extra-links-a-tag):not(.extra-links-a-tag):not(.sidebar-link) {
      font-size: inherit;
    }
  }

  blockquote {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
    margin-bottom: 24px;

    @include mobile {
      margin-top: 10px;
    }

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      position: absolute;
      left: -24px;
      top: 10px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='13px' viewBox='0 0 11 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Sprint-3-/-Abgabe' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Xlg_Open-Innovation-in-Science' transform='translate(-156.000000, -2363.000000)' fill='%23001141' fill-rule='nonzero'%3E%3Cg id='Group-7' transform='translate(156.000000, 2353.000000)'%3E%3Cpath d='M2.05112148,22.8953846 C3.20262828,22.8953846 3.74239709,21.9261538 3.74239709,20.7146154 C3.74239709,19.3576923 3.05868993,18.8730769 1.51135267,19.0184615 C1.47536808,18.8730769 1.47536808,18.6307692 1.47536808,18.2915385 C1.47536808,15.82 2.8427824,13.2515385 4.85791929,11.6523077 L4.5700426,10.8769231 C1.65529102,12.4276923 0,15.6746154 0,19.1638462 C0,21.3446154 0.755676335,22.8953846 2.05112148,22.8953846 Z M8.06054757,22.8953846 C9.21205437,22.8953846 9.78780776,21.8776923 9.78780776,20.7146154 C9.78780776,19.3576923 9.06811602,18.8730769 7.52077876,19.0184615 C7.48479417,18.8730769 7.48479417,18.6307692 7.48479417,18.2915385 C7.48479417,15.82 8.85220849,13.2515385 10.8673454,11.6523077 L10.5794687,10.8769231 C7.7007017,12.4276923 6.00942609,15.6746154 6.00942609,19.1638462 C6.00942609,21.3446154 6.76510242,22.8953846 8.06054757,22.8953846 Z' id='“'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;

      @include mobile {
        left: -18px;
      }
    }

    p {
      font-family: 'Antonia H3';
      font-size: 30px !important;
      line-height: em(39, 30) !important;
      color: $black !important;
      margin-bottom: 9px !important;
    }

    h6 {
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: em(20, 14) !important;
      color: $black !important;
      margin-bottom: 0 !important;
    }
  }

  table {
    margin: 0 auto;
    margin-bottom: 24px;
    border-left: 1px solid #ebf0f3;
    border-top: 1px solid #ebf0f3;
  }

  th,
  td {
    padding: 10px 15px;
    border-right: 1px solid #ebf0f3;
    border-bottom: 1px solid #ebf0f3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Antonia H3';
    margin-bottom: 20px;
    color: $black;
  }

  h1 {
    font-size: 38px;
    line-height: em(44, 38);

    @include mobile {
      font-size: 32px;
      line-height: em(38, 32);
    }
  }

  h2 {
    font-size: 32px;
    line-height: em(40, 32);

    @include mobile {
      font-size: 26px;
      line-height: em(32, 26);
    }
  }

  h3 {
    font-size: 26px;
    line-height: em(32, 26);

    @include mobile {
      font-size: 22px;
      line-height: em(28, 22);
    }
  }

  h4 {
    font-size: 20px;
    line-height: em(28, 20);

    @include mobile {
      font-size: 18px;
      line-height: em(24, 18);
    }
  }

  h5 {
    font-size: 18px;
    line-height: em(24, 18);

    @include mobile {
      font-size: 16px;
      line-height: em(20, 16);
    }
  }

  h6 {
    font-size: 14px;
    line-height: em(20, 14);
  }

  img {
    width: auto;
    text-align: center;
    height: auto;
    margin-bottom: 20px;
  }

  iframe {
    margin-bottom: 20px !important;
    text-align: center;

    @include mobile {
      width: 100% !important;
      max-width: 100% !important;
      min-height: 210px;
    }

    @include tablet {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  // UNORDERED LISTS
  ul:not(.extra-links):not(.stats-list-ul) {
    // margin: 20px auto 75px;
    margin: 0 auto 30px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 40px;

    @include mobile {
      padding-left: 0px;
      margin: 20px auto 20px;
    }

    @include tablet-big {
      margin: 20px auto;
    }

    @include tablet {
      max-width: 100%;
      margin: 20px auto;
    }

    &:first-child {
      margin-top: 0
    }

    li {
      color: $black;
      font-size: 18px;
      margin-bottom: 9px;
      line-height: em(24, 18);
      font-weight: normal;
      position: relative;
      list-style-type: none;
      padding-left: 1rem;

      @include mobile {
        font-size: 16px;
      }

      &:before {
        content: '\2022';
        position: absolute;
        left: 0;
        top: 0;
        color: #0032c3;
        font-weight: bold;
        border-radius: 50%;
        font-size: 13px;
      }
    }
  }

  // ORDERED LISTS
  ol {
    max-width: 733px;
    width: 100%;
    margin: 0 auto 30px;
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    list-style: auto;

    @include mobile {
      padding-left: 26px;
      max-width: 100%;
      margin: 20px auto 20px;
    }

    @include tablet-big {
      max-width: 100%;
      margin: 20px auto;
    }

    @include tablet {
      max-width: 100%;
      margin: 20px auto;
    }

    li {
      color: $black;
      font-size: 18px;
      margin-bottom: 9px;
      line-height: em(24, 18);
      font-weight: normal;
      position: relative;

      @include mobile {
        font-size: 16px;
      }

      &::marker {
        color: #0032c3;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

.m68 .embedded,
.wysiwyg .embedded {
  margin-bottom: 24px;

  .btn-primary {
    justify-content: center
  }
  
  &.youtube,
  &.googlemaps {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}