.m05 {
  .publication-slider-mobile-item,
  .publication-slider-item {
    &-inner {
      padding: 48px 0;
      border-bottom: 1px solid #b8a9bc;
      @include tablet {
        &:nth-child(1) {
          padding-top: 0;
        }
      }
      @include mobile {
        padding: 40px 0;
        &:nth-child(1) {
          padding-top: 0;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    &-left {
      display: flex;
      &--thumb{
        width: 85px;
        height: 114px;
        margin-right: 24px;
        img{
          @extend %imgCropped;
        }
      }
      &--content{
        width: calc(100% - 100px);
      }
      @include tablet {
        width: 100%;
        position: relative;

        .title {
          float: left;
          max-width: 70%;
          margin-right: 20px;
        }
        .tags-area {
          padding-left: 40px;
        }
      }
      h3 {
        font-family: 'Antonia H3';
        font-weight: 500;
        color: #001141;
        font-size: 20px;
        line-height: em(24, 20);
        margin-bottom: 21px;
        @include tablet-big {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
          margin-bottom: 16px;
          line-height: 22px;
        }
        @include tablet {
          font-size: 18px;
        }
      }
      p {
        font-weight: normal;
        color: #001141;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.42857em;
        @include mobile {
          line-height: 20px;
        }
      }
    }
    &-right {
      padding-left: 116px;
      @include mobile {
        padding-left: 0;
      }
      @include tablet {
        padding-left: 0;
        width: 70%;
      }
      p {
        color: #001141;
        font-size: 14px;
        line-height: em(20, 14);
        b{
          font-weight: 500;
        }
      }
    }
  }
  // @include tablet {
  //   .section-title {
  //     margin-bottom: 0;
  //   }
  // }
}
.tags-area {
  display: block;
  margin-top: 20px;
  li {
    float: left;
    margin-right: 4px;
    a,
    span {
      color: #001141;
      font-size: 12px;
      line-height: em(20, 12);
      background: #dee7ff;
      border-radius: 4px;
      padding: 2px 6px 3px;
      @include mobile {
        padding: 0 6px;
      }
    }
  }
}
@include mobile {
  .m05 {
    .tags-area {
      float: left;
      li {
        a {
          padding: 4px 7px;
        }
      }
    }
    .content-publication {
      margin-bottom: 16px;
    }
  }
  .btn-primary--outline {
    padding: 14px 18px 14px 18px;
    &__icon {
      margin-left: 18px;
    }
  }
}
