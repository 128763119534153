#totop
  position: fixed
  bottom: 4rem
  right: var(--offset)
  width: 3rem
  aspect-ratio: 1/1
  background: var(--primary)
  z-index: 10
  opacity: 0
  pointer-events: none
  transition: opacity .2s ease
  display: grid
  place-items: center
  cursor: pointer


  @include to_tablet
    display: none

  .lbg-arrow
    transform: rotate(-90deg)
    width: 1rem

    &::after
      border-color: var(--white)

    &::before 
      background: var(--white)

  &.visible
    opacity: 1
    pointer-events: all