.m08 {
  .wysiwyg {
    a {
      color: inherit;
      text-decoration: underline;
      transition: color .25s ease;

      &:hover {
        color: $green
      }
    }
  }

  .info-box {
    display: table;
    max-width: 1054px;
    margin: 0 auto;
    // height: 560px;
    padding: 60px 108px;
    background: rgba(0, 50, 195, 0.1);
    @include tablet-big {
      max-width: 100%;
      padding: 40px;
      // height: 670px;
    }
    @include tablet {
      max-width: 100%;
      padding: 40px;
      // height: 670px;
    }
    @include mobile {
      max-width: 100%;
      padding: 20px;
      // height: 964px;
    }
    &-thumbnail-area {
      float: left;
      width: 191px;
      height: 255px;
      position: relative;
      @include tablet-big {
        width: 160px;
        height: 220px;
      }
      @include tablet {
        width: 160px;
        height: 220px;
      }
      @include mobile {
        width: 130px;
        height: 173px;
      }
      img {
        @extend %imgCropped;
      }
      &__dsc {
        display: block;
        margin-top: 8px;
        @include mobile {
          position: absolute;
          left: 140px;
          bottom: -15px;
          width: 170px;
        }
        p {
          color: #7e6e7e;
          font-size: 14px;
          line-height: em(16, 14);
          font-weight: normal;
        }
      }
    }
    &-dsc {
      float: left;
      width: calc(100% - 192px);
      padding-left: 30px;
      padding-top: 65px;
      margin-bottom: 32px;
      position: relative;
      @include tablet {
        width: calc(100% - 160px);
      }
      @include tablet-big {
        width: calc(100% - 160px);
      }
      @include mobile {
        width: 100%;
        padding-left: 0px;
        padding-top: 45px;
      }
      h4 {
        font-size: 18px;
        color: #001141;
        font-weight: 500;
        margin-bottom: 20px;
      }
      p {
        color: #001141;
        font-size: 18px;
        line-height: em(24, 18);
        font-weight: normal;
      }
      .icon {
        position: absolute;
        right: 0;
        top: 0;
        @include mobile {
          top: -170px;
        }
      }
    }
    .source-url {
      float: left;
      width: 100%;
      color: #001141;
      font-size: 10px;
      margin-bottom: 32px;
      line-height: em(13, 10);
      font-weight: normal;
    }
  }
}
