:root
  --primary-hsl: 225, 100%, 38%
  --primary:     hsla(var(--primary-hsl), 1)

  --color-hsl:   224, 100%, 13%
  --color:       hsla(var(--color-hsl), 1)

  --white-hsl:   0, 0%, 100%
  --white:       hsla(var(--white-hsl), 1)

  --blue-light:  #F1F5FF
  --offset:      clamp(1rem, 4vw, 3rem)
  --offsetWide:  clamp(1.5rem, 4vw, 6rem)
