.rs-container * {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.rs-container {
    font-family: Arial, Helvetica, sans-serif;
    height: 45px;
    position: relative;
}
.rs-container .rs-bg, .rs-container .rs-selected {
    background-color: #eee;
    height: 8px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 100%;
    border-radius: 2px;
}
.rs-container .rs-selected {
    background-color: $blue;
    transition: all 0.2s linear;
    width: 0;
}
.rs-container.disabled .rs-selected {
    background-color: #ccc;
    border-color: #bbb;
}
.rs-container .rs-pointer {
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 2px;
    cursor: pointer;
    height: 20px;
    left: -10px;
    position: absolute;
    top: 0;
    transition: all 0.2s linear;
    width: 30px;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 6px #ebebeb, 1px 1px 4px rgba(0, 0, 0, 0.1);
}
.rs-container.disabled .rs-pointer {
    border-color: #ccc;
}
.rs-container .rs-pointer::before,
.rs-container .rs-pointer::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 9px;
    background-color: #ddd;
    left: 12px;
    top: 5px;
}
.rs-container .rs-pointer::after {
    left: auto;
    right: 12px;
}
.rs-container.disabled .rs-pointer {
    cursor: default;
}
.rs-container.sliding .rs-selected,
.rs-container.sliding .rs-pointer {
    transition: none;
}
.rs-container .rs-scale {
    left: 0;
    position: absolute;
    top: 5px;
    white-space: nowrap;
}
.rs-container .rs-scale span {
    float: left;
    position: relative;
}
 .rs-container .rs-scale span::before {
    background-color: #ededed;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 1px;
}
.rs-container.rs-noscale span::before {
    display: none;
}
.rs-container.rs-noscale span:first-child::before,
.rs-container.rs-noscale span:last-child::before {
    display: block;
}
.rs-container .rs-scale span:last-child {
    margin-left: -1px;
    width: 0px;
}
.rs-container .rs-scale span ins {
    color: #333;
    display: inline-block;
    font-size: 12px;
    margin-top: 20px;
    text-decoration: none;
}
.rs-container.disabled .rs-scale span ins {
    color: #999;
}
.rs-tooltip {
    color: var(--color);
    width: auto;
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 22px;
    text-align: center;
    font-size: 13px;
}
.rs-container.disabled .rs-tooltip {
    border-color: #ccc;
    color: #999;
}