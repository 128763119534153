// m48 & m49 share a lot of styling 
// m53 adds / overrides styles

.m53
  .projects 
    @include lbg_desktop
      grid-column: 1 / span 7

    ul
      display: grid
      grid-template-columns: 1fr 1fr
      gap: $gutter

      li
        // display: block !important
        grid-column: span 2

        @include lbg_mobile
          grid-column: span 1
          display: flex
          flex-direction: column
          justify-content: space-between

        &.project__chapter
          @include lbg_mobile
            grid-column: span 2
        
        &:nth-child(n+4)
          display: block

          @include lbg_mobile
            display: flex
           
  .projects li.project + .project
    border-top: none
    margin-top: 0
    padding-top: 0

  .projects li.project + .project__chapter
    margin-top: $gutter

  .project__chapter
    margin-bottom: calc($gutter / 2) * -1

    &.take-part .fz-h3
      color: $blue

  .project
    background: #f1f5ff

    &.take-part
      background: $blue
      color: #fff

      h2 a:hover
        color: #fff

      .image
        border: 2px solid $blue

    .image
      margin-bottom: 0

    .btn-primary,
    .btn-secondary,
    .btn-primary--outline,
    .btn-secondary--outline
      margin: 0 1rem 1.25rem 1rem
      justify-content: center
      padding-left: 2rem
      padding-right: 2rem
      min-width: auto

    h2
      margin: 1.25rem 1rem

      span
        display: block
        line-height: 1.25
        font-size: 16px
        font-family: Whitney, sans-serif

.m53__single .container
  .image-tags
    @include lbg_mobile
      grid-template-columns: 18.5rem 1fr

  .tag-list
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1.5rem 1rem
    max-width: 400px