.m01 {
  width: 100%;
  height: 530px;
  max-width: 100%;
  margin: 0 auto;
  background-color: $blue;
  background-image: linear-gradient(90deg, #0032C3 0%, #00289B 100%);
  padding: $d-vs 0;
  position: relative;
  @include tablet-big {
    height: 534px;
  }
  @include tablet {
    height: 576px;
    padding: $t-vs 0;
    .slick-list {
      padding-bottom: 30px !important;
    }
  }
  @include mobile {
    height: 476px;
    padding: 95px 0 124px;

    .slick-list {
      padding-bottom: 30px !important;
    }
    .slick-track {
      // margin-left: -34px;
      margin-left: 20px;
    }
  }

  &.m01__has-slider {
    margin-bottom: $d-vs;

    @include tablet {
      margin-bottom: 360px;
    }

    @include mobile {
      margin-bottom: 400px;
    }
  }

  &:before {
    content: "";
    background-image: url('../images/m01-triangle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  &.turquoise_triangles {
    background: #00BA96;
    
    &:before {
      background-image: url('../images/m01-triangle--turquoise.svg');
    }
  }
  
  &.lightblue_triangles {
    background: #6E85C6;
    
    &:before {
      background-image: url('../images/m01-triangle--lightblue.svg');
    }
  }

  &.background_image {
    background: $blueLight;
    
    &:before {
      display: none;
    }

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner-slider:not(.slick-initialized){
    height: 475px;
    overflow: hidden;
  }
  .banner-inner {
    max-width: 533px;
    h1 {
      font-size: 56px;
      font-weight: 400;
      color: #fff;
      line-height: em(60, 56);
      margin-bottom: 13px;
      font-family: 'Antonia H1';
      @include tablet {
        font-size: 50px;
      }
      @include mobile {
        font-size: 40px;
      }
    }
    p {
      color: #fff;
      font-size: 18px;
      line-height: em(24, 18);
      @include mobile {
        font-size: 16px;
      }
    }
    .btn-secondary--outline {
      margin-top: 40px;
      @include mobile {
        margin-top: 36px;
      }
    }
  }
  .banner-slider-holder {
    position: absolute;
    right: 14px;
    top: -67px;
    width: 408px;
    background: #f2eff4;
    border-radius: 4px;
    @include tablet-big {
      width: 379px;
    }

    @include tablet {
      width: 100vw;
      top: 337px;
      right: 0;
      background: none;
      right: 0;
    }
    @include mobile {
      width: 100vw;
      top: 344px;
      right: 0;
      background: none;
      right: 0;
    }
    @include tablet-big {
      right: 25px;
      top: -20px;
    }

    .noted-txt {
      color: #001141;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 24px;
      font-variant: small-caps;
      @include tablet-big {
        padding: 16px 24px 10px 24px;
      }
      @include tablet {
        padding: 0;
      }
      @include mobile {
        padding: 0;
      }
    }
  }
  .slick-prev,
  .slick-next {
    bottom: 0;
    top: auto;
    width: 28px;
    height: 26px;
    z-index: 2;
    &:before,
    &:after {
      display: none;
    }
  }
  .slick-prev {
    left: 22px;
    bottom: 21px;
    background: url('../images/arrow-outline-right-blue.svg') no-repeat 0 0;
    transform: rotate(180deg);
  }
  .slick-next {
    right: 24px;
    bottom: 10px;
    background: url('../images/arrow-outline-right-blue.svg') no-repeat 0 0;
  }
  .slick-counter {
    text-align: center;
    position: absolute;
    bottom: 27px;
    width: 100%;
    z-index: 1;
    @include tablet {
      display: none;
    }
    @include mobile {
      display: none;
    }
    span {
      color: #001141;
      font-weight: normal;
      font-size: 14px;
      line-height: em(20, 14);
    }
  }
}
#slick-1 .slick-dots li {
  width: 40px;
  height: 5px;
  background: #ccc;
}
#slick-1 .slick-dots li button {
  width: 40px;
  height: 5px;
}
#slick-1 .slick-dots li.slick-active,
#slick-1 .slick-dots li:hover {
  background: #777;
}
#slick-1 .slick-dots li button,
#slick-1 .slick-dots li button:before {
  color: transparent;
  opacity: 0;
}

/* progress bar */
.slider-progress {
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
}
.slider-progress .progress {
  width: 0%;
  height: 3px;
  background: #b8a9bc;
}
@include tablet {
  .m01 .banner-slider-item-noImg .event-box-dsc {
    float: left;
    width: 100%;
  }
  .m01 .banner-slider-item-noImg .event-box .blockquote {
    margin-top: 10px !important;
  }
  .m01 .banner-slider-item-noImg .event-box .blockquote {
    padding-left: 0;
  }
  .m01 .banner-slider-item .dsc {
    margin-top: 16px;
  }
  .m01 .banner-slider-item {
    padding-bottom: 21px;
  }
  .m01 .banner-slider-item-noImg {
    padding-bottom: 30px;
  }
}
