nav ul.menu
  opacity: 0
  transition: opacity .2s ease
  margin-bottom: 7rem

  @include lbg_desktop
    display: flex
    justify-content: space-between
    align-items: center
    opacity: 1
    height: 100%
    margin-bottom: 0
    gap: clamp(1.5rem, 2vw, 3rem)

  & > li
    opacity: 0

  li
    white-space: nowrap
    position: relative
    transition: opacity .25s ease

    @include lbg_tablet
      transition: opacity .25s ease
      opacity: 1

    &.menu-item-has-children
      & > a
        @include to_desktop
          display: grid
          grid-template-columns: 1fr .5rem
          align-items: center

          &::after
            content: ''
            width: 100%
            aspect-ratio: 1/1
            border-right: 1.5px solid var(--primary)
            border-bottom: 1.5px solid var(--primary)
            transform: translateY(-50%) rotate(45deg)

      &.is--open
        .sub-menu
          display: block

        & > a::after
          transform: translateY(0%) rotate(-135deg)

    &.mobile
      @include lbg_desktop
        display: none

    &:hover
      & > a
        color: var(--primary)

      & > ul
        opacity: 1
        pointer-events: all
        transition: opacity .2s ease, transform .2s $easeOutQuart

  & > li > a
    padding: .75rem 1.5rem
    display: block
    color: var(--primary)

    @include to_desktop
      font-size: 1.5rem
      line-height: 1.2
      font-family: 'Antonia H2'

    @include lbg_desktop
      font-size: 1.125rem
      padding: 0
      margin: 0
      height: 3rem
      display: flex
      align-items: center