.m04 {
  background: #f6f3f7;

  .center-element {
    float: none;
  }

  .banner-slider-item-noImg .event-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .events-slider {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    z-index: 3;
    
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0; 
      bottom: 0; 
      width: 10%;
      background: linear-gradient(90deg, rgba(246,243,247,1) 10%, rgba(246,243,247,0) 100%);
      z-index: 1;
      pointer-events: none;
      user-select: none;
    }

    &::after {
      right: 0;
      transform: scaleX(-1);
    }

    &::before {
      left: 0;
    }

    .slick-list {
      padding-left: 0 !important;
      padding-bottom: 10px !important;
      //added for shadow
      padding-top: 10px !important;
      margin-top: -10px;
    }
    .slick-track {
      margin-left: -434px !important;
      @include mobile {
        margin-left: 24px !important;
      }
      @include tablet {
        margin-left: 40px !important;
      }
      @include tablet-big {
        margin-left: -300px !important;
      }
    }
    .banner-slider-item {
      width: 407px !important;
      min-height: 468px;
      background: #fff;
      margin-right: 24px;
      padding: 28px 22px 37px;
      border-radius: 4px;
      box-shadow: 0px 2px 14px #dad5d5;
      transition: $transition1;
      &:hover{
        box-shadow: 0 0 13px 0 rgba(126, 110, 126, 0.8);
      }
      @include tablet-big {
        width: 378px !important;
      }
      @include tablet {
        width: 451px !important;
      }
      @include mobile {
        width: 269px !important;
        padding: 26px 20px 22px;
        min-height: unset;
        margin-right: 16px;
      }
    }
  }
  .slick-arrows-top {
    .slick-prev { 
      right: 125px;
      @include tablet-big {
        right: 93px;
      }
      @include tablet {
        right: 120px !important;
        top: -89px;
      }
    }
    .slick-next {
      right: 80px;
      @include tablet-big {
        right: 48px;
      }
      @include tablet {
        right: 78px;
        top: -71px;
      }
    }
  }
  @include mobile {
    .banner-slider-item-noImg .event-box-dsc h4 {
      font-size: 20px;
    }
  }
  .slick-arrows-top .slick-prev {
    top: -100px;
    @include desktop {
      right: calc(100vw / 2 - 594px);
    }
    @include tablet-big {
      right: calc(100vw / 2 - 478px);
    }
    @include tablet {
      right: calc(100vw / 2 - 338px);
    }
  }
  .slick-arrows-top .slick-next {
    top: -82px;
    @include desktop {
      right: calc(100vw / 2 - 640px);
    }
    @include tablet-big {
      right: calc(100vw / 2 - 490px);
    }
    @include tablet {
      right: calc(100vw / 2 - 346px);
    }
  }
}
@include tablet {
  .banner-slider-item-noImg .event-box-date {
    h5 {
      font-size: 64px;
    }
  }
  .banner-slider-item-noImg .event-box .blockquote {
    margin-top: 43px;
  }
  .banner-slider-item-noImg .event-box .blockquote-dsc p {
    text-align: right;
  }
  .m04 .events-slider .banner-slider-item {
    min-height: 438px;
  }
}
@include mobile {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}
@include tablet {
  .m04 .banner-slider-item-noImg .event-box-date-schedule {
    display: inline-block;
    float: none;
    margin-left: 95px;
  }
  .m04 .slick-arrows-top .slick-next {
    right: 38px;
  }
  .m04 .slick-arrows-top .slick-prev {
    right: 84px !important;
  }
}
@include tablet-big {
  .m04 .banner-slider-item-noImg .event-box .blockquote {
    margin-top: 58px;
  }
}
