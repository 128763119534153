// m60 & m61 share styles
.m61
  .m61__person
    display: grid
    gap: 1.5rem
    grid-template-columns: 5.25rem 1fr
    grid-column: span 12

    @include lbg_tablet
      grid-column: span 6
    
    @include lbg_desktop
      grid-column: span 5

    &:hover 
      .m61__name
        color: $green

  .m61__name
    color: $black

    span
      font-size: 1rem

    .m61__name_prefix
      color: #7e6e7e

    .m61__name_suffix
      color: $black

    .m61__name
      transition: all .3s cubic-bezier(.3,0,.2,1)
      font-weight: 600
      font-size: 1.125rem
      margin: .125rem 0 .5rem 0