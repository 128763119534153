a
  text-underline-offset: 0.125rem
  text-decoration-thickness: 1px !important
      
a.underline
  text-decoration: underline

.fz-d1,
.fz-h0,
.fz-h1,
.fz-h2,
.fz-h3,
.fz-h4,
.fz-h5
  color: $black
  line-height: 1.2
  font-family: 'Antonia H3'

.fz-d1
  font-size: clamp(2rem, 4vw, 5.625rem)

.fz-h0
  font-size: 32px

  @include lbg_tablet
    font-size: 42px

  @include lbg_desktop
    font-size: 48px

.fz-h1
  &,
  main &, 
  main .wysiwyg &
    font-size: 32px

    @include lbg_tablet
      font-size: 38px

    @include lbg_desktop
      font-size: 42px

.fz-h2
  font-size: 32px

  @include mobile
    font-size: 26px

.fz-h3
  font-size: 26px

  @include mobile
    font-size: 22px

.fz-h4
  font-size: 22px

  @include mobile
    font-size: 18px

.fz-h5
  font-size: 18px

  @include mobile
    font-size: 16px

.fz-p1,
.fz-p2
  line-height: 1.3

.fz-p1
  font-size: 18px

  @include mobile
    font-size: 16px

.fz-p2
  font-size: 16px

  @include mobile
    font-size: 15px