.m64
  // reset outdated styling behaviour
  clear: both
  font-size: 16px

  // start styling
  overflow: hidden

  .swiper-meta
    @include mobile
      margin: 0 1rem

  .swiper-slide
    width: initial
    height: 14rem

    @include lbg_tablet
      height: 55vh

    img
      height: 100%
      width: auto
      user-select: none