////
/// VARIABLES FILE File
/// @group _variables.scss
/// @author Shyqeri Gashi
////
/*====================================
=            GRID GLOBALS            =
====================================*/
/// The total number of grid columns
/// @type Number
$grid-columns: 12;
/// The gutter of the grid
/// @type Number
$gutter: 24px;
@include mobile {
  $gutter: 16px;
}

/*=====  End of GRID GLOBALS  ======*/

/*===================================
=            TRANSITIONS            =
===================================*/
/// Google Material Design cubic bezier transition
/// @type Transition
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
/// Google Material Design cubic bezier transition slower
/// @type Transition
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
/// @type Transition
$transition3: all 0.5s ease-in-out;

/*=====  End of TRANSITIONS  ======*/

/*=================================
=            STRUCTURE            =
=================================*/
$d-vs: 150px;
$t-vs: 120px;
$m-vs: 80px;
/*=====  End of STRUCTURE  ======*/

/*==============================
=            COLORS            =
==============================*/
$blue: #0032c3;
$blueLight: #dee7ff;
$blueLighter: #F0F3F7;
$blueDark: #002387;
$green: #00ba96;
$darkGreen: #008576;
$pink: #b8a9bc;
$pinkDark: #7e6e7e;
$pinkLight: #f6f4f7;
$black: #001141;
$black75: #8e939c;
$black50: #BBC1CC;
$black20: #CCCFD9;
$black10: #dde1eb;
/*=====  End of COLORS  ======*/


//==============================================================================
// Transitions
//==============================================================================

$ease          : cubic-bezier(0.25, 0.1, 0.25, 1);
$linear        : cubic-bezier(0.0, 0.0, 1, 1);
$easeIn        : cubic-bezier(0.42, 0, 1, 1);
$easeOut       : cubic-bezier(0, 0, 0.58, 1);
$easeInOut     : cubic-bezier(0.42, 0, 0.58, 1);

$easeInSine    : cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine   : cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine : cubic-bezier(0.445, 0.05, 0.55, 0.95);

$easeInQuad    : cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad   : cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad : cubic-bezier(0.455, 0.03, 0.515, 0.955);

$easeInCubic    : cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1);

$easeInQuart    : cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart   : cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);

$easeInQuint    : cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint   : cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint : cubic-bezier(0.86, 0, 0.07, 1);

$easeInExpo     : cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo    : cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo  : cubic-bezier(1, 0, 0, 1);

$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc    : cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);

$easeInBack     : cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack  : cubic-bezier(0.68, -0.55, 0.265, 1.55);