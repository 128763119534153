.main_header
  // reset outdated styling behaviour
  font-size: 16px

  // start styling
  background-color: #fff
  position: fixed
  z-index: 9
  top: 0
  left: 0
  right: 0
  display: grid
  grid-template-columns: 1fr auto
  align-items: center
  height: 5.625rem
  gap: 2.5rem
  padding: 0 var(--offset)

  @include tablet-big 
    height: 5.25rem

  @include tablet
    height: 4.125rem

  @include mobile
    height: 4.625rem
  
  @include lbg_desktop
    grid-template-columns: 1fr auto auto

.header__logo
  position: relative
  z-index: 21

  img
    display: block
    width: auto
    max-width: none
    height: 40px

  &.logo--large
    img
      height: 52px
      
      @include tablet
        height: 44px
      
      @include mobile
        height: 40px

.header__meta
  display: flex
  height: 2.5rem
  position: relative
  z-index: 21

  @include lbg_desktop
    gap: .25rem
    padding: 0 .5rem
    border-radius: 2.5rem
    border: 1px solid var(--primary)

  > *
    display: flex
    align-items: center
    padding: 0 1.25rem
    position: relative
    line-height: 1
    text-transform: uppercase
    color: var(--primary)
    font-weight: 500
    letter-spacing: 0.1em
    font-size: .875rem

    @include lbg_desktop
      padding: 0 .75rem
      font-size: .75rem
  
  a
    color: var(--primary)

  .headerWrap__search
    .disable-hide &
      @include to_desktop
        display: none

    &::before
      @include to_desktop
        display: none

  .headerWrap__lang
    @include to_desktop
      display: none

  .headerWrap__hamburger
    @include to_desktop
      position: fixed
      left: 50%
      bottom: $gutter*1.5
      background: green
      height: 2.75rem
      border: 1px solid var(--primary)
      border-radius: 2.75rem
      display: grid
      place-items: center
      min-width: 200px
      background: var(--white)
      transform: translateX(-50%)
      opacity: 0
      pointer-events: none
      transition: opacity .2s ease

  .headerWrap__menu_toggler
    padding-right: .5rem

    .disable-hide &::before
      display: none

    @include desktop
      display: none

    .headerWrap__menu_toggler--close
      display: none

    &.is--toggled
      .headerWrap__menu_toggler--open
        display: none

      .headerWrap__menu_toggler--close
        display: inline

  > * + *::before
    position: absolute
    left: 0
    top: 50%
    transform: translateY(-50%)
    display: block
    content: ''
    width: 1px
    background: hsla(var(--primary-hsl), .2)
    height: 1.5rem

    @include lbg_desktop
      height: 1rem

  > .headerWrap__search svg
    width: .875rem
    height: auto

    @include lbg_desktop
      width: .75rem

    path
      fill: var(--primary)