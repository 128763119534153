.m62
  // reset outdated styling behaviour
  clear: both
  font-size: 16px

  // start styling
  display: grid
  grid-template-rows: 1fr auto
  align-items: end
  gap: 1.5rem

  @include lbg_desktop  
    gap: 3rem

  @include lbg_tablet
    margin-top: 0 !important
    min-height: calc( 100vh - 84px - var(--offset) )

  @include lbg_desktop
    min-height: calc( 100vh - 90px - var(--offset) )

  .fz-d1
    color: var(--primary)
    margin: 0 var(--offset)

    @include lbg_tablet
      margin: 0 0 0 var(--offsetWide)

    mark
      display: inline
      color: inherit
      padding: 0
      background: none
      font-family: 'Antonia H2'
      font-weight: 500

  .fz-h1,
  .fz-d1
    line-height: 1.1
    font-family: 'Antonia H1'

  .cta
    display: flex
    gap: .625rem
    align-items: center
    color: var(--primary)

  .lbg-arrow
    margin-bottom: -.125rem
    
    &::before
      background: var(--white)

    &::after
      border-color: var(--white)

  .m62__content
    display: grid
    gap: .5rem
    
    @include lbg_tablet
      min-height: 52.5vh
      grid-template-columns: 2fr 1fr

  .m62__posts,
  .m62__featured
    background: var(--primary)
    color: var(--white)
    position: relative

  .m62__featured
    background: var(--primary)
    display: flex
    flex-direction: column
    gap: 1rem
    justify-content: end
    overflow: hidden
    margin: 0 var(--offset)
    padding: $gutter*1.5 var(--offsetWide)
    min-height: 24rem

    @include lbg_tablet
      min-height: inital
      padding: $gutter*2.5 var(--offsetWide)
      margin: 0

    &:hover
      img
        transform: scale(1.05)

    .cta,
    .fz-h1
      position: relative
      z-index: 2
      color: var(--white)
      max-width: 700px
      text-wrap: balance
    
    .fz-h1
      @include to_tablet
        font-size: 1.75rem

    img,
    &::before
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      pointer-events: none
      user-select: none

    img
      opacity: .4
      object-fit: cover
      transition: transform .5s ease

    &::before
      content: '' 
      z-index: 1
      background: linear-gradient(0deg, hsla(var(--primary-hsl), 1) 0%, hsla(var(--primary-hsl), 0) 75%)

  .m62__posts
    display: flex
    flex-direction: column
    align-self: stretch
    justify-content: space-between
    margin: 0 var(--offset)
    padding: $gutter*1.5 0

    @include lbg_tablet
      margin: 0

    @include lbg_desktop
      padding: $gutter*2.5 0
    
    &, a, a span
      color: var(--white)

    li
      display: flex
      position: relative

    .spacer
      margin: $gutter var(--offsetWide)
      height: 1px
      background: hsla(var(--white-hsl), 1)
  
      @include lbg_desktop
        margin: 1rem var(--offsetWide)

      &:first-child
        display: none

    @include lbg_tablet
      li:nth-of-type(4),
      .spacer:nth-of-type(4)
        @media (max-height: 960px)
          display: none

    a
      display: flex
      flex-direction: column
      justify-content: center
      gap: .25rem
      padding: 0 var(--offsetWide)

      @include lbg_tablet
        gap: .75rem

      &:hover
        .cta
          opacity: 1

      .title
        max-width: 500px
        text-wrap: balance
        line-height: 1.25
        font-size: 1.125rem

        @include lbg_tablet
          font-size: 1.25rem

      .cta
        transition: opacity .25s ease
        opacity: .5