.m11 {
  width: 100%;

  .download {
    max-width: 947px;
    width: 100%;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      @include mobile {
        flex-direction: column;
      }
      &-load-more {
        margin-top: 20.5px;
      }
      &-item {
        &-thumb {
          margin-bottom: 24px;
          width: 299px;
          height: 224px;
          img {
            @extend %imgCropped;
          }
        }
        @include mobile {
          &--mb {
            margin-top: 40px;
          }
        }
        &--padding {
          padding-right: 60px;
        }
        @include tablet {
          padding-right: 20px;
          p {
            max-width: 300px;
          }
        }
        &-h3 {
          padding-bottom: 16px;
        }
        h3 {
          color: $black;
          font-family: 'Antonia H3';
          line-height: 24px;
          font-size: 20px;
          font-weight: 500;
        }
        p {
          padding: 16px 0px;
          font-size: 16px;
          line-height: 22px;
          color: $black;
        }
        &-report {
          a {
            display: flex;
            justify-content: space-between;
            border-bottom: rgba($pink, $alpha: 0.5) 1px solid;
            padding: 8px 5px 8px 2px;
            transition: $transition1;
          }

          &:hover {
            transition: $transition1;
            cursor: pointer;
            background-color: rgba($color: $pink, $alpha: 0.1);
            
            span {
              color: $darkGreen;
            }
            svg {
              color: $darkGreen;
            }

            &-right {
              svg {
                color: $darkGreen;
              }
              span {
                color: $darkGreen;
              }
            }
            .icon-external-link-small:hover {
              svg {
                color: $darkGreen;
              }
            }
          }

          &-title {
            font-size: 16px;
            line-height: 22px;
            color: $black;
            transition: $transition1;
            font-weight: 500;
          }

          &-right {
            transition: $transition1;
            display: flex;
            align-items: center;
            svg {
              transition: $transition1;
              width: 8px;
              height: 8px;
              color: $black;
            }
            &--filetype {
              font-size: 16px;
              line-height: 22px;
              color: $black;
              transition: $transition1;
              text-transform: uppercase;
            }
            &--mb {
              color: $pinkDark;
              padding: 0px 27px;
              transition: $transition1;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
