.m26 {
  width: 100%;

  .tags-area li {
    margin-bottom: 10px;
  }
  .pagination ul {
    margin-left: 0;
  }
  .tpcht-right {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $black;
      margin-right: 17px;
      text-transform: uppercase;
    }
  }

  .btn-secondary--outline {
    background-color: blue;
    margin: 0 auto;
    margin-top: 40px;
  }
  &__content__wrapper {
    padding: 0 80px;
    @include tablet {
      padding: 0;
    }
    @include mobile {
      padding: 0;
    }
  }

  &__breadcrumb {
    transition: $transition1;
    @include mobile {
      display: none;
    }
    @include tablet {
      display: none;
    }
    span {
      font-size: 14px;
      color: $pinkDark;
      transition: $transition1;
    }
    a {
      margin-right: 8px;
      transition: $transition1;
      color: $pinkDark;
      &:hover {
        span {
          color: $green;
          transition: $transition1;
        }
      }
    }
  }
  &__checkbox-margin {
    li {
      margin-bottom: 0px;
    }
  }
  .checkbox_btns {
    z-index: 6;
  }
  .custom_date_filter {
    display: flex;
    flex-direction: column;
    &-von {
      display: flex;
      align-items: center;
    }
    &-bis {
      display: flex;
      padding-left: 22px;
      margin-top: 8px;
    }
  }
  .dropdown-is--active {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: #f1f5ff;
    align-items: center;
    padding: 0 24px;
    position: relative;
    &.is--active{
      .m26__actions__dropdown{
        transform: rotate(45deg);
      }
    }
    &_items {
      display: flex;
      justify-content: space-between;
      margin-top: -65px;
    }
    span {
      cursor: pointer;
    }
    &_dropdown {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      display: block;
      width: 100%;
      padding: 74px 107px;
      background-color: #f1f5ff;
      border-radius: 3px;
      box-shadow: 0 4px 20px rgba(32, 48, 65, 0.21);
      position: absolute;
      z-index: 4;
      transition: $transition1;
      left: 0;
      top: 0;
    }
  }
  &__footer_pagination {
    margin-top: 17px;
  }
  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(184, 169, 188, 0.5);
    padding-bottom: 16px;
    
    @include lbg_tablet {
      margin-top: 32px;
    }

    &__dropdown {
      z-index: 6;
      transition: $transition1;
    }
    &-left {
      display: flex;
      align-items: center;
      @include mobile {
        justify-content: space-between;
        width: 100%;
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.42857em;
          color: #0032c3;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &__results {
      display: flex;
      align-items: center;
      margin-right: 40px;
      position: relative;
      &.is--active {
        .m26__actions__result_select {
          opacity: 1;
          visibility: visible;
          overflow: visible;
        }
      }
    }

    &__result {
      &_value {
        display: flex;
        align-items: center;
        h6 {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $blue;
          border: 1px solid $blue;
          border-radius: 4px;
          margin: 0 8px 0 0;
          padding: 0 4px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          letter-spacing: 0.01px;
          text-transform: uppercase;
          span {
            font-size: inherit;
            color: inherit;
          }
        }
      }

      &_select {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        width: calc(100% + 5px);
        padding: 24px;
        background-color: rgba($color: #fff, $alpha: 0.95);
        border-radius: 3px;
        box-shadow: 0 4px 20px rgba(32, 48, 65, 0.21);
        position: absolute;
        top: -24px;
        left: -24px;
        z-index: 4;
        transition: $transition1;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          letter-spacing: 0.01px;
          text-transform: uppercase;
          span {
            font-weight: normal;
          }
        }

        ul {
          margin-left: 14px;
          margin-top: 1px;
        }
        li {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          position: relative;
          &.is--active {
            color: $blue;
            &:after {
              content: '';
              width: 12px;
              height: 10px;
              background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='10px' viewBox='0 0 12 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Sprint-2-/-Abgabe' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='XLg_Suchergebnisse-with-results' transform='translate(-531.000000, -517.000000)' fill='%230032C3' fill-rule='nonzero'%3E%3Cg id='Group-32' transform='translate(391.000000, 497.000000)'%3E%3Cg id='checkmark' transform='translate(140.000000, 20.000000)'%3E%3Cpath d='M3.29079567,9.82407895 C3.08079567,9.64407895 3.05079567,9.34407895 3.22079567,9.13407895 C3.22079567,9.13407895 3.22079567,9.13407895 3.22079567,9.13407895 L10.7407957,0.174078947 C10.9107957,-0.0359210526 11.2207957,-0.0559210526 11.4307957,0.114078947 L11.4307957,0.114078947 C11.6407957,0.284078947 11.6607957,0.594078947 11.4907957,0.804078947 L3.97079567,9.76407895 C3.80079567,9.96407895 3.49079567,9.99407895 3.29079567,9.82407895 C3.29079567,9.82407895 3.29079567,9.82407895 3.29079567,9.82407895 Z' id='Rectangle_2_'%3E%3C/path%3E%3Cpath d='M0.170795668,5.34407895 C0.380795668,5.17407895 0.690795668,5.19407895 0.860795668,5.40407895 C0.860795668,5.40407895 0.860795668,5.40407895 0.860795668,5.40407895 L3.99079567,9.13407895 C4.16079567,9.34407895 4.14079567,9.64407895 3.93079567,9.82407895 C3.93079567,9.82407895 3.93079567,9.82407895 3.93079567,9.82407895 L3.93079567,9.82407895 C3.73079567,9.99407895 3.42079567,9.96407895 3.25079567,9.76407895 L3.25079567,9.76407895 L0.110795668,6.02407895 C-0.059204332,5.82407895 -0.029204332,5.51407895 0.170795668,5.34407895 C0.170795668,5.34407895 0.170795668,5.34407895 0.170795668,5.34407895 Z' id='Rectangle-2_2_'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
              background-size: 12px;
              background-repeat: no-repeat;
              position: absolute;
              right: -22px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
  }
  &__item {
    width: 100%;
    border-bottom: 1px solid rgba($pink, $alpha: 0.5);
    padding: 56px 0;
    .tags-area{
      margin-top: 10px;
    }
  }
  .radio_btns li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .news_box-disc_right {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    @include tablet {
      margin-top: 16px;
      padding-left: 0;
    }
  }

  .news_box {
    display: flex;
    align-items: center;
    // justify-content: center;
    &_left {
      display: flex;
      width: 100%;
      @include mobile {
        padding: 0 24px;
        flex-direction: column;
      }
    }
    @include tablet {
      flex-direction: unset;
    }
    @include mobile {
      flex-direction: unset;
      align-items: unset;
    }
    &:hover {
      .box-thumb img {
        opacity: 0.9;
      }
    }
    &-thumb {
      position: relative;
      // min-width: 190px;
      // max-width: 190px;
      min-width: 90px;
      max-width: unset;
      margin-right: 50px;
      height: unset;
      h2 {
        font-size: 48px;
        line-height: 48px;
        font-family: 'Antonia H2';
        color: $black;
      }
      label {
        color: $black;
        font-weight: 500;
      }
      @include tablet-big {
        min-width: unset;
        max-width: unset;
        height: unset;
      }
      @include tablet {
        min-width: unset;
        max-width: unset;
        height: unset;
      }
      @include mobile {
        min-width: unset;
        max-width: unset;
        height: unset;
      }
      img {
        @extend %imgCropped;
        transition: $transition1;
      }
    }
    &-dsc {
      width: 100%;
      max-width: 515px;
      padding: 0 24px;
      position: relative;
      margin-right: 132px;
      @include mobile {
        max-width: unset;
        margin-right: 0;
        padding: 0;
      }
      h3 {
        font-size: 20px;
        line-height: em(24, 20);
        color: #001141;
        font-family: 'Antonia H3';
        font-weight: 500;
        word-break: break-word;
        transition: color .25s ease;
        margin-bottom: .25rem;

        @include tablet-big {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
        }

        @media (hover: hover) {
          &:hover {
            color: $green
          }
        }
      }
      
      p {
        font-weight: normal;
        color: #001141;
        font-size: 14px;
        line-height: 1.42857em;
        @include mobile {
          line-height: 20px;
        }
      }

      .time,
      .location {
        color: $blue;
        font-weight: 500;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      @include tablet {
        position: relative;
        bottom: auto;
        margin-top: 0;
      }
      @include mobile {
        margin-top: 50px;
        bottom: -42px;
      }

      h5 {
        font-weight: normal;
        color: #001141;
        font-weight: normal;
        font-size: 14px;
        line-height: em(20, 14);
      }
      span {
        font-size: 14px;
        color: #001141;
        line-height: em(20, 14);
        font-weight: normal;
      }
    }
  }
  .event_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &-date {
      min-width: 84px;
      max-width: 84px;
      display: flex;
      flex-direction: column;
      h5 {
        font-family: 'Antonia H2';
        font-size: 64px;
        font-weight: 300;
        color: #001141;
        display: flex;
      }
      h6 {
        font-size: 14px;
        line-height: em(20, 14);
        font-weight: normal;
        color: $black;
        margin-top: -5px;
      }
    }
    &-dsc {
      width: 100%;
      max-width: 563px;
      padding: 0 24px;
      h3 {
        font-family: 'Antonia H3';
        font-size: 22px;
        line-height: em(26, 22);
        color: #001141;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;

      span {
        font-size: 14px;
        color: #001141;
        line-height: em(20, 14);
        font-weight: normal;
      }
    }
  }

  .team_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &:hover {
      .box-thumb img {
        opacity: 0.9;
      }
    }
    &-thumb {
      position: relative;
      // min-width: 190px;
      // max-width: 190px;
      max-width: unset;
      min-width: unset;
      height: 141px;
      @include tablet-big {
        min-width: 220px;
        max-width: 220px;
        height: 164px;
      }
      @include tablet {
        min-width: 208px;
        max-width: 208px;
        height: 158px;
      }
      @include mobile {
        min-width: 100px;
        max-width: 100px;
        height: 76px;
      }
      img {
        width: 85px;
        height: 110px;
        float: right;
        transition: $transition1;
      }
    }
    &-dsc {
      width: 100%;
      max-width: 455px;
      padding: 0 24px;
      font-size: 20px;
      line-height: em(24, 20);
      color: #001141;
      position: relative;
      @include tablet {
        padding-left: 0;
        margin-top: 15px;
      }
      @include mobile {
        padding: 0 0 0 10px;
      }
      h3 {
        font-size: 18px;
        line-height: em(24, 18);
        color: #001141;
        font-weight: 600;
        word-break: break-word;
        margin-bottom: 4px;
        @include mobile {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
        line-height: em(20, 16);
        font-weight: 500;
        color: $pinkDark;
        &:first-of-type {
          margin-bottom: 16px;
        }
        a {
          color: inherit;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
    }
  }
  .publication_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &-dsc {
      width: 100%;
      max-width: 648px;
      padding-right: 24px;
      color: #001141;
      @include tablet {
        padding-right: 0;
      }
      @include mobile {
        padding-right: 0;
      }
      h3 {
        font-family: 'Antonia H3';
        font-size: 20px;
        font-weight: 500;
        line-height: em(24, 20);
        color: #001141;
        margin-bottom: 8px;
        @include tablet-big {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
        }
      }
      span {
        font-size: 14px;
        font-weight: normal;
        line-height: em(20, 14);
        color: $black;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      span {
        font-size: 14px;
        color: #001141;
        line-height: em(20, 14);
        font-weight: normal;
      }
    }
  }
  .seite_box {
    display: flex;
    @include tablet {
      flex-direction: column;
    }
    &-dsc {
      width: 100%;
      max-width: 648px;
      padding-right: 24px;
      @include tablet {
        padding-right: 0;
      }
      @include mobile {
        padding-right: 0;
      }
      h3 {
        font-family: 'Antonia H3';
        font-size: 20px;
        font-weight: 500;
        line-height: em(24, 20);
        color: #001141;
        margin-bottom: 8px;
        @include tablet-big {
          font-size: 18px;
        }
        @include tablet {
          font-size: 18px;
        }
        @include mobile {
          font-size: 16px;
        }
      }
      p {
        font-size: 18px;
        font-weight: normal;
        line-height: em(24, 18);
        color: $black;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      h5 {
        font-size: 16px;
        font-weight: normal;
        line-height: em(22, 16);
        text-transform: uppercase;
        color: $black;
      }
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 60px;
    justify-content: space-between;
    background-color: #dee7ff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #b8a9bc;
    margin-top: 96px;
    @include mobile {
      flex-direction: column;
      padding: 32px 24px;
    }
    &-mob {
      display: flex;
      justify-content: space-between;
      @include mobile {
        width: 100%;
        margin-bottom: 16px;
        align-items: center;
      }
    }
    p {
      max-width: 526px;
      width: 100%;
      font-size: 18px;
      color: $black;
      line-height: 24px;
      @include mobile {
        font-size: 16px;
        max-width: unset;
      }
    }
    h2 {
      max-width: 204px;
      width: 100%;
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
      font-family: 'Antonia H3';
      color: $blue;
      @include mobile {
        font-size: 24px;
        line-height: 28px;
        max-width: unset;
      }
    }
  }
}
