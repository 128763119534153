.m43
  .fz-h2,
  .fz-h5
    color: $blue

  .lbg-grid
    grid-template-columns: repeat(12, 1fr)
    margin: 3rem 0 0
    
    @include lbg_tablet
      grid-auto-rows: 0fr 0fr 1fr


  .m43__cta
    margin-top: 2rem

  .m43__cover
    grid-column: span 12

    @include lbg_tablet
      grid-column: 9 / span 3
      grid-row: 1

    img
      max-width: 130px

      @include lbg_tablet
        max-width: 205px

    a
      display: block
      line-height: 0

  .m43__attachments,
  .m43__extra-links
    grid-column: span 12

    @include lbg_tablet
      grid-column: 9 / span 4
  
  .m43__content
    color: $black
    grid-column: span 12
    margin: 0 0 2rem 0

    @include lbg_tablet
      grid-column: span 7
      grid-row: span 3
      margin: 0

    .m43__meta
      margin: 0 0 2rem 0
      display: grid
      grid-template-columns: 1fr
      gap: .75rem

      @include lbg_mobile
        display: grid
        gap: $gutter
        grid-template-columns: 1fr 1fr
        margin: 0 0 3rem 0

      div > div + div
        margin-top: .75rem 

    .authors,
    .details,
    .publishers
      strong
        display: block

      span
        &::after
          content: ', '

        &:last-child::after
          content: ''