// m22 & m55 share same styling

.m22 {
  .publication-slider2 {
    max-width: 1055px;
    margin: 0 auto;
  }

  .publication-slider-item {
    &-inner {
      padding: 48px 0;
      border-bottom: 1px solid #b8a9bc;

      @include tablet {
        &:nth-child(1) {
          padding-top: 0;
        }
      }
      @include mobile {
        padding: 40px 0;
        &:nth-child(1) {
          padding-top: 0;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    &-left {
      display: grid;
      grid-template-columns: 8rem 1fr 20rem;
      gap: 2rem;

      @include tablet {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      @include mobile {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .tags-area {
        margin-top: .125rem;
      }

      .time,
      .location {
        font-weight: 500;
        color: $blue;
      }

      h3 {
        font-family: 'Antonia H3';
        font-weight: 500;
        color: #001141;
        font-size: 20px;
        line-height: em(24, 20);
        margin-bottom: .5rem;
        transition: color .25s ease;

        @include desktop {
          max-width: 500px;
          margin-right: 130px;
        }
        @include tablet-big {
          font-size: 18px;
        }
        @include mobile {
          font-size: 18px;
          line-height: 22px;
        }
        @include tablet {
          font-size: 18px;
        }

        @media (hover: hover) {
          &:hover {
            color: $green
          }
        }
      }
      p {
        font-weight: normal;
        color: #001141;
        font-size: 14px;
        line-height: 1.42857em;
        @include mobile {
          line-height: 20px;
        }
      }
    }
    &-right {
      padding-left: 116px;
      @include mobile {
        padding-left: 0;
      }
      @include tablet {
        padding-left: 0;
        width: 70%;
      }
      p {
        color: #001141;
        font-weight: 500;
        font-size: 14px;
        line-height: em(20, 14);
      }
    }
  }
  .event-box-date {
    float: left;
    margin-right: 60px;
    h5 {
      font-size: 64px;
      color: #001141;
      font-weight: 300;
      font-family: 'Antonia H2';
      display: flex;
      @include mobile {
        font-size: 56px;
      }
      @include tablet {
        font-size: 56px;
      }
      .month {
        font-weight: normal;
        font-size: 14px;
        line-height: em(20, 14);
        margin-top: 3px;
        margin-left: 5px;
        @include mobile {
          font-size: 12px;
        }
        @include tablet {
          font-size: 14px;
        }
      }
    }
  }
}
