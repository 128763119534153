.m67_camp
  position: relative
  line-height: 1.25
  padding: 0 $gutter 1.25rem $gutter  

  @include lbg_tablet
    padding: 0 $gutter $gutter  

  &.open
    .m67_camp_body
      grid-template-rows: 1fr

    .m67_camp_toggle::after
      transform: translateY(.625rem) rotate(225deg)

.m67_camp + .m67_camp
  border-top: 1px solid hsla(var(--primary-hsl), .2)

.m67_camp_toggle
  min-width: 3rem
  display: grid
  align-items: center
  justify-items: end
  cursor: pointer
  align-self: stretch

  &::after
    content: ''
    width: .725rem
    aspect-ratio: 1/1
    border-right: 1px solid var(--primary)
    border-bottom: 1px solid var(--primary)
    transform: translateY(.25rem) rotate(45deg)

.m67_camp_head
  h2
    display: grid
    gap: 1.5rem
    grid-template-columns: auto 1fr 
    align-items: end
    font-family: 'Antonia H1'
    color: var(--primary)
    height: 2.75rem

    @include lbg_tablet
      height: 3.5rem

  p,
  ul
    padding-right: 1.5rem

  p
    color: var(--primary)
    margin-top: .25rem

  ul
    display: flex
    flex-wrap: wrap
    gap: 1.25rem
    color: hsla(var(--color-hsl), .7)
    
.m76_images
  margin-bottom: .75rem
  display: grid
  align-items: end
  gap: .5rem
  grid-template-columns: repeat(3, 1fr)

  &[data-count="1"]
    grid-template-columns: 1fr

    a
      aspect-ratio: 3/2

  &[data-count="2"],
  &[data-count="4"]
    grid-template-columns: repeat(2, 1fr)

  img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
    display: block
    transition: opacity .4s ease, filter .4s ease

  span
    position: absolute
    bottom: 0
    right: 0
    padding: .25rem
    background: rgba(0,0,0,0.5)
    white-space: nowrap
    font-size: 11px
    color: #fff
    transition: opacity .4s ease

  svg
    position: relative
    z-index: 1
    width: 1.25rem
    height: auto
    opacity: 0
    transition: opacity .4s ease

    path
      fill: #fff

  a
    position: relative
    display: grid
    place-items: center
    aspect-ratio: 4/3
    background: var(--color)

    &:hover
      svg
        opacity: 1

      span
        opacity: 0

      img
        opacity: .3
        filter: grayscale(1)

.m67_camp_body
  display: grid
  grid-template-rows: 0fr
  transition: grid-template-rows .4s $easeInOutQuart

  .m67_camp_body_content
    overflow: hidden
    display: grid
    gap: 1rem

    & > *:first-child
      margin-top: 1.25rem

    p,
    dd
      color: var(--primary)

      @include lbg_mobile
        font-size: 19px

      &.small
        @include lbg_mobile
          font-size: 16px
          color: var(--color)
          
      span
        position: relative
        cursor: default
        font-size: 17px
        user-select: none

        &:hover cite
          display: block

        cite
          position: absolute
          bottom: calc( 100% + .5rem )
          left: 0
          background: #fff
          border-radius: 4px
          padding: .5rem
          box-shadow: 0 0 10px 0 rgba(0,0,0,0.1)
          color: var(--color)
          font-size: 14px
          min-width: 200px
          display: none
          text-align: center
          text-wrap: balance

        &[data-position="center"] cite
          left: 50%
          transform: translateX(-50%)

        &[data-position="right"] cite
          left: auto
          right: 0

    p a
      color: inherit
      text-decoration: underline

    p a,
    dl a
      word-break: break-all

    dl
      dt
        color: hsla(var(--color-hsl), .7)

      dd
        margin-top: .125rem

        a
          color: inherit
          text-decoration: underline

        ul 
          list-style: dash

          li + li
            margin-top: .5rem

      dd + dt
        margin-top: 1rem

