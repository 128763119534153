#cookie-notice {
  color: #fff;
  font-family: inherit;
  background: $black;
  padding: 64px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  margin: 0;
  opacity: 0;
  z-index: 1000000;
  transition: opacity 0.3s ease;
  @include mobile {
    padding: 32px 0;
  }

  h3{
    font-family: 'Antonia H3';
    font-size: 22px;
    line-height: em(26,22);
    color: #fff;
    margin-bottom: 24px;
    @include mobile {
      font-size: 20px;
      line-height: em(26, 20);
      margin-bottom: 16px;
    }
  }
  
  p {
    font-size: 16px;
    line-height: em(22, 16);
    font-weight: 400;
    color: #fff;
    @include mobile {
      font-size: 14px;
      line-height: em(20, 14);
    }
  }
  .cookie-notice-buttons{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    width: auto;

    @include mobile {
      width: 100%;
      margin-top: 24px;
      flex-direction: column;
      justify-content: stretch;
    }
  }

  .btn-accept,
  .btn-manage {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 16px 14px 16px 22px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: $transition1;
    &__icon {
      width: 20px;
      height: 20px;
      margin-left: 12px;
      margin-right: 4px;
    }

    &:hover {
      color: $black;
      background-color: #fff;
    }
    &:focus {
      color: $black;
      background-color: #fff;
    }
  }
  .btn-manage {
    padding: 16px 22px;
    @include mobile {
      width: 100%;
      justify-content: center;
    }
  }

  .btn-accept {
    margin-left: 24px;
    @include mobile {
      margin-left: 0;
      margin-top: 16px;
      justify-content: center;
      width: auto;
    }
  }
}

#cookie-request {
  &.background {
    background: $blueLight;
  }

  #consent-display {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    padding: 16px 24px;
    cursor: pointer;
  }
}