.m02 {
  .section-title-small {
    padding-bottom: 0;
    margin-bottom: 40px;
  }

  .column-4 {
    .team-wrapper-item {
      background-image: url('../images/m02_wrapper-background.png') no-repeat;

      background-size: cover;
      h2 {
        background-size: 48px;
      }
      p {
        font-size: 18px;
        line-height: em(24, 18);
      }
    }
  }
  .teaser-box-holder {
    article {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 46px 40px;
      min-height: 331px;
      background: url(../images/bg.svg) no-repeat 0 0 #f6f3f7;
      background-size: cover;
      border-bottom: 1px solid #b8a9bc;
      box-shadow: 0 0 0 0 rgba(#7e6e7e, 0.8);
      transition: box-shadow 0.1s ease-in-out !important;
      &:hover {
        box-shadow: 0 0 13px 0 rgba(#7e6e7e, 0.8);
      }

      @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      @include tablet-big {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      @include tablet {
        margin-bottom: 20px;
        min-height: 280px;
      }
      @include mobile {
        margin-bottom: 20px;
        padding: 40px 24px;
        background-size: contain;
      }
      h2 {
        font-size: 36px;
        line-height: em(40, 36);
        color: #0032c3;
        background: url(../images/arrow-outline-right-blue.svg) no-repeat 101%
          center;
        background-size: 50px;
        margin-bottom: 30px;
        font-weight: 500;
        font-family: 'Antonia H2';
        padding-right: 50px;

        @include tablet-big {
          font-size: 34px;
        }
        @include mobile {
          font-size: 30px;
          background-size: 35px;
        }
      }
      p {
        line-height: em(24, 18);
        color: #00289b;
        font-size: 18px;
        font-weight: normal;
        @include desktop {
          max-width: 78%;
        }
        @include mobile {
          font-size: 16px;
        }
        @include tablet {
          max-width: 80%;
        }
      }
    }
  }

  // background: white;
  // @include tablet {
  //   padding: $t-vs 0;
  //   padding-bottom: 210px;
  // }
  // @include mobile {
  //   padding: $m-vs 0 160px;
  // }

  .team-wrapper {
    &-item {
      height: 249px;
      padding: 40px 30px;
      border-radius: 3px;
      background: #0032c3;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include tablet-big {
        margin-bottom: 13px;
        height: 210px;
      }
      @include tablet {
        margin-bottom: 13px;
        height: 210px;
      }
      a {
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        color: #fff;
        line-height: em(24, 18);
      }
      @include mobile {
        margin-bottom: 12px;
      }
      h2 {
        font-size: 28px;
        line-height: em(32, 28);
        color: #fff;
        font-family: 'Antonia H3';
        font-weight: 500;
        padding-right: 42px;
        background: url(../images/arrow-outline-right.svg) no-repeat 101% -6px;
        background-size: 45px;

        @include tablet-big {
          font-size: 34px;
        }
        @include mobile {
          font-size: 30px;
          background-size: 35px;
        }
        p {
          font-size: 18px;
          line-height: em(24, 18);
          color: #fff;
        }
      }
    }
  }
}
