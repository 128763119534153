.m67_filter
  display: grid
  border-bottom: 1px solid hsla(var(--primary-hsl), .2)
  transition: grid-template-rows .4s $easeInOutQuart
  grid-template-rows: 1fr
  min-height: 4rem

  &[data-state="closed"]
    grid-template-rows: 0fr

    & > .m67_filter_toggle
      transition-delay: .4s
      opacity: 1
      pointer-events: all

  &[data-state="all"]
    .m67_all_filters
      grid-template-rows: 1fr

    .m67_filter_more
      span.plus
        transform: rotate(45deg)

.m67_results
  display: flex
  align-items: center
  font-size: 13px
  color: var(--primary)

.m67_filter_reset
  width: 1.5rem
  aspect-ratio: 1/1
  border-radius: 50%
  background: var(--primary)
  cursor: pointer
  margin-left: .5rem
  place-items: center
  display: none

  &.visible
    display: grid

  &::after,
  &::before
    content: ''
    grid-area: 1/1
    width: 50%
    height: 1px
    background: #fff
    transform: rotate(45deg)

  &::before
    transform: rotate(-45deg)

.m67_filter_toggle
  opacity: 0
  padding: 0 $gutter
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 4rem
  transition: opacity .25s ease
  cursor: pointer
  pointer-events: none
  user-select: none
  display: flex
  align-items: center
  justify-content: space-between
  text-transform: uppercase
  color: var(--primary)
  font-size: .875rem
  letter-spacing: .01em

  &::after
    content: ''
    width: .725rem
    aspect-ratio: 1/1
    border-right: 1px solid var(--primary)
    border-bottom: 1px solid var(--primary)
    transform: translate(-.125rem,-0.1rem) rotate(45deg)

.m67_filter_content
  overflow: hidden
  padding: 0 $gutter
  display: grid
  gap: 1.25rem .5rem
  grid-template-columns: repeat(3, 1fr)

.m67_all_filters
  grid-column: 1 / span 3
  display: grid
  grid-template-rows: 0fr
  transition: grid-template-rows .4s $easeInOutQuart

.m67_all_filters_content
  overflow: hidden
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: .5rem

  &::after
    content: ''
    height: $gutter

.m67_filter_search
  margin-top: $gutter
  grid-column: 1 / span 3
  display: grid
  align-items: center

  & > *
    grid-area: 1/1

  & > div
    justify-self: flex-end

  & > input
    background: none
    border-radius: 0
    padding-right: 5.5rem

.m67_filter_more
  user-select: none

  span.mobile
    @include lbg_desktop
      display: none

  span.desktop
    @include to_desktop
      display: none

  span.plus
    transition: transform .25s ease
    display: inline-block
    margin-left: .25rem
    font-size: 18px

.m67_slider
  grid-column: 1 / span 3