.m38 {
  width: 300px !important;
  float: right !important;
  @include desktop {
    margin-bottom: 50px;
  }
  @include mobile {
    width: auto!important;
    float: none!important;
  }

  @include tablet {
    width: auto!important;
    float: none!important;
  }
  
  @include tablet-big {
    width: auto!important;
    float: none!important;
  }
}
