.m23 {
  width: 100%;
  
  .section-title-small {
    @include tablet {
      margin-bottom: 16px;
    }
    @include mobile {
      margin-bottom: 8px;
    }
  }
  .releases-list {
    width: 100%;
    padding: 0 105px;
    @include tablet {
      padding: 0;
    }
    @include mobile {
      padding: 0;
    }
    &__item {
      padding: 24px 0;
    }
    &__title {
      padding-right: 260px;
      @include tablet {
        padding-right: 0;
      }
      @include mobile {
        padding-right: 0;
      }
    }
  }
  &__list {
    max-height: 380px;
    overflow: auto;
    margin-right: -30px;
    padding-right: 30px;
    @include mobile {
      max-height: 75%;
    }
    .releases-list__item {
      @include tablet {
        flex-direction: column;
      }
    }
    .releases-list__title {
      @include tablet {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  &__search {
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
    &__left {
      width: 75%;
      @include mobile {
        width: 100%;
      }
    }
    &__right {
      width: 25%;
      padding-left: 60px;
      @include mobile {
        width: 100%;
        padding-left: 0;
        margin-top: 23px;
      }
      @include tablet {
        padding-left: 50px;
      }
    }
    &__title {
      display: block;
      text-transform: uppercase;
      font-size: 14px;
      line-height: em(20, 14);
      font-weight: 500;
      color: $black;
      letter-spacing: 0.01px;
      margin-bottom: 8px;
    }
    &__icon {
      margin-right: 10px;
      color: $black;
    }
    &__input {
      width: 100%;
      padding: 10px 12px;
      box-shadow: 0 1px 10px $pink;
      background-color: #fff;
      border-radius: 3px;
      display: flex;
      align-items: center;
      input[type='text'] {
        width: calc(100% - 30px);
        padding: 0;
        outline: 0;
        border: 0;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
  &__btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
    @include tablet {
      margin-top: 40px;
    }
    @include mobile {
      margin-top: 40px;
    }
    &:not(:hover) {
      background-color: transparent;
    }
    .btn-primary--outline__icon {
      // background-image: none !important;
      // svg {
      //   color: inherit;
      // }
    }
  }
}
