.m15 {
  width: 100%;
  .label-radio {
    color: $black;
  }
  .news_box-dsc {
    @include mobile {
      padding: 0;
    }
  }
  .search__header {
    height: 360px;
    padding-bottom: 0;
    @include mobile {
      height: 286px;
    }
    &__tabs {
      margin-top: 64px;
      @include mobile {
        margin-top: 81px;
      }
      ul {
        display: flex;
        @include mobile {
          overflow: scroll;
        }
      }
      li {
        margin-right: 2px;
        &.is--disabled {
          a {
            cursor: auto;
            color: rgba($blueDark, $alpha: 0.4);
            background-color: rgba($color: #fff, $alpha: 0.5);
          }
        }
        &.is--active {
          a {
            background-color: #fff;
            color: #008576;
          }
        }
      }
      a {
        display: flex;
        padding: 13px 16px;
        background-color: rgba($color: #fff, $alpha: 0.5);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: 16px;
        font-weight: 500;
        line-height: em(18, 16);
        color: $blueDark;
        transition: $transition1;
        @include mobile {
          white-space: nowrap;
        }
        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.7);
        }
      }
    }
  }
  &__footer_pagination {
    margin-top: 17px;
  }
  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(184, 169, 188, 0.5);
    margin-top: 65px;
    padding-bottom: 16px;
    &-left {
      display: flex;
      align-items: center;
      @include mobile {
        justify-content: space-between;
        width: 100%;
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.42857em;
          color: #0032c3;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &__results {
      display: flex;
      align-items: center;
      margin-right: 40px;
      position: relative;
      &.is--active {
        .m15__actions__result_select {
          opacity: 1;
          visibility: visible;
          overflow: visible;
        }
      }
    }

    &__result {
      &_value {
        display: flex;
        align-items: center;
        h6 {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $blue;
          border: 1px solid $blue;
          border-radius: 4px;
          margin: 0 8px;
          padding: 0 4px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          letter-spacing: 0.01px;
          text-transform: uppercase;
          span {
            font-weight: normal;
          }
        }
      }

      &_select {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        width: calc(100% + 5px);
        padding: 24px;
        background-color: rgba($color: #fff, $alpha: 0.95);
        border-radius: 3px;
        box-shadow: 0 4px 20px rgba(32, 48, 65, 0.21);
        position: absolute;
        top: -24px;
        left: -24px;
        z-index: 4;
        transition: $transition1;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          letter-spacing: 0.01px;
          text-transform: uppercase;
          span {
            font-weight: normal;
          }
        }

        ul {
          margin-left: 14px;
          margin-top: 1px;
        }
        li {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          font-size: 14px;
          font-weight: 500;
          line-height: em(20, 14);
          color: $black;
          position: relative;
          &.is--active {
            color: $blue;
            &:after {
              content: '';
              width: 12px;
              height: 10px;
              background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='10px' viewBox='0 0 12 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Sprint-2-/-Abgabe' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='XLg_Suchergebnisse-with-results' transform='translate(-531.000000, -517.000000)' fill='%230032C3' fill-rule='nonzero'%3E%3Cg id='Group-32' transform='translate(391.000000, 497.000000)'%3E%3Cg id='checkmark' transform='translate(140.000000, 20.000000)'%3E%3Cpath d='M3.29079567,9.82407895 C3.08079567,9.64407895 3.05079567,9.34407895 3.22079567,9.13407895 C3.22079567,9.13407895 3.22079567,9.13407895 3.22079567,9.13407895 L10.7407957,0.174078947 C10.9107957,-0.0359210526 11.2207957,-0.0559210526 11.4307957,0.114078947 L11.4307957,0.114078947 C11.6407957,0.284078947 11.6607957,0.594078947 11.4907957,0.804078947 L3.97079567,9.76407895 C3.80079567,9.96407895 3.49079567,9.99407895 3.29079567,9.82407895 C3.29079567,9.82407895 3.29079567,9.82407895 3.29079567,9.82407895 Z' id='Rectangle_2_'%3E%3C/path%3E%3Cpath d='M0.170795668,5.34407895 C0.380795668,5.17407895 0.690795668,5.19407895 0.860795668,5.40407895 C0.860795668,5.40407895 0.860795668,5.40407895 0.860795668,5.40407895 L3.99079567,9.13407895 C4.16079567,9.34407895 4.14079567,9.64407895 3.93079567,9.82407895 C3.93079567,9.82407895 3.93079567,9.82407895 3.93079567,9.82407895 L3.93079567,9.82407895 C3.73079567,9.99407895 3.42079567,9.96407895 3.25079567,9.76407895 L3.25079567,9.76407895 L0.110795668,6.02407895 C-0.059204332,5.82407895 -0.029204332,5.51407895 0.170795668,5.34407895 C0.170795668,5.34407895 0.170795668,5.34407895 0.170795668,5.34407895 Z' id='Rectangle-2_2_'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
              background-size: 12px;
              background-repeat: no-repeat;
              position: absolute;
              right: -22px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
  }
  &__sidebar {
    float: left;
    width: 280px;
  }
  &__results {
    float: left;
    width: calc(100% - 280px);
    padding-left: 45px;
    @include mobile {
      padding-left: 0;
      width: 100%;
    }
    @include tablet {
      padding-left: 0;
      width: 100%;
      .news_box {
        display: flex;
        flex-direction: row;
      }
      .team_box {
        display: flex;
        flex-direction: row;
        &-dsc {
          margin-top: 0px;
        }
        &-thumb {
          margin-right: 23px;
        }
      }
      .publication_box {
        flex-direction: row;
      }
      .event_box {
        flex-direction: row;
      }
      .seite_box {
        flex-direction: row;
      }
    }
    @include mobile {
      .publication_box,
      .seite_box {
        flex-direction: column;
      }
    }
  }
  &__item {
    width: 100%;
    border-bottom: 1px solid rgba($pink, $alpha: 0.5);
    padding: 56px 0;
  }
  .radio_btns li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__noresults {
    width: 100%;
    margin-top: 70px;
    h2 {
      font-family: 'Antonia H2';
      font-size: 36px;
      line-height: em(40, 36);
      font-weight: 500;
      color: $black;
    }
  }
}
.search_filter {
  width: 100%;
  margin-top: 65px;
  &__header {
    width: 100%;
    h5 {
      font-size: 14px;
      font-weight: 500;
      line-height: em(20, 14);
      letter-spacing: 0.01px;
      color: $black;
      // text-transform: uppercase;
      margin-bottom: 16px;
    }
  }
  &__main {
    width: 100%;
  }
  .custom_date_filter {
    display: flex;
    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: em(18, 16);
      color: $black;
      &:not(.label-radio) {
        margin: 0 5px;
      }
      &.label-radio {
        margin-right: 5px;
      }
    }
    input[type='text'] {
      width: 98px;
      outline: 0;
      border: 1px solid $black;
      border-radius: 3px;
      padding: 4px 7px;

      font-size: 16px;
      font-weight: 500;
      color: $black;

      transition: $transition1;
      @include placeholder {
        font-size: 16px;
        font-weight: 500;
        color: rgba($color: #000000, $alpha: 0.3);
      }
      &:focus {
        border-color: $blue;
        box-shadow: 0 0 5px rgba(0, 17, 65, 25);
      }
    }
  }
}

.news_box {
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  &:hover {
    .box-thumb img {
      opacity: 0.9;
    }
  }
  &-thumb {
    position: relative;
    min-width: 190px;
    max-width: 190px;
    height: 141px;
    @include tablet-big {
      min-width: 220px;
      max-width: 220px;
      height: 164px;
    }
    @include tablet {
      min-width: 208px;
      max-width: 208px;
      height: 158px;
    }
    @include mobile {
      min-width: 100px;
      max-width: 100px;
      height: 76px;
    }
    img {
      @extend %imgCropped;
      transition: $transition1;
    }
  }
  &-dsc {
    width: 100%;
    max-width: 455px;
    padding: 0 24px;
    position: relative;
    line-height: 1.25;
    color: #001141;

    h3 {
      font-size: 20px;
      line-height: em(24, 20);
      color: #001141;
      font-family: 'Antonia H3';
      font-weight: 500;
      word-break: break-word;
      transition: color .25s ease;
      margin-bottom: .25rem;
      
      @include tablet-big {
        font-size: 18px;
      }
      @include tablet {
        font-size: 18px;
      }
      @include mobile {
        font-size: 16px;
        padding: 0 !important;
      }
      @media (hover: hover) {
        &:hover {
          color: $green
        }
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    @include tablet {
      position: relative;
      bottom: auto;
      margin-top: 8px;
    }
    @include mobile {
      margin-top: 8px;
      bottom: -42px;
    }
    &-wrapper {
      display: flex;
      width: 100%;
      
      @include mobile {
        flex-direction: column;
        margin-left: 16px;
      }
    }

    h5 {
      font-weight: normal;
      color: #001141;
      font-weight: normal;
      font-size: 14px;
      line-height: em(20, 14);
    }
    span {
      font-size: 14px;
      color: #001141;
      line-height: em(20, 14);
      font-weight: normal;
    }
  }
}

.news_box-thumb.books_box-thumb img {
  object-fit: contain;
  object-position: right top;
}

.event_box {
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  &-date {
    min-width: 84px;
    max-width: 84px;
    display: flex;
    flex-direction: column;
    h5 {
      font-family: 'Antonia H2';
      font-size: 64px;
      font-weight: 300;
      color: #001141;
      display: flex;
    }
    h6 {
      font-size: 14px;
      line-height: em(20, 14);
      font-weight: normal;
      color: $black;
      margin-top: -5px;
    }
  }
  &-dsc {
    width: 100%;
    max-width: 563px;
    padding: 0 24px;
    h3 {
      font-family: 'Antonia H3';
      font-size: 22px;
      line-height: em(26, 22);
      color: #001141;
    }
    @include mobile {
      padding: 0 !important;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;

    span {
      font-size: 14px;
      color: #001141;
      line-height: em(20, 14);
      font-weight: normal;
    }
    &-wrapper {
      display: flex;
      width: 100%;

      @include mobile {
        flex-direction: column;
        margin-left: 16px;
      }
    }
  }
}

.team_box {
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  &:hover {
    .box-thumb img {
      opacity: 0.9;
    }
  }
  &-thumb {
    position: relative;
    min-width: 190px;
    max-width: 190px;
    height: 141px;
    @include tablet-big {
      min-width: 220px;
      max-width: 220px;
      height: 164px;
    }
    @include tablet {
      min-width: 208px;
      max-width: 208px;
      height: 158px;
    }
    @include mobile {
      min-width: 100px;
      max-width: 100px;
      height: 76px;
    }
    img {
      width: 85px;
      height: 110px;
      float: right;
      object-fit: cover;
      transition: $transition1;
    }
  }
  &-dsc {
    width: 100%;
    max-width: 455px;
    padding: 0 24px;
    font-size: 20px;
    line-height: em(24, 20);
    color: #001141;
    position: relative;
    @include tablet {
      padding-left: 0;
      margin-top: 15px;
    }
    @include mobile {
      padding: 0 0 0 10px;
    }
    h3 {
      font-size: 18px;
      line-height: em(24, 18);
      color: #001141;
      font-weight: 600;
      word-break: break-word;
      margin-bottom: 4px;
      @include mobile {
        font-size: 16px;
      }
    }
    p {
      font-size: 16px;
      line-height: em(20, 16);
      font-weight: 500;
      color: $pinkDark;
      &:first-of-type {
        margin-bottom: 16px;
      }
      a {
        color: inherit;
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    &-wrapper {
      display: flex;
      width: 100%;
      @include mobile {
        flex-direction: column;
        margin-left: 16px;
      }
    }
  }
}
.publication_box {
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  &-dsc {
    width: 100%;
    max-width: 648px;
    padding-right: 24px;
    color: #001141;
    @include tablet {
      padding-right: 0;
    }
    @include mobile {
      padding-right: 0;
    }
    h3 {
      font-family: 'Antonia H3';
      font-size: 20px;
      font-weight: 500;
      line-height: em(24, 20);
      color: #001141;
      margin-bottom: 8px;
      @include tablet-big {
        font-size: 18px;
      }
      @include tablet {
        font-size: 18px;
      }
      @include mobile {
        font-size: 16px;
      }
    }
    span {
      font-size: 14px;
      font-weight: normal;
      line-height: em(20, 14);
      color: $black;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    span {
      font-size: 14px;
      color: #001141;
      line-height: em(20, 14);
      font-weight: normal;
    }
    &-wrapper {
      display: flex;
      width: 100%;
      @include mobile {
        flex-direction: column;
        margin-left: 16px;
      }
    }
  }
}
.seite_box {
  display: flex;
  @include tablet {
    flex-direction: column;
  }
  &-dsc {
    width: 100%;
    max-width: 648px;
    padding-right: 24px;
    @include tablet {
      padding-right: 0;
    }
    @include mobile {
      padding-right: 0;
    }
    h3 {
      font-family: 'Antonia H3';
      font-size: 20px;
      font-weight: 500;
      line-height: em(24, 20);
      color: #001141;
      margin-bottom: 8px;
      @include tablet-big {
        font-size: 18px;
      }
      @include tablet {
        font-size: 18px;
      }
      @include mobile {
        font-size: 16px;
      }
    }
    p {
      font-size: 18px;
      font-weight: normal;
      line-height: em(24, 18);
      color: $black;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 16px;
      font-weight: normal;
      line-height: em(22, 16);
      text-transform: uppercase;
      color: $black;
    }
  }
}
