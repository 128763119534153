.choices
  margin-bottom: 0

  &::after
    border-width: 4px !important
    border-color: $blue transparent transparent !important

.choices + .choices
  margin-top: .25rem

.choices__inner
  border: 0
  font-size: inherit
  background: #fff
  display: flex
  align-items: center
  padding: .25rem .5rem  !important
  min-height: 36px

.choices__list--single
  padding: 0 16px 0 0

  .choices__item
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.choices__list
  .choices__item
    line-height: 1.2
    word-break: break-word
    padding-right: 2rem

.choices__list--dropdown .choices__item.choices__item--selectable, 
.choices__list[aria-expanded] .choices__item.choices__item--selectable
  padding-right: 2rem

.choices__placeholder
  opacity: 1

.choices__item--selectable.is-highlighted
  background: #f1f5ff !important

.choices__list--dropdown
  top: 0 !important
  border: none !important
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.10)

input.choices__input
  border-radius: 0px !important
  color: $blue !important

  &:focus
    outline: 0 !important