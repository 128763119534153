.m46 {
  .section-title-small {
    margin-bottom: 2rem;
  }

  .gallery {
    display: grid;
    gap: calc($gutter/2);
    grid-template-columns: 1fr 1fr;
    
    @media only screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 990px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .99;
      transition: all .4s ease;
    }

    a {
      display: block;
      padding-top: 67%;
      position: relative;
      overflow: hidden;
      cursor: zoom-in;
    }

    @media (hover: hover) {
      a:hover img {
        transform: scale(1.075);
        opacity: .85;
      }
    }
  }

  .toggle span:first-child {
    display: none
  }

  .toggle span:last-child {
    display: block
  }

  .gallery.collapsed {
    a {
      display: none
    }

    a:nth-child(-n+6) {
      display: block
    }

    @media only screen and (min-width: 990px) {
      a:nth-child(-n+8) {
        display: block
      }
    }

    @media only screen and (min-width: 1200px) {
      a:nth-child(-n+10) {
        display: block
      }
    }

    & + .toggle span:first-child {
      display: block
    }

    & + .toggle span:last-child {
      display: none
    }
  }

  .btn-primary {
    justify-content: center;
    margin: 3rem auto 0 auto;
  }
}
