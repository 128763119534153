.rs-container
    height: 20px
    margin: 0 30px

    .rs-scale
        display: none

    .rs-bg,
    .rs-selected
        top: 6px
        height: 6px

    .rs-bg
        background: #fff
        border-radius: 5px
        left: -26px
        width: calc(100% + 26px + 26px )
        border: 1px solid hsla(var(--primary-hsl), .5)

    .rs-selected
        background: var(--primary)

    .rs-pointer
        height: 26px
        margin-top: -5px
        width: 60px
        border-radius: 13px
        box-shadow: none
        background: var(--white)
        border: 1px solid hsla(var(--primary-hsl), .5)

        &::after,
        &::before
            display: none

    .rs-tooltip
        top: calc( 50% + .5px )
        bottom: 0
        line-height: 1
        transform: translate(-50%, -50%)
        font-size: 11px
        user-select: none
        pointer-events: none
        color: var(--primary)