.m07e {
  margin-bottom: 150px;

  @include tablet {
    margin-top: 90px;
  }
  @include mobile {
    margin-bottom: 0;
    margin-top: 80px;
  }
  .m07e-section {
    padding-top: 80px;
    @include tablet-big {
      padding: $t-vs 0;
    }
    @include tablet {
      padding: 120px 0;
    }
    @include mobile {
      padding: 50px 0;
    }
  }

  .single-header {
    label {
      border-bottom: 1px solid #008576;
    }
    h3 {
      max-width: 840px;
    }
    .tags-area {
      margin-top: 0;
    }
    .link-secondary{
      margin-bottom: 16px;
    }
    .headerWrap__socail {
      margin-left: 14px;
      span {
        display: flex;
        align-items: flex-end;
        padding-left: 30px;
        color: #001141;
        font-size: 16px;
        line-height: 1.33333em;
        font-weight: 500;
        font-family: 'Whitney';
        margin-right: 5px;
        height: 18px;
      }
    }
  }
  h3 {
    font-family: 'Antonia H3';
    font-size: 28px;
    font-weight: 500;
    line-height: em(32, 28);
    color: $blue;
  }

  &__leading {
    font-weight: 500!important;
  }
  
  .top-content {
    float: left;
    width: 100%;
    &-wrapper {
      display: flex;
      @include tablet {
        flex-direction: column;
        max-width: 570px;
      }
      @include mobile {
        flex-direction: column;
      }
    }
    &-title {
      width: 100%;
      display: inline-block;
      padding-left: 108px;
      margin-bottom: 42px;

      @include tablet-big {
        padding-left: 0;
        margin-top: 64px;
      }

      @include tablet {
        padding-left: 0;
        margin-top: 64px;
        margin-bottom: 0;
      }

      @include mobile {
        padding-left: 0;
        margin-bottom: 0;
      }
    }

    ul:not(.extra-links) {
      max-width: 733px;
      width: 100%;
      margin: 20px auto 75px;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding-left: 40px;
      @include mobile {
        padding-left: 0px;
        max-width: 100%;
        margin: 20px auto 20px;
      }

      @include tablet-big {
        max-width: 100%;
        margin: 20px auto;
      }
      @include tablet {
        max-width: 100%;
        margin: 20px auto;
      }
      li {
        color: $black;
        font-size: 18px;
        margin-bottom: 9px;
        line-height: em(24, 18);
        font-weight: normal;
        position: relative;
        list-style-type: none;
        @include mobile {
          font-size: 16px;
        }

        &:before {
          content: '\2022';
          color: #0032c3;
          font-weight: bold;
          width: 17px;
          border-radius: 50%;
          font-size: 13px;
          font-size: 13px;
          margin-top: 1px;
          float: left;
        }
      }
    }
    p {
      color: $black;
      font-size: 18px;
      margin-bottom: 32px;
      line-height: em(24, 18);
      font-weight: normal;
    }
    .btn-primary {
      float: left;
      justify-content: space-between;
    }
    &--left {
      float: left;
      width: 50%;
      margin-bottom: 75px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include desktop {
        padding-left: 108px;
      }
      @include tablet-big {
        width: 100%;
      }
      @include tablet {
        width: 100%;
        margin-bottom: 64px;
      }
      @include mobile {
        width: 100%;
        margin-bottom: 40px;
      }
      p {
        color: $black;
        font-size: 18px;
        margin-bottom: 32px;
        line-height: 24px;
        font-weight: normal;
        @include mobile {
          font-size: 16px;
        }
      }
    }
    &--right {
      float: right;
      width: 50%;
      @include desktop {
        padding-left: 120px;
      }
      @include tablet-big {
        width: 100%;
        padding-left: 50px;
      }
      @include tablet {
        width: 100%;
      }
      @include mobile {
        width: 100%;
      }
      &__thumb {
        width: 100%;
        height: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 2fr);
        img {
          @extend %imgCropped;
          max-height: 200px;
        }
      }
      &__chart {
        margin-top: 88px;
      }
      &__single {
        display: block;
        > div {
          width: 100%;
          height: 100%;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .photo-credit {
        float: left;
        width: 100%;
        margin-top: 8px;
        color: $black;
        font-size: 13px;
        line-height: 17px;
      }
    }
  }

  .extra-links-area {
    float: left;
    width: 100%;
    // margin-top: 100px;
    margin-top: 32px;
    padding: 30px 42px;
    background: #f1f5ff;
    h3 {
      color: $black;
      font-size: 22px;
      line-height: em(26, 22);
      font-family: 'Antonia H3';
      font-weight: normal;
      margin-bottom: 18px;
    }

    .download-links {
      a {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;
        @include mobile {
          flex-direction: column;
        }
        &:last-child {
          border-bottom: 0;
        }
        p {
          display: flex;
          font-size: 16px;
          line-height: em(18, 16);
          font-weight: 500;
          color: $blue;
          margin-bottom: 0;
          transition: $transition1;
          &:first-child {
            width: 64%;
            @include mobile {
              width: 100%;
              margin-bottom: 3px;
            }
          }
          &:hover {
            color: #008576;
          }
        }
        .file-type {
          color: $black;
          font-size: 16px;
          text-transform: uppercase;
          min-width: 43px;
          text-align: left;
          display: inline-block;
          margin-right: 12px;
          font-weight: 500;
          font-variant: small-caps;
        }
        .file-size {
          color: #7e6e7e;
          font-size: 16px;
          line-height: em(22, 16);
          font-weight: 500;
          margin-right: 12px;
          font-variant: small-caps;
          white-space: nowrap;
        }
      }
    }
  }
  ul.extra-links {
    li {
      margin-bottom: 16px;
      a {
        color: #0032c3;
        font-size: 18px;
        line-height: em(21, 18);
        font-weight: normal;
        position: relative;
        padding-left: 25px;
        display: flex;
        &:before {
          content: '';
          float: left;
          width: 10px;
          height: 10px;
          margin-top: 5px;
          background: url(../images/arrow-list.svg) no-repeat 0 0;
          background-size: 100%;
          transform: rotate(-90deg);
          margin-right: 14px;
          position: absolute;
          left: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .different-single-layout {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    .full-block {
      float: left;
      width: 100%;
      display: flex;
      margin-bottom: 16px;
    }
    span {
      clear: left;
      min-width: 115px;
      max-width: 115px;
      width: 115px;
      font-size: 14px;
      font-weight: 500;
      line-height: em(20, 14);
      font-variant: all-small-caps;
      @include mobile {
        min-width: 75px;
        max-width: 75px;
        width: 75px;
      }
    }
    p {
      clear: right;
      margin-bottom: 0;
    }
  }
  .social__icons a{
    color: #fff;
    svg{
      width: 14px;
    }
  }
  .book-detail-p-content-footer{
    width: 100%;
    margin-left: 0;
    margin-top: 60px;
    &.only-mobile{
      display: none!important;
      @include tablet {
        float: left;
        display: flex!important;
      }
      @include mobile {
        float: left;
        display: flex!important;
      }
    }
  }
}
