.m41
  .books-listing,
  .no-items-found
    grid-column: span 12

    @include lbg_tablet
      grid-column: span 7

  .books-sidebar
    order: -1
    grid-column: span 12

    @include lbg_tablet
      order: 1
      grid-column: 9 / span 4

    @include lbg_desktop
      grid-column: 9 / span 3

  .books-listing__book
    display: flex

  .books-listing__book + .books-listing__book
    border-top: 1px solid $blueLight
    margin-top: 2.5rem
    padding-top: 2.5rem

  .book__cover
    flex-basis: 4rem
    flex-grow: 0
    flex-shrink: 0

    @include lbg_tablet
      flex-basis: 12rem

    img
      width: 100%
      max-width: 3rem
  
      @include lbg_tablet
        max-width: 130px

  .book__content
    color: $black

    h2
      margin: 0
      hyphens: auto

    p
      line-height: 1.3

    span
      &::after
        content: ', '

      &:last-of-type::after
        content: ''

    strong
      margin: 1rem 0 0
      display: block

    a
      display: block
      color: inherit
      transition: color .3s ease

      &:hover
        color: $green