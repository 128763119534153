.m10 {
  .team-profiles {
    margin-top: 0;

    @include mobile {
      margin-top: 0;
    }
  }

  .team-profiles-dropdown-right {
    margin-bottom: -29px;
    justify-content: flex-end;
    @include mobile {
      margin-bottom: -25px;
      .dropdown-with-arrow {
        display: none;
      }
    }
    @include tablet {
      margin-bottom: -25px;
      .dropdown-with-arrow {
        display: none;
      }
    }
  }
  
  .team-profiles-content-header {
    position: unset;
    margin-top: 80px;
    &:first-child {
      margin-top: 0px;
    }
  }

  // &-card {
  //   display: flex;
  //   padding-bottom: 72px;
  //   @include tablet {
  //     border-bottom: 1px solid $pink;
  //     padding: 13px 0px;
  //   }
  //   @include mobile {
  //     border-bottom: 1px solid $pink;
  //     padding: 13px 0px;
  //   }
  //   &-thumb {
  //     width: 100%;
  //     max-width: 84px;
  //     margin-right: 23px;
  //     background-color: #c2c2c2;
  //     height: 113px;
  //   }
  //   &-desc {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     h6 {
  //       font-weight: 600;
  //       font-size: 18px;
  //       line-height: 24px;
  //       color: $black;
  //       margin-bottom: 8px;
  //     }
  //     span {
  //       font-size: 16px;
  //       line-height: 24px;
  //       color: $pinkDark;
  //       transition: $transition1;
  //       &:hover {
  //         color: $green;
  //         cursor: pointer;
  //         transition: $transition1;
  //       }
  //     }
  //   }
  // }
  .teamDataHolder {
    .row {
      .column-6 {
        &:nth-child(2n + 1) {
          clear: left;
        }
      }
    }
  }
}
