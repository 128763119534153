.m18 {
  width: 100%;
  background: $blueLight;
}

.m19 {
  .wysiwyg + .btn-primary {
    margin-top: 2rem
  }
}

.program-buttons {
  @include desktop {
    padding: 0 110px;
  }
  &__description {
    width: 50%;
    color: $black;
    font-size: 18px;
    margin-bottom: 52px;
    line-height: 1.333em;
    @include tablet {
      width: 70%;
    }
    @include tablet-big {
      width: 70%;
    }
    @include mobile {
      width: 100%;
      margin-bottom: 43px;
    }
  }
  &__title {
    width: 100%;
    color: $black;
    font-size: 22px;
    margin-bottom: 24px;
    line-height: 1.182em;
    font-family: 'Antonia H3';
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    &:hover {
      a {
        border-color: $darkGreen;
      }
      h2 {
        color: $darkGreen;
      }
      .program-buttons {
        &__arrow {
          background-image: url('../images/arrow-filled-green-gradient.svg');
        }
      }
    }
    a {
      display: flex;
      padding: 65px 60px;
      position: relative;
      background: white;
      align-items: flex-start;
      justify-content: space-between;
      transition: $transition1;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom: 2px solid $pink;
      @include mobile {
        padding: 40px 25px;
        flex-direction: column;
      }
    }
    h2 {
      width: 210px;
      color: $blue;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.143em;
      transition: $transition1;
      font-family: 'Antonia H3';
      hyphens: auto;
      
      @include tablet {
        width: 150px;
      }
      @include tablet-big {
        width: 150px;
      }
      @include mobile {
        width: 80%;
        margin-bottom: 16px;
      }
    }
    p {
      flex: 1 1;
      color: $black;
      font-size: 18px;
      padding-left: 55px;
      padding-right: 110px;
      line-height: 1.333em;
      @include tablet {
        padding-left: 20px;
        padding-right: 40px;
      }
      @include tablet-big {
        padding-left: 20px;
        padding-right: 40px;
      }
      @include mobile {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &__arrow {
    width: 42px;
    height: 38px;
    transition: $transition1;
    background: url('../images/arrow-outline-light-blue.svg') no-repeat center;
    background-size: cover;
    @include mobile {
      top: 40px;
      right: 25px;
      width: 22px;
      height: 20px;
      position: absolute;
    }
  }
}
