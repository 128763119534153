.m40 {
  margin-bottom: 150px;
  @include tablet {
    margin-bottom: 120px;
  }
  @include mobile {
    margin-bottom: 120px;
  }
  .publication-listing {
    margin-bottom: 50px;
    &-item {
      margin-bottom: 80px;
      .dropdown-with-arrow {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -27px;
        z-index: 7;
      }
      .dropdown-with-arrow ul {
        left: unset;
        right: -7px;
        width: unset;
      }
    }
    h2 {
      font-size: 20px;
      line-height: 26px;
      color: $black;
      margin-bottom: 24px;
      font-weight: 600;
    }
    @include mobile {
      h4 {
        font-size: 16px;
      }
    }
    &-chapter {
      display: flex;
      margin-bottom: 24px;
      &--number{
        min-width: 40px;
        text-align: right;
      }
      span {
        font-size: 18px;
        line-height: 24px;
        color: $black;
        font-weight: 500;
        display: block;
        margin-right: 8px;
      }
      strong {
        color: $black;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        display: block;
        transition: $transition1;
        &:hover {
          color: $blue;
          transition: $transition1;
          cursor: pointer;
        }
      }
      p {
        font-size: 16px;
        color: $black;
        line-height: 22px;
        margin-bottom: 4px;
      }
      label {
        font-size: 18px;
        line-height: 24px;
        color: $black;
        font-weight: 500;
        display: block;
        transition: $transition1;
        &:hover {
          color: $blue;
          transition: $transition1;
          cursor: pointer;
        }
      }
      &-links {
        display: flex;
      }
      @include mobile {
        strong,
        span {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
  .publiation-listing-show-more-btn {
    transform: rotate(180deg);
    transition: $transition1;
  }

  .publiation-listing-show-more-btn--rotate {
    transform: rotate(0deg);
    transition: $transition1;
  }
}
