.mainWrap
  div.wpforms-container-full .wpforms-form .wpforms-field-label
    color: $black

  div.wpforms-container p:empty,
  div.wpforms-container li::before
    display: none

  div.wpforms-container-full .wpforms-form label.wpforms-error
    margin-top: .5rem

  div.wpforms-container-full .wpforms-form input[type=submit],
  div.wpforms-container-full .wpforms-form button[type=submit],
  div.wpforms-container-full .wpforms-form .wpforms-page-button
    border: none
    outline: none
    background-image: none
    background-color: transparent
    box-shadow: none
    min-width: 190px
    text-align: center
    background-color: #0032c3
    padding: 16px 22px
    border-radius: 4px
    font-size: 16px
    font-weight: 500
    color: #fff