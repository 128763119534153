.m45 {
  margin: 120px 0;
  .author-overview {
    &-row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-box {
      display: flex;
      border-bottom: 1px solid $pink;
      padding-bottom: 48px;
      margin-bottom: 48px;
      justify-content: space-between;
      &-left {
        display: flex;
        max-width: 515px;
        width: 100%;
      }
      &-right {
        display: flex;
        flex-direction: column;
        max-width: 407px;
        width: 100%;

        h6 {
          font-size: 20px;
          line-height: 24px;
          color: $black;
          font-weight: 500;
          font-family: 'Antonia H3';
          margin-bottom: 16px;
        }
        a {
          display: flex;
          align-items: center;
          transition: $transition1;
          font-size: 18px;
          color: $blue;
          line-height: 21px;
          margin-bottom: 13px;
          &:hover {
            color: $green;
            transition: $transition1;
          }
        }
        span {
          img {
            width: 10px;
            height: 10px;
          }
          margin-right: 16px;
        }
        @include tablet {
          margin-top: 40px;
          h6 {
            margin-bottom: 19px;
          }
        }
        @include mobile {
          margin-top: 40px;
          font-size: 16px;
          line-height: 22px;
          a {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      @include tablet {
        flex-direction: column;
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
      @include mobile {
        flex-direction: column;
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
    }
    &-thumb {
      width: 84px; 
      height: 84px; 
      border-radius: 100%;
      overflow: hidden;
      margin-right: 24px;
      @include tablet {
        margin-bottom: 16px;
        width: 84px;
        height: 84px;
        margin-right: 24px;
      }
      @include mobile {
        margin-bottom: 16px;
        margin-right: 16px;
        width: 57px;
        height: 57px;
      }
      img{
        @extend %imgCropped;
      }
    }
    &-content {
      width: calc(100% - 110px);
      h4 {
        color: $black;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
        font-weight: 600;
      }
      p {
        line-height: 24px;
        font-size: 16px;
        color: $pinkDark;
        margin-bottom: 16px;
      }
      @include tablet {
        h4 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 6px;
        }
      }
      @include mobile {
        .m20__box__link {
          a {
            font-size: 14px;
          }
        }
        h4 {
          font-size: 16px;
          margin-bottom: 5px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          color: $pink;
          margin-bottom: 16px;
        }
      }
    }
  }

  @include tablet {
    .section-title-small {
      font-size: 34px;
    }
  }
}
