.lbg__breadcrumbs
  margin-bottom: 0 !important

.lbg__breadcrumbs .container,
.lbg__breadcrumbs--inline
  display: flex
  align-items: center
  gap: .25rem .5rem
  flex-wrap: wrap

  &::after,
  &::before
    display: none

  a
    transition: color .25s ease

    &,
    &:active,
    &:visited
      color: $black

    &:hover
      color: $darkGreen


.lbg__breadcrumbs--inline
  svg
    color: $pinkDark
    transition: color .25s ease
  
  a
    &,
    &:active,
    &:visited
      color: $pinkDark

    &:hover,
    &:hover svg
      color: $darkGreen