.lbg__linked-people
  margin-top: 2.5rem
  padding-top: 1.75rem
  border-top: 1px solid $pinkLight
  color: $black
  max-width: 730px
  font-size: 16px

  .linked-people
    margin-top: .75rem
    line-height: 1.4

  h4
    font-weight: 600

  a
    color: $blue
    text-decoration: underline
    transition: all .3s ease
    white-space: nowrap

    &:hover
      color: $darkGreen