.m66
  // reset outdated styling behaviour
  clear: both
  font-size: 16px
  
  &, a, .fz-h1
    color: var(--primary)

  .m66__headline
    text-transform: uppercase
    font-weight: 500
    letter-spacing: 0.1em
    font-size: .875rem

  .m66__ctas
    margin-top: 1.5rem

    @include lbg_tablet
      margin-top: 2.5rem

    a
      border-top: 1px solid hsla(var(--primary-hsl), .1)
      display: grid
      gap: .25rem
      align-items: center
      color: var(--primary)
      font-size: 1.125rem
      transition: background .2s ease
      grid-template-columns: 1fr auto
      padding: 1.5rem 0

      @include lbg_tablet
        gap: 2rem
        grid-template-columns: 2fr 1.5fr auto
        padding: 1.5rem 2rem 1.5rem .25rem
        text-wrap: balance

      &:hover
        .lbg-arrow
          transform: translateX(.5rem)
  
          &::before
            transform: scaleX(.75)

        .fz-h1
          transform: translateX(1rem)
    
    li:last-child a
      border-bottom: 1px solid hsla(var(--primary-hsl), .1)

    .lbg-arrow
      grid-column: 2 / span 1
      grid-row: 1 / span 2

      @include lbg_tablet
        grid-row: span 1
        grid-column: span 1

    .description
      grid-column: 1 / span 1
      grid-row: 2 / span 1
      line-height: 1.25

      @include lbg_tablet
        grid-row: span 1
        grid-column: span 1

    .fz-h1
      line-height: 1.1
      font-family: 'Antonia H1'
      transition: transform .2s $easeOutQuad
      grid-column: 1 / span 2
      grid-row: 1 / span 1

      @include lbg_tablet
        grid-row: span 1
        grid-column: span 1